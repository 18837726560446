import React, { useEffect, useState, useRef } from 'react'
import { nftBuy } from '@/api/nft'
import { alipayGetOrderStatus, alipayPcPay } from '@/api/alipay'
import { Button, Avatar, InputNumber, message } from 'antd';
import { zfb } from '../../utils/icon'
import DarkFooter from "../../components/Footers/DarkFooter";
import Dilaog from './Dialog/index'
import { useMappedState,useDispatch } from 'redux-react-hook'
import { dfb,border } from '@/utils/icon.js'
import './index.scss'

export default function Pay(props) {
    const [recharge, setRecharge] = useState(1);
    const [open, setOpen] = useState(false);
    const [count, setCont] = useState(1);
    const content_type_list = [{ key: "All", name: "所有" }, { key: "Avatar", name: "头像" }, { key: "Music", name: "音乐" }, { key: "RightCard", name: "权益卡" }, { key: "DriverSkin", name: "车机皮肤" }];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sutusType,setSutusType] = useState();
    const dispatch = useDispatch();

    const { nftDetialItem,userInformation } = useMappedState((state) => {
        return state;
    })
    

    
    const handleChangeCount = (value) => {
        setCont(value)
    }
    const setFloat = (arg1, arg2) => {
        if(arg1&&arg2){
            var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
            try { m += s1.split(".")[1].length } catch (e) { }
            try { m += s2.split(".")[1].length } catch (e) { }
            return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
        }
        return 0
    }
   

    const alipayPcPayOk = async () => {
        if(recharge===1){
            if(count!= 0){
                const params = {
                    deal_count: count,
                    asset_id: nftDetialItem.asset_id,
                    price: count * nftDetialItem.price,
                    return_url:'home/my/order',
                }
                const res = await alipayPcPay(params)
                if (res?.code === 200) {
                    console.log(res, "res")
                    localStorage.setItem("out_trade_no",res.data.out_trade_no)
                    window.open(res.data.url,"_self")
                } else {
                    message.error('支付失败,请联系管理员')
                }
            }else{
                 message.error('下单数量不可以为0')
            }
           
        }else if(recharge===2){
            if(userInformation?.balance>0){
                setIsModalOpen(true);
                setSutusType(1)
            }else {
                  message.error('可用积分不足，请充值')
            }
            
        }
    }
    const setContentType = (value) => {
        let content_type = ""
        content_type_list.map((v, i) => {
            if (v.key == value) {
                content_type = v.name;
            }
        })
        return content_type;
    }

    const setImageFun = (value) => {
         if(value){
            const image = JSON.parse(value)
            return image?.FrontCoverUrl;
         }
    }
    const setIsModalOpenFun = (isModalOpen)=>{
        setIsModalOpen(isModalOpen)
    }

    const setSutusTypeFun = (sutusType)=>{
        setSutusType(sutusType)
    }


    const initSatusFun = ()=>{
        if(userInformation.has_pay_pwd&&sutusType!=4){
            setSutusType(3)
        }
    }

    useEffect(()=>{
        initSatusFun()
    })
    console.log(userInformation,"userInformation")
    return (
        <div className='pay'>
            <Dilaog isModalOpen={isModalOpen} 
            setIsModalOpenFun={setIsModalOpenFun} 
            sutusType={sutusType} 
            setSutusTypeFun={setSutusTypeFun}
            count={count}
            nftDetialItem={nftDetialItem}
            userInformation={userInformation}
            />
            <div className='pay-content-box'>
                <div className='pay-title'>
                    确认下单
                </div>
                <div className='attribute'>
                    <div className='one'>作品名称</div>
                    <div className='two'>作品简介</div>
                    <div className='tree'>作品类型</div>
                    <div className='tree'>单价</div>
                    <div className='tree'>交易数量</div>
                    <div className='fover'>交易价格</div>
                </div>
                <div className='list'>
                    <div className='list-card'>
                        <div className='list-card-detail'>
                            <div className='avate-meassage'>
                                <Avatar className='avatar' shape="square" size={114} src={setImageFun(nftDetialItem?.meta_data)} />
                                <div className='avata-name'>{nftDetialItem?.name}</div>
                            </div>
                            <div className='pron-message'>
                                <div className='one'>
                                    {nftDetialItem?.describe}
                                </div>
                                <div className='two'>
                                    {setContentType(nftDetialItem?.content_type)}
                                </div>
                                <div className='tree'>
                                    {nftDetialItem?.price} 
                                    {
                                            // setPrice(nftDetialItem?.price)
                                    }
                                </div>
                                <div className='tree'>
                                    <InputNumber className='tree-input'  min={1} defaultValue={1} onChange={handleChangeCount} />
                                </div>
                                <div>
                                    {
                                        setFloat(count, nftDetialItem?.price)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='recharge-points'>
                    <div className='points-two'>
                        <div className='points-label'>请选择支付方式:</div>
                        <div className='two-box'>
                            <div className="two-box-content" id={recharge === 1 ? "active" : ""} onClick={() => {
                                setRecharge(1)
                            }}>
                                <Avatar shape="square" className='pay' src={zfb} />
                               {recharge === 1&&<img src={border} alt="" className='border-image' />}
                            </div>
                            <div className="two-box-content" id={recharge === 2 ? "active" : ""} onClick={() => {
                                setRecharge(2)
                            }}>
                                <div className='pay-box'>
                                    <Avatar shape="square" className='left-pay' src={dfb} />
                                    <div className='right-pay'>
                                        <div className='two-title'>积分支付</div>
                                        <div className='two-describe'>可用积分{userInformation?.balance}</div>
                                    </div>
                                </div>
                                {recharge === 2&&<img src={border} alt="" className='border-image' />}

                            </div>
                        </div>
                    </div>
                    <div className='points-four'>
                        <div className='points-label'>总金额:</div>
                        <div className='jine'> {
                            setFloat(count, nftDetialItem?.price)
                        }</div><div className='yuan'>元</div>
                    </div>
                    <Button type="primary" className='pay-ok' onClick={() => {
                        alipayPcPayOk();               
                    }}>确认支付</Button>
                </div>
            </div>
            <DarkFooter />
        </div>
    )
}
