import React, { useState,useEffect } from 'react';
import './index.scss'
import {seeStarNft} from '../../api/user'
import { Card ,message} from 'antd';
import {useMappedState,useDispatch} from 'redux-react-hook'


export default function Star(props) {
    let dispatch = useDispatch();
    let {starList} = useMappedState((state)=>{
         return state;
    })
    const getStar = async()=>{
        await seeStarNft().then(res => {
            if(res.code===200){
               dispatch({type:"GET_STAR_LIST",starList:res.data})
               message.success(res.msg);  
            }else {
                message.error(res.msg);
            }
       })
    }
    useEffect(()=>{
        getStar();
    },[])
    return (
        <div className='Star'>
               <ul>
                   {
                          starList?.starList?.map((v,i)=>{
                                 return <li key={i}>{v.name}</li>
                          })
                   }   
               </ul>
               <ul>
                  {
                        //   Collection?.nft?.map((v,i)=>{
                        //          return <li key={i}>{v.name}</li>
                        //   })
                   }   
               </ul>
        </div>
    )
}

