import React, { useEffect, useState } from 'react'
import "./index.scss"
import { Col, Row } from 'antd';
import { useRequest } from 'ahooks';
import { useMappedState, useDispatch } from 'redux-react-hook';
import Authentication from './component/Authentication/index'
import EditPhone from './component/EditPhone/index'
import EditPassword from './component/EditPassword/index'
import AuthModal from './component/AuthModal/index'
import { getAuthStatus } from '@/api/bill';
import successIcon from '@/assets/img/bill/successIcon.svg'

export default function SecuritySetting() {
    const [acitves, setAcitves] = useState(1);
    const [stustsType, setSutusType] = useState();
    const [oldPhone, setOldPhone] = useState();
    const [newPhone, setNewPhone] = useState();
    const [oldCode, setOldCode] = useState();
    const [newCode, setNewCode] = useState();
    const [authStatus, setAuthStatus] = useState('') // 0未认证 1已认证
    const [authVisiable, setAuthVisiable] = useState(false) //是否开启认证Modal
    const [dataAuthStatus, setDataAuthStatus] = useState('')
    let timer = null;

    const oldMessuge = (oldPhone, oldCode) => {
        setOldPhone(oldPhone)
        setOldCode(oldCode)

    }
    const newMessuge = (oldPhone, oldCode) => {
        setNewPhone(oldPhone)
        setNewCode(oldCode)
    }

    // 获取用户信息redux
    const { userInformation } = useMappedState((state) => {
        return state;
    })

    console.log('userInformation123', userInformation)

    const setAcitvesFun = (value) => {
        console.log(value, "value")
        setAcitves(value)
    }



    useEffect(() => {
        if (localStorage.getItem("token")) {
            getStatusData(); //数据查询函数，
            clearTimerFun(); //清空定时器
            setTimerFun(); //创建定时器
        }

    }, []); //监听筛选条件，一旦变化就立即重新查询，并清空已有定时器，创建新的定时器

    useEffect(() => {
        return () => {
            clearTimerFun(); //组件销毁时，清空定时器
        };
    }, []);

    const setTimerFun = () => {
        console.log("创建定时器");
        timer = setInterval(() => {
            getStatusData();
        }, 2000);
    };

    const clearTimerFun = () => {
        console.log("清空定时器");
        clearInterval(timer);
    };

    const getStatusData = async () => {
        const { data, code } = await getAuthStatus();
        if (code === 200) {
            setDataAuthStatus(data)
            console.log('data22---->', data)
        }
    }



    const showAuthModal = (flag) => {

        setAuthVisiable(flag)

    }
    return (
        <div>
            <div className='set-big-box'>
                {acitves === 1 && <div className='set-box ziti'>
                    <div className='set-box-item'>
                        <div className="item-title ziti">实名认证</div>
                        {
                            userInformation?.is_auth ? <div className='right-explain auth-id'>
                                已认证
                                <img src={successIcon} alt="" />
                            </div> : <div className='auth-area'>
                                <div className='item-content ziti'>
                                    未认证
                                </div>
                                <div className='item-last ziti' onClick={() => showAuthModal(true)}>
                                    去认证
                                </div>
                            </div>
                        }

                    </div>
                    <div className='set-box-item'>
                        <div className="item-title ziti">手机号码</div>
                        <div className='item-content ziti'>
                            {userInformation?.phone}
                        </div>
                        <div className='item-last ziti' onClick={() => {
                            setAcitves(2)
                            setSutusType(3)
                        }}>
                            修改
                        </div>
                    </div>
                    <div className='set-box-item'>
                        <div className="item-title ziti">邮箱账号</div>
                        <div className='item-content ziti'>
                            {userInformation?.email || "未设置"}
                        </div>
                        <div className='item-last ziti' onClick={() => {
                            setAcitves(5)
                        }}>
                            {userInformation?.email || "去设置"}
                        </div>
                    </div>
                    <div className='set-box-item'>
                        <div className="item-title ziti">登录账号密码</div>
                        <div className='item-content ziti'>
                            {userInformation ? '已设置' : "未设置"}
                        </div>
                        <div className='item-last ziti' onClick={() => {
                            setAcitves(2)
                            setSutusType(4)
                        }}>
                            修改
                        </div>
                    </div>
                </div>}
                {acitves === 2 && <Authentication oldMessuge={oldMessuge} oldPhone={oldPhone} oldCode={oldCode} setAcitvesFun={setAcitvesFun} stustsType={stustsType} />}
                {acitves === 3 && <EditPhone setAcitvesFun={setAcitvesFun} newPhone={newPhone} newCode={newCode} oldPhone={oldPhone} oldCode={oldCode} newMessuge={newMessuge} />}
                {acitves === 4 && <EditPassword setAcitvesFun={setAcitvesFun} oldPhone={oldPhone} oldCode={oldCode} newMessuge={newMessuge} />}
            </div>

            <AuthModal visiable={authVisiable} authStatus={dataAuthStatus?.status} showAuthModal={showAuthModal}></AuthModal>

        </div>



    )
}
