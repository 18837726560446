import React,{useEffect} from 'react'
import {nft_deal} from '../../api/user'
import { Card ,message} from 'antd';
import {useMappedState,useDispatch} from 'redux-react-hook'



export default function Nftdeal() {
  let dispatch = useDispatch();
  let {nftDealList} = useMappedState((state)=>{
    return state;
  })
  const getNftDealList = async()=>{
    await nft_deal().then((res)=>{
      console.log(res,"res")
          if(res.code===200){
              dispatch({type:"GET_NFT_DEAL_LIST",nftDealList:res.data})
          }else {
              message.error(res.msg);
          }
  })
  }
  useEffect(()=>{
       getNftDealList()
  },[])
  console.log(nftDealList,"nftDealList")
  return (
    <div>Nftdeal</div>
  )
}
