import React,{useEffect} from 'react'
import {userNft,Collection} from '../../api/user'
import { Card ,message} from 'antd';
import {useMappedState,useDispatch} from 'redux-react-hook'


export default function PersonalNft() {
  let dispatch = useDispatch();
  let {personalNftList} = useMappedState((state)=>{
    return state;
  })
  

  const getPersonalNftList = async()=>{
    // await userNft().then((res)=>{
    //   console.log(res,"res")
    //       if(res.code===200){
    //           dispatch({type:"GET_PERSONAL_NFT_LIST",personalNftList:res.data})
    //       }
    // })
    await Collection().then((res)=>{
      console.log(res,"res")
          if(res.code===200){
              dispatch({type:"GET_PERSONAL_NFT_LIST",personalNftList:res.data})
          }
    })
  }
  useEffect(()=>{
    getPersonalNftList()
  },[]) 
  console.log(personalNftList,"personalNftList")
  return (
    <div>PersonalNft</div>
  )
}
