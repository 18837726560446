import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Avatar } from 'antd';
import { Carousel } from 'antd';
import { useHistory } from "react-router-dom";
import {
       PopularCreator,
       // notice,
} from "@/api/nft"
import './index.scss'
import avatar from '@/assets/img/default-avatar.svg'
import { rz, fire, backLeft, bakRight } from '../../../../utils/icon'

export default function PopularityCreator(props) {
       const bannerDom = useRef(null);
       const history = useHistory();
       const [popularCreatorList, setPopularCreatorList] = useState([]);
       const onChange = (currentSlide) => {
              console.log(currentSlide);
       };

       const [totalPage, setTotalPage] = useState(1); // 轮播分页
       const [pageNo, setPageNo] = useState(1);
       const [isPageFirst, setIsPageFirst] = useState(true); // 是否在第一页
       //复制多份数组
       const copyArr = (arr, num) => {
              let res = [];
              for (let i = 0; i < num; i++) {
                     res.push(...arr);
              }
              return res;
       }
       const getPopularCreator = async (value) => {
              let params = {
                     page_no: value,
                     page_size: 6,
              }
              await PopularCreator(params).then(res => {
                     setPopularCreatorList(res.data.data);
                     const Num = Math.ceil(res.data.count/6);    
                     setTotalPage(Num)

              })
       }
       useEffect(() => {
              getPopularCreator(1);
       }, [])
       console.log(popularCreatorList, "popularCreatorList")

       const getSliderContent = () => {
              for (let i = 0; i < totalPage; i++) {
                     return <div id='popularity-list-box'>
                            <div className='popularity-list-box'>
                                   <Row>
                                          {
                                                 popularCreatorList?.map((v, i) => {
                                                        return <Col span={4}>
                                                               <a key={i} className='popularity-card' onClick={() => {
                                                                      history.push('/home/creator', { seller_id: v?.user_id })
                                                               }}>
                                                                      <div className='popularity-avatar-box'>
                                                                             <Avatar className='popularity-avatar' src={v?.avatar || avatar} />
                                                                             <div className='popularity-label'>
                                                                                    <img alt='认证' src={fire} /> &nbsp;hot1
                                                                             </div>
                                                                      </div>
                                                                      <div className='popularity-item'>
                                                                             {v?.username} <img alt='认证' src={rz} />
                                                                      </div>
                                                                      <div className='popularity-describe'>
                                                                             {v?.brief}
                                                                      </div>
                                                               </a>
                                                        </Col>
                                                 })
                                          }
                                   </Row>

                            </div>
                     </div>
              }

       }

       const goToPrevPage = () => {
              bannerDom.current.prev();
              // let newPageNo = pageNo - 1;
              // setPageNo(newPageNo);
              // getPopularCreator(newPageNo)
              // if (pageNo === 1) {
              //        setIsPageFirst(true)
              // } else {
              //        setIsPageFirst(false)
              // }
              if (pageNo > 1) {
                     let newPageNo = pageNo - 1;
                     setPageNo(newPageNo);
                     getPopularCreator(newPageNo)
              } else if (pageNo === 1) {
                     setIsPageFirst(true)
              } else {
                     setIsPageFirst(false)
              }

       }

       const goToNextPage = () => {
              bannerDom.current.next();
              // if (pageNo > 1) {
              setIsPageFirst(false)
              let newPageNo = pageNo + 1
              setPageNo(newPageNo);
              getPopularCreator(newPageNo);



       }
       return (
              <div className='popularity-creator-box'>
                     <div className='popularity-creator'>
                            人气创作者
                     </div>
                     <div className='works-banner'>
                            <Carousel afterChange={onChange} ref={bannerDom} >
                                   {
                                          getSliderContent()
                                   }
                            </Carousel>
                            <div className={totalPage === 1 || isPageFirst === true ? 'noIcon' : 'yesIcon'} id="backLeft" onClick={goToPrevPage}>  <Avatar shape="square" className='backLeft-avartar' src={backLeft} /></div>
                            {/* <div className='bakRight' onClick={() => {
                                   bannerDom.current.next();
                            }}>  <Avatar shape="square" className='bakRight-avartar' src={bakRight} /></div> */}
                            <div className={totalPage === 1 ? 'noIcon' : 'yesIcon'} id="bakRight" onClick={goToNextPage}>  <Avatar shape="square" className='bakRight-avartar' src={bakRight} /></div>
                     </div>
              </div>
       )
}
