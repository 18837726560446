import Api from "@/utils/request"
import NoTokenApi from "@/utils/request"
// NFTZ展示
export const getNftList = data => {
  return NoTokenApi({
    url: "/nft",
    method: "get",
    params: data,
  })
}

// NFT系列列表
export const getCollectionList = data => {
  return NoTokenApi({
    url: "/nft/collection",
    method: "get",
    params: data,
  })
}

// 系列详情
export const collectionDetail = data => {
  return NoTokenApi({
    url: "/nft/collection/detail",
    method: "get",
    params: data,
  })
}

// 系列创建
export const collectionCreate = (formData) => {
  return Api({
    url: "/nft/collection_create",
    method: "post",
    data:formData,
  })
}


// 系列编辑
export const collectionEdit = data => {
  return Api({
    url: "/nft/collection_edit",
    method: "post",
    data,
  })
}

// 系列删除
export const collectionDelete = data => {
  return Api({
    url: "/nft/collection_delete",
    method: "post",
    data,
  })
}


// NFT详情上架了的
export const nftDetail = data => {
  return NoTokenApi({
    url: "/nft/detail",
    method: "get",
    params: data,
  })
}

// NFT详情未上架的
export const nftDetailNoton = data => {
  return Api({
    url: "/nft/detail/noton",
    method: "get",
    params: data,
  })
}

// 详情创作者信息详情
export const creatorDetail = data => {
  return Api({
    url: "/nft/creator/detail",
    method: "get",
    params: data,
  })
}


// 铸造盲盒
export const mysteryBox = data => {
  return Api({
    url: "/nft/mystery_box/mint",
    method: "post",
    data,
  })
}

// nft销毁
export const nftBurn = data => {
  return Api({
    url: "/nft/nft_burn",
    method: "post",
    data,
  })
}


// NFT积分购买
export const nftBuy = data => {
  return Api({
    url: "/nft/nft_buy",
    method: "post",
    data,
  })
}


// NFT创建
export const nftCreate = data => {
  return Api({
    url: "/nft/nft_create",
    method: "post",
    data,
  })
}


// NFT上架
export const NftOrderAdd = data => {
  return Api({
    url: "/nft/nft_order_add",
    method: "post",
    data,
  })
}

// NFT下架
export const NftOrderDown = data => {
  return Api({
    url: "/nft/nft_order_down",
    method: "post",
    data,
  })
}


// NFTZ热门排行
export const ranking = data => {
  return NoTokenApi({
    url: "/nft/ranking",
    method: "get",
    params: data,
  })
}

// 人气创作者
export const PopularCreator = data => {
  return NoTokenApi({
    url: "/nft/creator",
    method: "get",
    params: data,
  })
}

// 人气创作者
export const notice = data => {
  return NoTokenApi({
    url: "/notice",
    method: "get",
    params: data,
  })
}
