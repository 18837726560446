import React, { useState, useEffect } from 'react'
import './index.scss'
import { Avatar, message, Row, Col } from 'antd';
import { useHistory } from "react-router-dom";
import { EyeOutlined, HeartOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';
import avatar from '@/assets/img/default-avatar.svg'
import { profile } from '@/api/user'
import { useMappedState, useDispatch } from 'redux-react-hook';
import { userNftPrivate } from '../../../../api/user'
import { follow, followers } from '../../../../api/user'
import { Collection, top } from "../../../../api/user"
import { NftOrderAdd, NftOrderDown, nftBurn } from '../../../../api/nft'
import { asset } from '@/api/public'
import { Empty } from 'antd';
import { noStatusBack } from '@/utils/icon'
import copy from 'copy-to-clipboard';
import { collectionDelete } from '@/api/nft';
import { Select, Button, Modal, InputNumber, Form } from 'antd';

import UploadHomeImage from './UploadHomeImage/index'

export default function HomePage(props) {
        const [createWorksList, setCreateWorksList] = useState([]);
        const history = useHistory();
        const dispatch = useDispatch();
        const [nftId, setNftId] = useState();
        const [assetUserMation, setAssetUserMation] = useState("");
        const [followersList, setFollowersList] = useState([]);
        const [fansCount, setFansCount] = useState([]);
        const [userInformation, setUserInformation] = useState();
        const [createCollectionItem, setcreateCollectionItem] = useState([])
        const [flag, setFlag] = useState(true);
        const [works, setWorks] = useState("");
        const [isTop, setIsTop] = useState();
        const [form] = Form.useForm();
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [statusType, setStatusType] = useState([{ key: 1, name: "出售中" }, { key: 2, name: "已出售" }, { key: 3, name: "未上架" }])
        const {loginInformation, statusCreate, } = useMappedState((state) => {
                return state;
        })


        const getCreateCollectionList = async () => {
                if (statusCreate === 3) {
                        setFlag(false)
                }
                let params = {
                        page_no: 1,
                        page_size: 1,
                        user_id: loginInformation.id,
                }
                await Collection(params).then(res => {
                        setcreateCollectionItem(res?.data?.data[0])
                })
        }
        const setImageFun = (value) => {
                if (value) {
                        const image = JSON.parse(value)
                        return image.FrontCoverUrl;
                }

        }
        const getCreateWorksList = async () => {
                const params = {
                        all_type: true,
                        ascending: true,
                        order_by: "time",
                        status_type: 0,
                }
                await userNftPrivate(params).then(res => {
                        setCreateWorksList(res.data.items[0])
                })
        }
        const getSingleInfor = async () => {
                const params = {
                  //   user_id: nftDetailObj?.seller_id
                  user_id: loginInformation?.id
                }
                if (loginInformation) {
                  await profile(params).then(res => {
                    if (res.code == 200) {
                      setUserInformation(res.data)
                      dispatch({ type: 'GET_USERINFORMATION', userInformation: res.data })
                    }
                  })
                }
              }
        const getAssetfun = async () => {
                const params = {
                        user_id: loginInformation?.id,
                }
                await asset(params).then(res => {
                        setAssetUserMation(res.data);
                })
        }
        const getfollowersList = async () => {
                let params = {
                        user_id: loginInformation.id,
                }
                await followers(params).then(res => {
                        console.log(res, 'res')
                        setFollowersList(res?.data?.follow.slice(0, 3))
                        setFansCount(res?.data?.count)
                })
        }
        const deleteCollectionFun = async (id) => {
                const params = {
                        collection_id: id
                }
                await collectionDelete(params).then(res => {
                        if (res.code === 200) {
                                message.success("删除成功");
                                getCreateCollectionList()
                        }
                })
        }
        const editCollection = async (vlaue) => {
                dispatch({ type: 'SERVIES_PAGE_STATUS', serviesPageStatusStore: 3 })
                dispatch({
                        type: "COLLECTION_ITEM",
                        collectionItem: vlaue,
                })
                props.history.push('/home/creatnft/works_series');
        }
        const topFun = async (id) => {
                const params = {
                        is_top: true,
                        owner_id: id,
                }
                await top(params).then(res => {
                        if (res.code === 200) {
                                getCreateWorksList();
                                message.success("置顶成功");
                        }
                })
        }
        const handleCancel = () => {
                form.resetFields();
                setIsModalOpen(false);
        };
        const titles = () => {
                if (works === "1") {
                        return "上架作品"
                } else if (works === "2") {
                        return "销毁作品"
                } else if (works === "3") {
                        return "下架作品"
                }
        }
        const onFinishAdd = async (values) => {
                if (works === "1") {
                        const params = {
                                count: values.count,
                                end_time: 3,
                                nft_base_id: Number(nftId),
                                price: values.price
                        }
                        await NftOrderAdd(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        getCreateWorksList();
                                        message.success("上架成功");
                                }
                        })
                } else if (works === "2") {
                        const params = {
                                count: values.count,
                                nft_base_id: Number(nftId),
                        }
                        await nftBurn(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        getCreateWorksList();
                                        message.success("销毁成功");
                                }
                        })
                } else if (works === "3") {
                        const params = {
                                asset_id: nftId,
                        }
                        await NftOrderDown(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        getCreateWorksList();
                                        message.success("下架成功");
                                }
                        })
                }
        };
        useEffect(() => {
                getSingleInfor();
                getCreateWorksList();
                getCreateCollectionList()
                getAssetfun();
                getfollowersList();
        }, [])
        console.log(assetUserMation, "assetUserMation")
        return (
                <div className='HomePage'>
                        <Modal title={titles()}
                                open={isModalOpen}
                                onCancel={handleCancel}
                                footer={null}
                        >
                                <Form
                                        name="basic"
                                        form={form}
                                        labelCol={{
                                                span: 6,
                                        }}
                                        wrapperCol={{
                                                span: 16,
                                        }}
                                        onFinish={onFinishAdd}
                                        autoComplete="off"
                                >
                                        {works === "1" &&
                                                <Form.Item
                                                        label="上架数量"
                                                        name="count"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }
                                        {works === "1" &&
                                                <Form.Item
                                                        label="作品价格"
                                                        name="price"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }
                                        {works === "2" &&
                                                <Form.Item
                                                        label="销毁数量"
                                                        name="count"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }

                                        <Form.Item wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                        }}>
                                                <Row>
                                                        <Col span={12}>
                                                                <Button type="primary" htmlType="submit">
                                                                        确认
                                                                </Button>
                                                        </Col>
                                                        <Col span={12}>
                                                                <Button onClick={handleCancel}>
                                                                        取消
                                                                </Button>
                                                        </Col>
                                                </Row>
                                        </Form.Item>
                                </Form>
                        </Modal>
                        <div className='creator-banner'>
                                <UploadHomeImage userInformation={userInformation} />
                                <a><Avatar onClick={() => {
                                        props.history.push('/home/bill');
                                }} className='creator-Avatar' src={userInformation?.avatar || avatar} /></a>
                                <div className='edit-message-back'>
                                        <EditOutlined className="edit-icon" />&nbsp; 编辑图片
                                </div>
                        </div>
                        <div className='creator-content'>
                                <div className='creator-content-message'>
                                        <div className='title'>
                                                <span>
                                                        {userInformation?.username}
                                                </span>
                                                <a className='edit-message' onClick={() => {
                                                        props.history.push('/home/bill');
                                                }}>
                                                        <EditOutlined className="edit-icon" />&nbsp; 修改信息
                                                </a>
                                        </div>
                                        <div className='message-address'>
                                                <b>
                                                        区块链账号地址:{userInformation?.address}
                                                </b>
                                                <span>
                                                        加入时间:{userInformation?.time}
                                                </span>
                                        </div>
                                        <div className='describe'>
                                                {userInformation?.brief}
                                        </div>
                                        <a className='singleWeb' onClick={() => {
                                                if (userInformation?.creator_url) {
                                                        copy("https://pdawn.ccsmec.com/home/creators/" + userInformation?.creator_url)
                                                        message.success('复制成功')
                                                }
                                        }}>
                                                <div className='singleWeb-left'>个人网站:{userInformation?.creator_url ? `https://pdawn.ccsmec.com/home/creators/${userInformation?.creator_url}` : "未设置网站地址"}</div>
                                                <div className='singleWeb-right'>{userInformation?.creator_url && <CopyOutlined />}{userInformation?.creator_url && '复制'}</div>
                                        </a>
                                        {
                                                // assetUserMation?.nft_count>0 ||
                                                // assetUserMation?.follow_count>0 ||
                                                // assetUserMation?.collection_count>0||
                                                <div className='ok-status'>
                                                        <div className='CreationIncome-box'>
                                                                <div className='worksAdmin-title'>
                                                                        <div className='title-left'>
                                                                                收益明细
                                                                        </div>
                                                                        <a className='title-right' onClick={() => {
                                                                                dispatch({ type: "ACTIVE_TYPE", activeType: 4 })
                                                                                props.history.push('/home/creatnft/creationIncome');
                                                                        }}>
                                                                                查看明细&nbsp;&gt;
                                                                        </a>
                                                                </div>
                                                                <div className='p'>我的累计创作收益:{assetUserMation?.earning}积分(￥{assetUserMation?.earning})</div>
                                                        </div>
                                                        <div className='WorksSeries-box'>
                                                                <div className='worksAdmin-title'>
                                                                        <div className='title-left' >
                                                                                作品系列管理{assetUserMation?.collection_count}
                                                                        </div>
                                                                        <a className='title-right' onClick={() => {
                                                                                dispatch({ type: "ACTIVE_TYPE", activeType: 2 })
                                                                                props.history.push('/home/creatnft/works_series');
                                                                        }}>
                                                                                查看更多&nbsp;&gt;
                                                                        </a>
                                                                </div>
                                                                {createCollectionItem && <div className='dl' key={createCollectionItem.id}>
                                                                        <a onClick={() => {
                                                                                props.history.push('/home/worksSeries' + createCollectionItem?.id, { collection_id: createCollectionItem?.id })
                                                                        }}>
                                                                                <Avatar className='WorksSeries-Avatar' shape="square" src={createCollectionItem?.logo_pic_url} />
                                                                        </a>
                                                                        <div className='works-content-box'>
                                                                                <div className='title'>
                                                                                        {createCollectionItem?.name}
                                                                                </div>
                                                                                <div className='eye-love'>
                                                                                        <div className='eye'>
                                                                                                <EyeOutlined />
                                                                                                &nbsp;
                                                                                                空
                                                                                        </div>
                                                                                        <div className='love'>
                                                                                                <HeartOutlined />
                                                                                                &nbsp;&nbsp;
                                                                                                {createCollectionItem?.star_num}
                                                                                        </div>
                                                                                        <span>{createCollectionItem?.time}</span>
                                                                                </div>
                                                                                <div className='wokrs-count'>
                                                                                        共有{createCollectionItem?.count}个作品
                                                                                </div>
                                                                                <div className='operation'>
                                                                                        <a onClick={() => {
                                                                                                editCollection(createCollectionItem)
                                                                                        }}>编辑系列</a>
                                                                                        <a onClick={() => {
                                                                                                deleteCollectionFun(createCollectionItem.id)
                                                                                        }}>删除系列</a>
                                                                                        <a onClick={() => {
                                                                                                props.history.push('/home/worksSeries' + createCollectionItem?.id, { collection_id: createCollectionItem?.id })
                                                                                        }}>查看系列</a>
                                                                                </div>
                                                                        </div>

                                                                </div>}
                                                                {!createCollectionItem && <a className='noStatus-box' onClick={() => {
                                                                        dispatch({ type: "ACTIVE_TYPE", activeType: 3 })
                                                                        props.history.push('/home/creatnft/works_series')
                                                                }}>
                                                                        <div className='noStatus-content'>
                                                                                <div className='add-icon'>
                                                                                        +
                                                                                </div>
                                                                                <div className='add-describe'>你还没有上传系列，点击创建系列</div>
                                                                        </div>
                                                                </a>}
                                                        </div>
                                                        <div className='worksAdmin-box'>
                                                                <div className='worksAdmin-title'>
                                                                        <div className='title-left'>
                                                                                作品管理{assetUserMation?.nft_count}
                                                                        </div>
                                                                        <a className='title-right' onClick={() => {
                                                                                dispatch({ type: "ACTIVE_TYPE", activeType: 2 })
                                                                                props.history.push('/home/creatnft/work_sadmin');
                                                                        }}>
                                                                                查看更多&nbsp;&gt;
                                                                        </a>
                                                                </div>
                                                                {createWorksList && <div className='dl' >
                                                                        <a> <Avatar className='works-Avatar'
                                                                                shape="square"
                                                                                src={setImageFun(createWorksList?.meta_data)}
                                                                                onClick={() => {
                                                                                        history.push('/home/detail_creator' + createWorksList?.owner_id, { nftDetailItem: createWorksList, statusItem: 0 })
                                                                                }}
                                                                        /></a>
                                                                        <div className='works-content-box'>
                                                                                <div className='title'>
                                                                                        {createWorksList?.nft_name}
                                                                                </div>
                                                                                <div className='works-eye-love'>
                                                                                        <div className='eye'>
                                                                                                <EyeOutlined />
                                                                                                &nbsp;
                                                                                                0
                                                                                        </div>
                                                                                        <div className='love'>
                                                                                                <HeartOutlined />
                                                                                                &nbsp;
                                                                                                {createWorksList?.star_num}
                                                                                        </div>
                                                                                        <span>{createCollectionItem?.time}</span>
                                                                                </div>
                                                                                <div className='works-count-box'>
                                                                                        <div className='works-status-count'>
                                                                                                未上架:{createWorksList.not_on_quantity}
                                                                                        </div>
                                                                                        <div className='works-count'>
                                                                                                作品总量:{createWorksList.total_quantity}
                                                                                        </div>
                                                                                </div>
                                                                                <div className='footer-status'>
                                                                                        <a className='status-left' onClick={() => {
                                                                                                setNftId(createWorksList?.nft_base_id)
                                                                                                setWorks("1");
                                                                                                setIsModalOpen(true);
                                                                                        }}>
                                                                                                上架作品
                                                                                        </a>
                                                                                        <a className='status-right' onClick={() => {
                                                                                                topFun(createWorksList?.owner_id)
                                                                                                setIsTop(true)
                                                                                        }}>
                                                                                                作品置顶
                                                                                        </a>
                                                                                        <a className='status-right' onClick={() => {
                                                                                                setIsModalOpen(true);
                                                                                                setNftId(createWorksList?.nft_base_id)
                                                                                                setWorks("2");
                                                                                        }}>
                                                                                                销毁作品
                                                                                        </a>
                                                                                </div>
                                                                        </div>
                                                                </div>}
                                                                {!createWorksList && <a className='noStatus-box' onClick={() => {
                                                                        dispatch({ type: "ACTIVE_TYPE", activeType: 2 })
                                                                        props.history.push('/home/creatnft/works_admin')
                                                                }}>
                                                                        <div className='noStatus-content'>
                                                                                <div className='add-icon'>
                                                                                        +
                                                                                </div>
                                                                                <div className='add-describe'>你还没有上传作品，点击创建作品</div>
                                                                        </div>
                                                                </a>}
                                                        </div>
                                                        <div className='FansPage-box'>
                                                                <div className='worksAdmin-title'>
                                                                        <div className='title-left'>
                                                                                我的粉丝
                                                                        </div>
                                                                        <a className='title-right' onClick={() => {
                                                                                dispatch({ type: "ACTIVE_TYPE", activeType: 2 })
                                                                                props.history.push('/home/creatnft/fanspage');
                                                                        }}>
                                                                                查看更多&nbsp;&gt;
                                                                        </a>
                                                                </div>
                                                                {fansCount > 0 && <div className='FansPage-title'>
                                                                        <span className='title-one'>
                                                                                昵称
                                                                        </span>
                                                                        <span className='title-two'>
                                                                                简介
                                                                        </span>
                                                                        <span>
                                                                                关注时间
                                                                        </span>
                                                                </div>}
                                                                {
                                                                        followersList?.map((v, i) => {
                                                                                return <div className='FansPage-list' key={i}>
                                                                                        <div className='nickName'>
                                                                                                <Avatar className='FansPage-Avatar' src={v?.avatar || avatar} />
                                                                                                <div className='zhiyu'>
                                                                                                        {v?.username}
                                                                                                </div>
                                                                                        </div>
                                                                                        <div className='jianjie'>
                                                                                                {v?.brief}
                                                                                        </div>
                                                                                        <div className='gzsj'>
                                                                                                {v?.update_date}
                                                                                        </div>
                                                                                </div>
                                                                        })
                                                                }
                                                                {!fansCount && <div className='no-fans'>
                                                                        你还没有粉丝~
                                                                </div>}
                                                        </div>
                                                </div>}
                                        {!assetUserMation?.collection_count &&
                                                !assetUserMation?.nft_count &&
                                                !assetUserMation?.follow_count &&
                                                <Empty className='noStatusBack'
                                                        description={
                                                                <span>
                                                                        您尚未创建作品系列，点击创建系列
                                                                </span>
                                                        }
                                                        image={noStatusBack}>
                                                        <Button type="primary" onClick={() => {
                                                                dispatch({ type: "ACTIVE_TYPE", activeType: 3 })
                                                                props.history.push('/home/creatnft/works_series')
                                                        }}>创建</Button>
                                                </Empty>}
                                </div>
                        </div>
                </div>
        )
}
