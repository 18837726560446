import { legacy_createStore as createStore } from "redux"

let initValue = {
     nftList:[],//nft作品
     worksSeriesList:[],//精选系列
     token:localStorage.getItem('token'),
     userInformation:"",//个人信息
     CollectionList:"",//Collection列表
     starList:[],//收藏列表
     billList:[],//积分记录列表
     nftDealList:[],//NFT交易记录
     personalNftList:[],//个人nft
     createCollectionList:[],//创建的系列
     statusCreate:"",//创建状态页面
     contentType:"",//状态类型
     loginInformation:JSON.parse(localStorage.getItem("loginInformation")),//登录信息
     activeType:"",//创作作品页面的跳转状态
     walletActiveType:"",//钱包页面跳转积分账户
     nftDetialItem:"",//钱包页面跳转积分账户
     collectionItem:"",//collection编辑对象
     PersonalCenterType:"",//个人中心type
     serviesPageStatusStore:"",//个人首页系列编辑控制变量
     messageCount:0,//消息数量
     outTradeNo:""//订单状态码
}

let reducer = (state=initValue,action)=>{
     let newState = JSON.parse(JSON.stringify(state));
     switch(action.type){
         case 'LOGIN_INFORMATION':
             newState.loginInformation = action.loginInformation ;
             return newState;
         case 'SERVIES_PAGE_STATUS':
               newState.serviesPageStatusStore = action.serviesPageStatusStore;
               return newState;
         case 'GET_NFT_LIST':
              newState.nftList = action.nftList;
              return newState;
         case 'GET_USERINFORMATION':
               newState.userInformation = action.userInformation;
               return newState;
         case 'GET_STAR_LIST':
                    newState.starList = action.starList;
                return newState;
         case 'GET_COLLECTION':
                    newState.CollectionList = action.CollectionList;
                return newState;
         case 'GET_BILL_LIST':
                    newState.billList = action.billList;
                return newState;
         case 'GET_NFT_DEAL_LIST':
                    newState.nftDealList = action.nftDealList;
                 
                return newState;
         case 'GET_PERSONAL_NFT_LIST':
                    newState.personalNftList = action.personalNftList;
                  
                return newState;
         case 'GET_RANKING_LIST':
                    newState.rankingList = action.rankingList;
                return newState;   
         case 'GET_CREATE_COLLECTION_LIST':
                    newState.createCollectionList = action.createCollectionList; 
                return newState;   
         case 'STATUS_CREATE':
                    newState.statusCreate = action.statusCreate;
                return newState; 
         case 'CONTENT_TYPE':
                    newState.contentType = action.contentType;
                return newState; 
         case 'ACTIVE_TYPE':
                    newState.activeType = action.activeType;
                return newState;
         case 'WALLET_ACTIVE_TYPE':
                    newState.walletActiveType = action.walletActiveType;
                return newState;
         case 'NFT_DETAIL_ITEM':
                    newState.nftDetialItem = action.nftDetialItem;
                return newState;
         case 'COLLECTION_ITEM':
                    newState.collectionItem = action.collectionItem;
                return newState;
         case 'PERSONALCENTERTYPE':
                    newState.PersonalCenterType = action.PersonalCenterType;
                return newState;
         case 'MESSAGE_COUNT':
                    newState.messageCount = action.messageCount;
                return newState;
         default:
             return state;
     }
}

export default createStore(reducer)
