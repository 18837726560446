import { Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { Fade } from 'reactstrap';
import { useHistory } from "react-router-dom";


import './index.scss'
const AuthModal = (props) => {
    let { visiable, showAuthModal, authStatus } = props;
    const [modalVisable, setModalVisable] = useState(false);
    const history = useHistory();
    useEffect(() => {
        console.log("visiable:", visiable)
        setModalVisable(visiable)
    }, [visiable]);

    const handleOk = () => {
        showAuthModal(false)

    };
    const handleCancel = () => {
        showAuthModal(false)
    };

    const goToAuth = () => {
         showAuthModal(false);
         history.push({pathname: '/home/bill'})
    }

    return (
        <>
            <Modal open={modalVisable} onOk={handleOk} onCancel={handleCancel} footer={[
                <Button type="primary" onClick={goToAuth}>
                    立即认证
                </Button>,
                <Button key="back" onClick={handleCancel}>
                    稍后操作
                </Button>,

            ]}>
                <div className='content-auth-modal'>
                    <div className='title'>实名认证提示</div>
                    <div className='subtitle'>您尚未实名认证，为保障您的权利，请及时实名认证。</div>
                </div>

            </Modal>
        </>
    );
};
export default AuthModal;