import React,{useState,useEffect} from 'react'
import { Form, Input, Button, message, } from 'antd';
import {send_code} from '@/api/login'
import { ArrowLeftOutlined } from '@ant-design/icons';
import {useMappedState,useDispatch} from 'redux-react-hook'
import {codeValidate} from '@/api/login.js'
import './index.scss'


export default function Authentication(props) {
    const [phone,setPhone] = useState("")
    const [code,setCode] = useState("");//短信验证码
    const [values, setValues] = useState(true);
    const [open, setOpen] = useState("");//获取已设置支付密码的状态
    const {userInformation} = useMappedState((state)=>{
        return state;
    })

    const getSendCode = async() =>{
        const params = {
              phone:userInformation?.phone,
              type:"修改"
        }
        await send_code(params).then(res=>{
            if(res.code===200){
                 message.success("验证码已发送");
            }
    })
    };
    const codeValidateFun = async()=>{
        const params = {
            phone:userInformation?.phone,
            code:code,
       }
       await codeValidate(params).then(res=>{
               if(res.code===200){   
                   if(!res?.data?.is_code){
                        message.error('验证码错误')
                   }else{
                        console.log(props.stustsType,'props.stustsType')      
                        props.setAcitvesFun(props.stustsType)
                   }
               }
       })
   }   
 
 

  return (
    <div className='Authentication'>
           <div className='test-box'>
                <div className='test-back' onClick={() => {
                     props.setAcitvesFun(1)
                }}>
                    <ArrowLeftOutlined />
                    返回
                </div>
                <div className='test-title'>
                    身份验证
                </div>
                <p>为了保护你的账号安全,请验证身份，验证成功后进行下一步操作</p>
                <div className='test-describe'>
                    请输入手机+86 {userInformation?.phone}验证
                </div>
                <div className='message-test-box'>
                    <Input className='message-test' onChange={(e) => {
                        console.log(e.target.value, "value")
                        let value = e.target.value;
                         
                        if (value) {
                            setValues(false)
                            setCode(e.target.value)
                            props.oldMessuge(userInformation?.phone,e.target.value)
                        } else {
                            setValues(true)
                        }
                    }} placeholder="输入6位数短信验证码" />
                    <Button className='link-button' type="link" onClick={()=>{
                            getSendCode()
                            
                    }}>获取短信验证码</Button>
                </div>
                <Button type="primary" className='button-ok' disabled={values} htmlType="submit" onClick={()=>{
                      codeValidateFun()
                    //   props.setAcitvesFun(3)
                }}>验证</Button>
            </div>
    </div>
  )
}
