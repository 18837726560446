import React,{useState,useEffect,useRef} from 'react'
import { Switch,message } from 'antd';
import './index.scss'
import {tzh, zcbh,zpsh,dz,xzgz,zpshJy,xzgzJy} from '@/utils/icon.js'
import {messageSetting,seeMessageSetting} from '@/api/user.js'
import { useMappedState, useDispatch } from 'redux-react-hook';


export default function MessageNotification() {
    const [stutusType,setStutasType] = useState({});
    const eleRef = useRef(null)
    const { userInformation } = useMappedState((state) => {
        return state;
    })

  const onChangeZCBH = async(checked) => {
      console.log(`switch to ${checked}`);
     const params = {
          status:checked,
          msg_type:'资产变化'
     }
     await messageSetting(params).then(res=>{
             if(res.code===200){
                message.success(res.msg); 
             }else{
                 message.error(res.msg)
             }
     })
  };
  const onChangeZPSH = async(checked) => {
        console.log(`switch to ${checked}`);
        const params = {
                status:checked,
                msg_type:'作品审核动态'
           }
           await messageSetting(params).then(res=>{
                   if(res.code===200){
                      message.success(res.msg); 
                   }else{
                       message.error(res.msg)
                   }
           })
  };


  const onChangeXDGZ = async(checked) => {
        console.log(`switch to ${checked}`);
        const params = {
                status:checked,
                msg_type:'新的关注'
           }
           await messageSetting(params).then(res=>{
                   if(res.code===200){
                      message.success(res.msg); 
                   }else{
                       message.error(res.msg)
                   }
           })
  };
 
 const seeMessageSettingFun = async()=>{
        await seeMessageSetting().then((res)=>{
                if(res.code=200){
                        setStutasType(res.data)
                }else{
                    message.error('请联系管理员')
                }
        })
 }

 useEffect(()=>{
     seeMessageSettingFun()
 },[])

 console.log(stutusType,"stutusType?.following")
  return (
    <div className='message-card'>
           <div className='item-row'>
                   <div className='left-col'>
                          <img src={zcbh} alt=""></img>
                          <div className='left-col-content'>
                                  <div className='title'>
                                          资产变化
                                  </div>
                                  <div className='discribe'>
                                          充值、消费、获得奖励、作品成功出售等情形资产发生变化时，您将受到平台信息。           
                                  </div>
                          </div>
                   </div>
                   <div className='right-col'>
                          <Switch defaultChecked={stutusType?.asset_change?true:false} onChange={onChangeZCBH} />
                   </div>
           </div>
           <div className='item-row'>
                   <div className='left-col'>
                          <img src={userInformation?.role===2?zpsh:zpshJy} alt=""></img>
                          <div className='left-col-content'>
                                  <div className='title'>
                                          作品审核动态
                                  </div>
                                  <div className='discribe'>
                                          充值、消费、获得奖励、作品成功出售等情形资产发生变化时，您将受到平台信息。           
                                  </div>
                          </div>
                   </div>
                   <div className='right-col'>
                          <Switch defaultChecked={stutusType?.audit?true:false} onChange={onChangeZPSH} disabled={userInformation?.role===2?false:true} />
                   </div>
           </div>
           <div className='item-row'>
                   <div className='left-col'>
                          <img src={userInformation?.role===2?xzgz:xzgzJy} alt=""></img>
                          <div className='left-col-content'>
                                  <div className='title'>
                                          新的关注
                                  </div>
                                  <div className='discribe'>
                                          充值、消费、获得奖励、作品成功出售等情形资产发生变化时，您将受到平台信息。           
                                  </div>
                          </div>
                   </div>
                   {stutusType&&<div className='right-col'>
                          <Switch 
                          defaultChecked={stutusType?.following?true:false} 
                          onChange={onChangeXDGZ}  disabled={userInformation?.role===2?false:true} />
                   </div>}
           </div>
    </div>
  )
}
