import React, { useEffect, useState, useRef } from 'react'
import "./index.scss"
import { HeartOutlined } from '@ant-design/icons';
import { Select, Card, Avatar, Button } from 'antd';
import { useMappedState, useDispatch } from 'redux-react-hook'
import { getNftList } from '@/api/nft'
import { Empty, List } from 'antd';
import { noStatusBack } from '@/utils/icon'
import { useHistory } from "react-router-dom";
import AuthModal from '../bill/component/SecuritySetting/component/AuthModal/index';
import { getAuthStatus } from '@/api/bill';
const { Option } = Select;

export default function Market(props) {
    const dispatch = useDispatch()
    const history = useHistory();
    const content_type_list = [{ key: "All", name: "所有" }, { key: "Avatar", name: "头像" }, { key: "Music", name: "音乐" }, { key: "RightCard", name: "权益卡" }, { key: "DriverSkin", name: "车机皮肤" },];
    const order_by_list = [{ name: "综合排序", ascending: true, order_by: "Time" },
    { name: "最新发布", ascending: false, order_by: "Time" },
    { name: "价格由高到低", ascending: true, order_by: "Price" },
    { name: "价格由低到高", ascending: false, order_by: "Price" }];
    const [content_type, setContent_type] = useState("All");
    const [pageNo, setPageNo] = useState(1); // 加载分页
    const [order_by, setOrder_by] = useState('Time');
    const [ascending, setAscending] = useState(true);
    const [initLoading, setInitLoading] = useState(false);
    const [loading, setLoading] = useState(false); // 是否显示加载更多按钮
    const [list, setList] = useState([]); // 数据源
    const [isLastPage, setIsLastPage] = useState(false); //是否加载到最后一页
    const [authVisiable, setAuthVisiable] = useState(false) //是否开启认证Modal
    const [dataAuthStatus, setDataAuthStatus] = useState('')
    let timer = null;
    const { contentType } = useMappedState((state) => {
        return state;
    })

    // 获取用户信息redux
    const { userInformation } = useMappedState((state) => {
        return state;
    })

    const handleChangeCategory = (value) => {
        setList([]);
        setPageNo(1);
        setContent_type(value)

    }


    const handleChangeSort = (value) => {
        console.log(`selected ${value}`);
        console.log('order_by_list[value].order_by----->', order_by_list[value].order_by)
        console.log('order_by_list[value].ascending----->', order_by_list[value].ascending)
        setOrder_by(order_by_list[value].order_by);
        setAscending(order_by_list[value].ascending);
        setPageNo(1);
        setList([]);
    };
    /**获取市场列表 */
    const initData = async (value, ascending, order_by, content_type) => {
        const params = {
            page_no: value,
            page_size: 12,
            ascending: ascending, // 是否升序
            content_type: content_type ? content_type : "All",
            order_by: order_by ? order_by : "Time",
        }
        await getNftList(params).then(res => {
            if (res.code === 200) {
                setInitLoading(false);
                if (res?.data.length < 12) {
                    setIsLastPage(true);
                    setLoading(true);
                }
                setList(list.concat(res?.data));
            }
        })
    }

    const setImageFun = (value) => {
        if (value) {
            const image = JSON.parse(value)
            return image.FrontCoverUrl;
        }
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getStatusData(); //数据查询函数，
            clearTimerFun(); //清空定时器
            setTimerFun(); //创建定时器
        }

    }, []); //监听筛选条件，一旦变化就立即重新查询，并清空已有定时器，创建新的定时器

    useEffect(() => {
        return () => {
            clearTimerFun(); //组件销毁时，清空定时器
        };
    }, []);

    const setTimerFun = () => {
        console.log("创建定时器");
        timer = setInterval(() => {
            getStatusData();
        }, 2000);
    };

    const clearTimerFun = () => {
        console.log("清空定时器");
        clearInterval(timer);
    };
    const getStatusData = async () => {
        const { data, code } = await getAuthStatus();
        if (code === 200) {
            setDataAuthStatus(data)
            console.log('data22---->', data)
        }
    }

    /**初始化列表数据 */
    useEffect(() => {
        initData(pageNo, ascending, order_by, content_type);
    }, [content_type, order_by, ascending])



    const onLoadMore = () => {
        if (!isLastPage) {
            let newPageNo = pageNo + 1
            console.log('newPageNo111----->', newPageNo)
            setPageNo(newPageNo);
            initData(newPageNo);
        }

    };
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 57,
                    lineHeight: '57px',
                }}
            >
                <button className='load-more-button' onClick={onLoadMore}>加载更多</button>
            </div>
        ) : null;

    const goToBuy = (item, e) => {
        e.preventDefault()
        e.stopPropagation()
        // console.log('userInformation?.is_auth123--->', userInformation?.is_auth)
        if (userInformation?.is_auth === false) {
            return setAuthVisiable(true)
        }

        dispatch({ type: "NFT_DETAIL_ITEM", nftDetialItem: item });
        history.push('/home/pay');
    }
    const showAuthModal = (flag) => {
        setAuthVisiable(flag)
    }
    return (
        <div className='Market'>
            <div className='market-content'>
                <main>
                    <div className='selects'>
                        <Select
                            defaultValue={contentType ? contentType : "全部"}
                            style={{
                                width: 250,
                            }}
                            onChange={handleChangeCategory}
                        >
                            {content_type_list.length && content_type_list.map((v, i) => {
                                return <Option value={v.key} key={i}>{v.name}</Option>
                            })}
                        </Select>
                        <Select
                            defaultValue="综合排序"
                            style={{
                                width: 250,
                            }}
                            onChange={handleChangeSort}
                        >
                            {
                                order_by_list.length && order_by_list.map((v, i) => {
                                    return <Option value={i} key={i}>{v.name}</Option>
                                })
                            }

                        </Select>
                    </div>
                    <div className='content'>
                        <List
                            className="demo-loadmore-list"
                            loading={initLoading}
                            loadMore={loadMore}
                            grid={{ gutter: 16, column: 4 }}
                            dataSource={list}
                            renderItem={v => (
                                <List.Item>
                                    <div className='work-card' key={v.asset_id} onClick={() => {
                                        history.push('/home/nftdetail' + v.asset_id, { v })
                                    }}
                                    >
                                        <div className='works-img-item'><img src={setImageFun(v?.meta_data)} /></div>
                                        <div className='card-describe'>
                                            <div>
                                                <div className='name'>
                                                    {v?.name}
                                                </div>
                                                <div className='dec'>
                                                    {v?.collection_name}
                                                </div>
                                                <div className='buy-button' onClick={(e) => goToBuy(v, e)}>立即购买</div>
                                                <div className='sale-status'>{v.status}</div>
                                            </div>
                                            <div className='footer'>
                                                <div>¥{v?.price}</div>
                                                <div>
                                                    <HeartOutlined /> <span>{v?.star_num}</span>
                                                </div>
                                            </div>
                                            <div className='avatar-mask'>
                                            </div>
                                        </div>
                                    </div>
                                </List.Item>
                            )}
                        />
                        {/* {list.length === 0 && <Empty className='noStatusBack' image={noStatusBack} />} */}
                    </div>
                </main>
            </div>
            <AuthModal visiable={authVisiable} authStatus={dataAuthStatus?.status} showAuthModal={showAuthModal}></AuthModal>
        </div>
    )
}
