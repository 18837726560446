import { useState } from "react"
import "../scss/box.scss"
import { Image } from "antd"
import avatar from '@/assets/img/default-avatar.svg'
import { HeartFilled } from "@ant-design/icons"
import { useHistory } from "react-router-dom";

export default function Creator(props) {
  let { creator } = props;
  const history = useHistory();
  console.log(creator,'creator')
  return (
    <div className="box-card f-c creator-card" onClick={() => {
          history.push('/home/creator', { seller_id: creator.user_id })
   }}>
      <div className="f-r f-c-e" style={{ margin: "0.1rem" }}>
        <HeartFilled style={{ fontSize: "16px" }} />
      </div>
      <div className="top f-c f-c-c">
        <img
          src={creator.avatar || avatar }
          className="creator-img"
        />
      </div>
      <div className="bottom f-c f-c-c">
        <div>{creator.username || "没有名字"}</div>
        <div className="label">{creator.brief|| "没有简介"}</div>
      </div>
    </div>
  )
}
