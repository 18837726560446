import React,{useState} from 'react'
import Header from '../components/header/index'
// import {NavLink} from 'react-router-dom'
import  RouterView  from '../router/RouterView'
import "../css/index.scss"

export default function Home(props) {

  const {chil} = props;
  const [flags, setFlags] = useState(false);
   
  const setFlagsFun = ()=>{
      setFlags(!flags)
  }
  
  return (
    <div className="home">
                    <Header  history={props.history} flags={flags} setFlagsFun={setFlagsFun}  />
                    <main>
                          <RouterView routerList = {chil}>
                          </RouterView>
                    </main>
                    {/* <footer>
                          {
                               navLink.map((item,index)=>{
                                     return <NavLink key={index} to = {item.path}>
                                         {item.title}
                                     </NavLink>
                               })
                          }    
                    </footer>  */}
            </div>
  )
}
