import React, { useState, useEffect } from 'react'
import { message, Popover, Row, Col, List } from "antd";
import { useHistory } from "react-router-dom";
import './index.scss'
import { Select, Button, Modal, InputNumber, Form,Divider, Skeleton } from 'antd';
import { Avatar } from 'antd';
import { EyeOutlined, HeartOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CreateWorks from '../CreateWorks/index.js'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { userNftPrivate, top, } from '../../../../api/user'
import { NftOrderAdd, NftOrderDown, nftBurn } from '../../../../api/nft'
import { asset } from '@/api/public'
import InfiniteScroll from 'react-infinite-scroll-component';
const { Option } = Select;
const ContainerHeight = 1000;

export default function WorksAdmin(props) {
        const [flag, setFlag] = useState(2);
        const history = useHistory();
        const [worksPageStatus, setWorksPageStatus] = useState(2);//控制作品管理页面展示的状态1.无数据 2. 有数据 3. 创建页面
        const dispatch = useDispatch();
        // const [nftStatus,setNftStatus] = useState()
        const [form] = Form.useForm();
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [createWorksList, setCreateWorksList] = useState([]);
        const [assetUserMation, setAssetUserMation] = useState({});
        const content_type_list = [{ key: "All", name: "全部" }, { key: "Avatar", name: "头像" }, { key: "Music", name: "音乐" }, { key: "RightCard", name: "权益卡" }, { key: "DriverSkin", name: "车机皮肤" }];
        const [works, setWorks] = useState("");
        const [nftId, setNftId] = useState();
        const [isTop, setIsTop] = useState();
        const [pageNo, setPageNo] = useState(1);
        const [allPage, setAllPage] = useState(1);
        const [contentItem, setContentItem] = useState("All");
        const [statusItem, setStatusItem] = useState(1);
        const [statusType, setStatusType] = useState([{ key: 0, name: "未上架" }, { key: 1, name: "出售中" }, { key: 2, name: "已出售" }, { key: 3, name: "下架" }])
        const { statusCreate, activeType, loginInformation, } = useMappedState((state) => {
                return state;
        })
        const content = (
                <div>
                        创作者可免费铸造作品，当您的作品成功出售时，平台将按《平台服务协议》中约定的比例收取平台服务费。作品上链免费，卖出一件作品，平台将收取手续费10%。
                </div>
        );
        const noticeContent = (v) => {
                return (
                        <div className='dl'>
                                <a onClick={() => {
                                        if (v?.asset_id === "0") {
                                                history.push('/home/detail_creator' + v?.owner_id, { nftDetailItem: v, statusItem })

                                        } else {
                                                history.push('/home/detail_creator' + v?.asset_id, { nftDetailItem: v, statusItem })
                                        }
                                }}>
                                        <Avatar className='works-Avatar' shape="square" src={setImageFun(v?.meta_data)} />
                                </a>
                                <div className='works-content-box'>
                                        <div className='title'>
                                                {v?.nft_name}
                                        </div>
                                        <div className='eye-love'>
                                                {/* <div className='eye'>
                                                <EyeOutlined />
                                                &nbsp;1560
                                        </div> */}
                                                <div className='love'>
                                                        <HeartOutlined />
                                                        &nbsp;{v.star_num}
                                                </div>
                                        </div>
                                        <div className='label-list-box'>
                                                {statusItem === 1 && <div className='lael-item'>
                                                        出售中 {v?.status === 1 ? v.count : 0}
                                                </div>}
                                                {statusItem === 2 && <div className='lael-item'>
                                                        已出售 {v?.deal_count}
                                                </div>}
                                                {statusItem === 0 && <div className='lael-item'>
                                                        已下架 {v?.status === 3 ? v.count : 0}
                                                </div>}
                                                <div className='lael-item'>
                                                        {v?.create_date}
                                                </div>
                                        </div>
                                        {statusItem != 0 && <div className='works-count'>
                                                上架数量/作品总量: {v.count + '/' + v.total_quantity}
                                        </div>}
                                        {statusItem === 0 && <div className='works-count'>
                                                作品数量: {v.total_quantity}
                                        </div>}
                                        <div className='footer-status'>
                                                <div className='status-left' onClick={() => {
                                                        topFun(v?.owner_id)
                                                        setIsTop(true)
                                                }}>
                                                        作品置顶
                                                </div>
                                                {(statusItem === 0) && <div className='status-right' onClick={() => {
                                                        setNftId(v?.nft_base_id)
                                                        setWorks("1");
                                                        setIsModalOpen(true);
                                                }}>
                                                        上架作品
                                                </div>}
                                                {statusItem === 0 && <div className='status-right' onClick={() => {
                                                        setIsModalOpen(true);
                                                        setNftId(v?.nft_base_id)
                                                        setWorks("2");
                                                }}>
                                                        销毁作品
                                                </div>}
                                                {statusItem === 1 && <div className='status-right' onClick={() => {
                                                        setIsModalOpen(true);
                                                        setNftId(v?.asset_id)
                                                        setWorks("3");
                                                }}>
                                                        下架作品
                                                </div>}
                                        </div>
                                </div>
                        </div>
                )
        }
        const getCreateWorksList = async () => {
                if (activeType === 2) {
                        setWorksPageStatus(3)
                }
                const params = {
                        page_no: pageNo,
                        page_size: 10,
                        ascending: true,
                        all_type: true,
                        order_by: "time",
                        status_type: 1,
                }
                await userNftPrivate(params).then(res => {
                        if (res.code == 200) {
                                setCreateWorksList(createWorksList.concat(res.data.items))
                                if (res.data.total % 10) {
                                        const allPage = Math.floor(res.data.total / 10) + 1;
                                        setAllPage(allPage);
                                } else {
                                        const allPage = res.data.total / 10;
                                        setAllPage(allPage);
                                }
                        }
                })
        }

        const setImageFun = (value) => {
                if (value) {
                        const image = JSON.parse(value)
                        return image.FrontCoverUrl;
                }

        }
        const setContentType = (value) => {
                let content_type = ""
                statusType.length && statusType?.map((v, i) => {
                        if (v?.key == value) {
                                content_type = v.name;
                        }
                })
                return content_type;
        }

        const topFun = async (id) => {
                const params = {
                        is_top: true,
                        owner_id: id,
                }
                await top(params).then(res => {
                        if (res.code === 200) {
                                getCreateWorksList();
                                message.success("置顶成功");
                        }
                })
        }

        const handleCancel = () => {
                form.resetFields();
                setIsModalOpen(false);
        };
        const getAssetfun = async () => {
                const params = {
                        user_id: loginInformation?.id,
                }
                await asset(params).then(res => {
                        setAssetUserMation(res.data);
                })
        }
        const onFinishAdd = async (values) => {
                if (works === "1") {
                        const params = {
                                count: values.count,
                                end_time: 3,
                                nft_base_id: Number(nftId),
                                price: values.price
                        }
                        await NftOrderAdd(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        getCreateWorksList();
                                        message.success("上架成功");
                                }
                        })
                } else if (works === "2") {
                        const params = {
                                count: values.count,
                                nft_base_id: Number(nftId),
                        }
                        await nftBurn(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        getCreateWorksList();
                                        message.success("销毁成功");
                                }
                        })
                } else if (works === "3") {
                        const params = {
                                asset_id: nftId,
                        }
                        await NftOrderDown(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        getCreateWorksList();
                                        message.success("下架成功");
                                }
                        })
                }
        };
        const titles = () => {
                if (works === "1") {
                        return "上架作品"
                } else if (works === "2") {
                        return "销毁作品"
                } else if (works === "3") {
                        return "下架作品"
                }
        }
        const handleChange = async (value) => {
                setContentItem(value);
                const params = {
                        ascending: true,
                        order_by: "time",
                        status_type: statusItem,
                }
                if (value === "All") {
                        params.all_type = true;
                } else if (value === "Avatar") {
                        params.avatar = true;
                } else if (value === "Music") {
                        params.music = true;
                } else if (value === "RightCard") {
                        params.right_card = true;
                } else if (value === "DriverSkin") {
                        params.driver_skin = true;
                }
                await userNftPrivate(params).then(res => {
                        setCreateWorksList(res.data.items)
                        if (res.data.total % 10) {
                                const allPage = Math.floor(res.data.total / 10) + 1;
                                setAllPage(allPage);
                        } else {
                                const allPage = res.data.total / 10;
                                setAllPage(allPage);
                        }
                })
        };
        const initStatus = ()=>{
                setWorksPageStatus(2)
                dispatch({ type: "ACTIVE_TYPE", activeType: 0 })
        }
        const TableChange = async (status_type, value) => {          
                const params = {
                        ascending: true,
                        order_by: "time",
                        status_type: status_type,
                }
                if (value === "All") {
                        params.all_type = true;
                } else if (value === "Avatar") {
                        params.avatar = true;
                } else if (value === "Music") {
                        params.music = true;
                } else if (value === "RightCard") {
                        params.right_card = true;
                } else if (value === "DriverSkin") {
                        params.driver_skin = true;
                }
                // setCreateWorksList([])
                await userNftPrivate(params).then(res => {
                        setCreateWorksList(res.data.items)
                        if (res.data.total % 10) {
                                const allPage = Math.floor(res.data.total / 10) + 1;
                                setAllPage(allPage);
                        } else {
                                const allPage = res.data.total / 10;
                                setAllPage(allPage);
                        }
                })
        };
        const onScroll = (e) => {
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
                        setPageNo(pageNo + 1);
                        // 如果没到视图底部就加载列表
                        if (allPage >= pageNo) {
                                getCreateWorksList();
                        }
                }
        };
        useEffect(() => {
                getCreateWorksList();
        }, [])
        useEffect(() => {
                getAssetfun();
        }, [loginInformation])
        console.log(contentItem,'contentItem')
        return (
                <div className='WorksAdmin'>
                        <Modal title={titles()}
                                open={isModalOpen}
                                onCancel={handleCancel}
                                footer={null}
                        >
                                <Form
                                        name="basic"
                                        form={form}
                                        labelCol={{
                                                span: 6,
                                        }}
                                        wrapperCol={{
                                                span: 16,
                                        }}
                                        onFinish={onFinishAdd}
                                        autoComplete="off"
                                >
                                        {works === "1" &&
                                                <Form.Item
                                                        label="上架数量"
                                                        name="count"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }
                                        {works === "1" &&
                                                <Form.Item
                                                        label="作品价格"
                                                        name="price"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }
                                        {works === "2" &&
                                                <Form.Item
                                                        label="销毁数量"
                                                        name="count"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }

                                        <Form.Item wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                        }}>
                                                <Row>
                                                        <Col span={12}>
                                                                <Button type="primary" htmlType="submit">
                                                                        确认
                                                                </Button>
                                                        </Col>
                                                        <Col span={12}>
                                                                <Button onClick={handleCancel}>
                                                                        取消
                                                                </Button>
                                                        </Col>
                                                </Row>
                                        </Form.Item>
                                </Form>
                        </Modal>
                        <div className='works-admin-title-box'>
                                {worksPageStatus === 3 && <div className='CreateSeries-back' onClick={() => {
                                        setWorksPageStatus(2)
                                        dispatch({ type: "ACTIVE_TYPE", activeType: 0 })
                                }}>
                                        <ArrowLeftOutlined /> &nbsp; 返回
                                </div>}
                                <div></div>
                                {worksPageStatus === 2 && <div className='worksadmin-right-explain'>
                                        费用说明
                                        &nbsp;
                                        <Popover content={content} title="费用说明"><QuestionCircleOutlined /></Popover>
                                </div>}
                        </div>
                        {worksPageStatus != 3 && <div className='header-table-box'>
                                <div className='header-table'>
                                        <a className={statusItem === 1 ? "active" : ""} onClick={() => {
                                                setStatusItem(1)
                                                TableChange(1, contentItem)
                                        }}>出售中{assetUserMation?.list_quantity}</a>
                                        <a className={statusItem === 2 ? "active" : ""} onClick={() => {
                                                setStatusItem(2)
                                                TableChange(2, contentItem)
                                        }}>已出售{assetUserMation?.sold_quantity}</a>
                                        <a className={statusItem === 0 ? "active" : ""} onClick={() => {
                                                setStatusItem(0)
                                                TableChange(0, contentItem)
                                        }}>未上架{assetUserMation?.not_on_quantity}</a>
                                </div>
                                <div className='right-allcount'>
                                        总共有 {assetUserMation?.nft_count} 个作品
                                </div>
                        </div>}
                        {worksPageStatus === 2 && <div className='selects'>
                                <Select
                                        defaultValue="全部"
                                        style={{
                                                width: 250,
                                        }}
                                        onChange={handleChange}
                                >
                                        {
                                                content_type_list.map((v, i) => {
                                                        return <Option value={v.key} key={i}>{v.name}</Option>
                                                })
                                        }
                                </Select>
                        </div>}

                        <div className='worksAdmin-box'>
                                {worksPageStatus === 2 && <InfiniteScroll
                                dataLength={createWorksList.length}
                                next={createWorksList}
                                // hasMore={createWorksList.length < 50}
                                loader={
                                <Skeleton
                                avatar
                                paragraph={{
                                rows: 1,
                                }}
                                active
                                />
                                }
                                endMessage={<Divider plain>没有更多数据了🤐</Divider>}
                                scrollableTarget="scrollableDiv"
                        >
                                    <List>
                                        <VirtualList
                                                data={createWorksList}
                                                height={ContainerHeight}
                                                itemHeight={47}
                                                itemKey="id"
                                                onScroll={onScroll}
                                        >
                                                {(item) => (
                                                        <List.Item key={item?.uri}>
                                                                <List.Item.Meta
                                                                     description={noticeContent(item)}
                                                                />
                                                        </List.Item>
                                                )}
                                        </VirtualList>
                                </List> </InfiniteScroll>}
                        {
                                createWorksList.length === 0 && worksPageStatus === 2 && <div className='create-button' id={statusCreate === 2 || activeType === 2 ? "none" : ""} onClick={() => {
                                        setWorksPageStatus(3)
                                }}>
                                        +您还没有作品，点击铸造新作品
                                </div>
                        }
                </div>
                        { worksPageStatus === 3 && <CreateWorks initStatus={initStatus} /> }
                </div >
        )
}
