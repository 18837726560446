import React, { useState, useEffect } from 'react'
import './index.scss'
import { Button, Modal, Form, Input,message } from 'antd';
import { Row, Col } from "antd";
import {send_code} from '@/api/login'
import { payPasswordSet, payPasswordEdit,payForgetPassword } from '@/api/user'
import { nftBuy } from '@/api/nft'

export default function Dialog(props) {
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [isTop, setIsTop] = useState();
    const titles = () => {
        console.log(props.sutusType,"props.sutusType")
        console.log(props.sutusType, "sutusType")
        if (props.sutusType === 1) {
            return "您还未设置支付密码"
        } else if (props.sutusType === 2||props.sutusType === 4) {
            return "请设置支付密码"
        } else if (props.sutusType === 3) {
            return "请输入支付密码"
        }
    }
    const getSendCode = async() =>{
        const params = {
              phone:props.userInformation.phone,
              type:"修改"
        }
        await send_code(params).then(res=>{
            if(res.code===200){
                 message.success("验证码已发送");
            }
    })
    };
    const onFinishAdd =  async(values) => {
        console.log('Success:', values);
        const params = {
            password: values.pay_password
        }
        if (props.sutusType === 1) {
            props.setSutusTypeFun(2)
        }else if(props.sutusType === 2){
                props.setSutusTypeFun(3)
                payPasswordSet(params).then((res) => {
                console.log(res, "res")   
                if (res.code === 200) {
                    message.success('设置成功');
                    props.setSutusTypeFun(3)
                } else {
                    props.setSutusTypeFun(3)
                    message.error('This is an error message');
                }
            })
        }else if(props.sutusType === 3){
            const params = {
                deal_count: props.count,
                asset_id: props.nftDetialItem.asset_id,
                price: props.count * props.nftDetialItem.price,
                pay_password:values.pay_password,
            }
            await nftBuy(params).then((res) => {
                console.log(res,'res')
                if (res?.code === 200) {
                    window.open(res.data,"_self")
                    form.resetFields();
                    props.setIsModalOpenFun(false)
                } else {
                    console.log(res.msg,'res.msg')
                    message.error(res.msg)
                    props.setIsModalOpenFun(false)
                }
            })
        }else if(props.sutusType === 4){
            console.log(form.getFieldsValue().code,'form.getFieldsValue().code')
            const params = {
                code:form.getFieldsValue().code,
                password: values.pay_password,
            }
            await payForgetPassword(params).then((res) => {
                if (res.code === 200) {
                    props.setSutusTypeFun(3)
                }
            })
        }
    }
    const handleCancel = () => {
        form.resetFields();
        props.setIsModalOpenFun(false)
    };
    return (
        <div className='dialog'>
            <Modal title={titles()}
                open={props.isModalOpen}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    name="basic"
                    form={form}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    onFinish={onFinishAdd}
                    autoComplete="off"
                >   
                   {props.sutusType === 4 && <Form.Item label="验证码" >
                        <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                            name="code"
                            noStyle
                            rules={[
                                {
                                required: true,
                                message: '请输入验证码',
                                },
                            ]}
                            >
                            <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Button onClick={()=>{
                                  getSendCode()
                            }}>获取验证码</Button>
                        </Col>
                        </Row>
                    </Form.Item>}
                    {(props.sutusType === 2 || props.sutusType === 4 )&&
                        <Form.Item
                            label="密码"
                            name="pay_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your pay_password!',
                                },
                            ]}
                        >
                            <Input.Password style={{ width: "100%" }} />
                        </Form.Item>
                    }
                    {(props.sutusType === 2 ||props.sutusType === 4 )&&
                        <Form.Item
                        name="confirm"
                        label="确认密码"
                        dependencies={['pay_password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('pay_password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('你两次输入的密码不一致'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    }
                   
                   {props.sutusType === 3 &&
                        <Form.Item
                            label="密码"
                            name="pay_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your pay_password!',
                                },
                            ]}
                        >
                            <Input.Password style={{ width: "100%" }} />
                        </Form.Item>
                    }
                     {props.sutusType === 3 &&
                            <Row>
                                <Col span={14}></Col>
                                <Col span={1} offset={4}>
                                      <Button type="link" onClick={()=>{
                                             props.setSutusTypeFun(4)
                                      }}>忘记密码</Button>
                                </Col>
                           </Row>
                     }
                    <Form.Item wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}>
                        <Row>
                            <Col span={12}>
                                <Button type="primary" htmlType="submit">
                                    {props.sutusType === 1 && "去设置"}
                                    {props.sutusType === 2 && "确定"}
                                    {props.sutusType === 3 && "确定"}
                                    {props.sutusType === 4 && "确定"}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button onClick={handleCancel}>
                                    {props.sutusType === 1 && "取消支付"}
                                    {props.sutusType === 2 && "取消"}
                                    {props.sutusType === 3 && "取消"}
                                    {props.sutusType === 4 && "取消"}
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
