import React, { useState, useEffect } from "react";
import { Select, Avatar, message } from "antd";
import { HeartOutlined, CopyOutlined } from "@ant-design/icons";
import { follow, followers } from "../../api/user";
import { useDispatch, useMappedState } from "redux-react-hook";
import { creatorNftPublic, isStar, Collection, profile } from "@/api/user";
import { asset, profileUrl } from "@/api/public";
import "./index.scss";
import copy from "copy-to-clipboard";
const { Option } = Select;

export default function CreatorUrl(props) {
	const [flag, setFlag] = useState(1);
	const [followersList, setFollowersList] = useState([]);

	// const { seller_id} = props.location.state;
	const content_type_list = [
		{ key: "All", name: "全部" },
		{ key: "Avatar", name: "头像" },
		{ key: "Music", name: "音乐" },
		{ key: "RightCard", name: "权益卡" },
		{ key: "DriverSkin", name: "车机皮肤" }
	];
	const [fansCount, setFansCount] = useState([]);
	const [creatorNftPublicList, setCreatorNftPublicList] = useState([]);
	const [createCollectionList, setcreateCollectionList] = useState([]);
	const [userInformation, setUserInformation] = useState();
	const [acitves, setAcitves] = useState(1);
	const [star, setStar] = useState();

	const [seller_id, setSeller_id] = useState("");
	const getUserIdFun = async () => {
		const urlParams = new URL(window.location.href);
		const pathname = urlParams?.pathname;
		const newUrl = pathname.slice(15, 50);
		console.log("newUrl:", newUrl);
		const params = {
			url: newUrl
		};
		try {
			const res = await profileUrl(params);
			console.log(res, "res");
			setSeller_id(res.data.user_id);
			getSingleInfor(res.data.user_id);
			isStarFun(res.data.user_id);
			getfollowersList(res.data.user_id);
			collectionNftFun(res.data.user_id);
			getCreateCollectionListfun(res.data.user_id);
		} catch (error) {
			message.error(error);
		}
	};
	const isStarFun = async id => {
		const params = {
			id: id,
			type: "User"
		};
		await isStar(params).then(res => {
			setStar(res.data.is_star);
		});
	};

	const getSingleInfor = async id => {
		const params = {
			user_id: id
		};
		await profile(params).then(res => {
			setUserInformation(res.data);
		});
	};
	const getfollowersList = async id => {
		let params = {
			page_no: 1,
			page_size: 20,
			user_id: id
		};
		await followers(params).then(res => {
			setFollowersList(res.data.follow);
			setFansCount(res.data.count);
		});
	};
	const collectionNftFun = async id => {
		const params = {
			// page_no: 1,
			// page_size: 20,
			ascending: true,
			all_type: true,
			order_by: "time",
			status_type: 0,
			avatar: true,
			driver_skin: true,
			music: true,
			right_card: true,
			user_id: id
		};
		await creatorNftPublic(params).then(res => {
			setCreatorNftPublicList(res.data);
		});
	};
	const getCreateCollectionListfun = async id => {
		let params = {
			page_no: 1,
			page_size: 20,
			user_id: id
		};
		await Collection(params).then(res => {
			setcreateCollectionList(res.data);
		});
	};

	const handleChange = async value => {
		const params = {
			// page_no: 1,
			// page_size: 20,
			ascending: true,
			order_by: "time",
			status_type: 0,
			avatar: "",
			driver_skin: "",
			music: "",
			right_card: "",
			user_id: seller_id
		};
		if (value === "All") {
			params.all_type = true;
		} else if (value === "Avatar") {
			params.avatar = true;
		} else if (value === "Music") {
			params.music = true;
		} else if (value === "RightCard") {
			params.right_card = true;
		} else if (value === "DriverSkin") {
			params.driver_skin = true;
		}

		await creatorNftPublic(params).then(res => {
			setCreatorNftPublicList(res.data);
		});
	};
	const activeChange = async value => {
		const params = {
			// page_no: 1,
			// page_size: 20,
			ascending: true,
			order_by: "time",
			status_type: value || 0,
			all_type: true,
			user_id: seller_id
		};
		// if (value === 1) {
		//      params.status_type = 0;
		// } else if (value === 2) {
		//      params.status_type = 1;
		// } else if (value === 3) {
		//      params.status_type = 2;
		// } else if (value === 4) {

		// }
		// params.status_type = value;

		await creatorNftPublic(params).then(res => {
			setCreatorNftPublicList(res.data);
		});
	};
	const getfollow = async () => {
		setStar(!star);
		const params = {
			following_id: seller_id,
			has_follow: star
		};
		await follow(params).then(res => {
			if (res.code === 200) {
				message.success(`${star ? "已取关" : "已关注"}`);
			}
		});
	};
	const setImageFun = value => {
		const image = JSON.parse(value);
		return image.FrontCoverUrl;
	};

	useEffect(() => {
		getUserIdFun();
	}, []);

	console.log(userInformation, "userInformation");
	console.log(seller_id, "seller_id");
	return (
		<div className="creatorUrl">
			<div className="creator-banner">
				<Avatar shape="square" className="backgroud-Avatar" src={userInformation?.avatar} />
				<Avatar className="creator-Avatar" src={userInformation?.avatar} />
			</div>
			<div className="creator-content">
				<div className="creator-content-message">
					<div className="title">
						<div className="nickname">{userInformation?.username}</div>
						<a
							className="message-button"
							onClick={() => {
								getfollow();
							}}
						>
							{star ? "已关注" : "加关注"}
						</a>
					</div>
					<div className="message-address">
						<div className="message-address-content">区块链合约地址{userInformation?.address}</div>
						<div className="message-address-date">加入时间:2022-10-1</div>
					</div>
					<div className="describe">{userInformation?.brief}</div>
					<div
						className="singleWeb"
						onClick={() => {
							if (userInformation?.creator_url) {
								copy("https://pdawn.ccsmec.com/home/creators/" + userInformation?.creator_url);
								message.success("复制成功");
							}
						}}
					>
						<div className="singleWeb-left">
							{" "}
							个人网站:
							{userInformation?.creator_url
								? `https://pdawn.ccsmec.com/home/creators/${userInformation?.creator_url}`
								: "未设置网站地址"}
						</div>
						<div className="singleWeb-right">
							{userInformation?.creator_url && <CopyOutlined />}
							{userInformation?.creator_url && "复制"}
						</div>
					</div>
				</div>
				<div className="header-table-box">
					<div className="header-table">
						<div
							className={flag === 1 ? "active" : ""}
							onClick={() => {
								setFlag(1);
							}}
						>
							作品数量{creatorNftPublicList.length}
						</div>
						<div
							className={flag === 2 ? "active" : ""}
							onClick={() => {
								setFlag(2);
							}}
						>
							作品系列{createCollectionList.length}
						</div>
						<div
							className={flag === 3 ? "active" : ""}
							onClick={() => {
								setFlag(3);
							}}
						>
							粉丝数{fansCount}
						</div>
					</div>
				</div>
				{flag === 1 && (
					<div className="lable-form">
						<div className="lable-list">
							<div
								className={acitves === 1 ? "active" : ""}
								onClick={() => {
									setAcitves(1);
									activeChange(0);
								}}
							>
								全部
							</div>
							<div
								className={acitves === 2 ? "active" : ""}
								onClick={() => {
									setAcitves(2);
									activeChange(1);
								}}
							>
								出售中
							</div>
							<div
								className={acitves === 3 ? "active" : ""}
								onClick={() => {
									setAcitves(3);
									activeChange(2);
								}}
							>
								已出售
							</div>
							<div
								className={acitves === 4 ? "active" : ""}
								onClick={() => {
									setAcitves(4);
									activeChange(0);
								}}
							>
								未上架
							</div>
						</div>
						<Select
							defaultValue="全部"
							style={{
								width: 250
							}}
							onChange={handleChange}
						>
							{content_type_list.map((v, i) => {
								return (
									<Option value={v.key} key={i}>
										{v.name}
									</Option>
								);
							})}
						</Select>
					</div>
				)}
				{flag === 1 && (
					<div className="content">
						{creatorNftPublicList?.map((v, i) => {
							return (
								<div
									className="card-my"
									key={i}
									onClick={() => {
										props.history.push("/home/nftdetail" + v.asset_id, { v });
									}}
								>
									<img alt="example" className="card-my-avatar" src={setImageFun(v?.meta_data)} />
									<div className="card-content-box">
										<div className="title">{v?.name}</div>
										<div className="miaoshu">{v?.collection_name}</div>
										<div className="card-content">
											<div className="price">
												<div className="price-symbol">￥</div> {v.price}
											</div>
											<div className="house">
												<HeartOutlined />
												&nbsp;
												{v?.star_num}
											</div>
										</div>
									</div>
									<div className="button">立即购买</div>
									<div className="label">未上架</div>
									<div className="avatar-mask"></div>
								</div>
							);
						})}
					</div>
				)}
				{flag === 2 && (
					<div className="works">
						{createCollectionList?.map((v, i) => {
							return (
								<li key={i}>
									<img className="backgroud-works" src={v?.home_pic_url} />
									<div className="works-label">
										<img className="touxiang-works" src={v?.logo_pic_url} />
										<div className="collection-name">{v?.name}</div>
										<div className="starnum">
											<HeartOutlined />
											&nbsp;{v?.star_num}
										</div>
									</div>
								</li>
							);
						})}
					</div>
				)}
				{flag === 3 && (
					<div className="list-card-header">
						<div className="list-card-header-first">昵称</div>
						<div className="list-card-header-last">简介</div>
						<div className="list-card-header-first">关注时间</div>
					</div>
				)}
				{flag === 3 &&
					followersList?.map((v, i) => {
						return (
							<div className="list-card-detail">
								<div className="avate-meassage">
									<Avatar className="avatar" src={v.avatar} />
									<div className="avata-name">{v.username}</div>
								</div>
								<div className="pron-message">
									<div className="one">{v.brief}</div>
									<div className="two">{v.update_date}</div>
								</div>
							</div>
						);
					})}
			</div>
		</div>
	);
}
