import React, { useState, useEffect } from 'react'
import { Avatar,List  } from 'antd';
import { follow, followers } from '../../../../api/user'
import { useMappedState } from 'redux-react-hook';
import { Empty } from 'antd';
import VirtualList from 'rc-virtual-list';
import { noStatusBack } from '@/utils/icon'
import avatar from '@/assets/img/default-avatar.svg'
import './index.scss'
const ContainerHeight = 600;




export default function FansPage() {
       const [followersList, setFollowersList] = useState([]);
       const [fansCount, setFansCount] = useState([]);
       const [pageNo, setPageNo] = useState(1);
       const [allPage, setAllPage] = useState(1);
       const { loginInformation } = useMappedState((state) => {
              return state;
       })

       const noticeContent = (v) => {
              return (
                     <li key={v?.buyer_id}>
                     <div className='list-card-one'>
                            <Avatar className='type-icon' src={v?.avatar || avatar} />
                            <div className='card-one-box'>
                                   <div className='card-one-title'>
                                          {v?.username}
                                   </div>
                            </div>
                     </div>
                     <div className='list-card-two'>
                            {v?.brief}
                     </div>
                     <div className='list-card-three'>
                            {v?.update_date}
                     </div>
              </li>
              )
            }

       const getfollowersList = async () => {
              let params = {
                     page_no: pageNo,
                     page_size: 10,
                     user_id: loginInformation.id,
              }
              await followers(params).then(res => {
                     setFollowersList(followersList.concat(res.data.follow))
                     setFansCount(res.data.count)
                     if(res.data.count%10){
                            const allPage  = Math.floor(res.data.count/10)+1;
                            setAllPage(allPage);
                       }else{
                            const allPage  = res.data.count/10;
                            setAllPage(allPage);
                       }
              })
       }
       const onScroll = (e) => {
              if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
                setPageNo(pageNo + 1);
                // 如果没到视图底部就加载列表
                if (allPage>=pageNo) {
                     getfollowersList();
                }
              }
            };
       useEffect(() => {
              getfollowersList();
       }, [])
       console.log(followersList, "followersList")
       return (
              <div className='FansPage'>
                     <div className='bill-list-box'>
                            <ul className='bill-list-title'>
                                   <li className='one'>昵称</li>
                                   <li className='two'>简介</li>
                                   <li>关注时间</li>
                            </ul>
                            <ul className='bill-list-card'>
                                     <List split='flase'>
                                          <VirtualList
                                          data={followersList}
                                          height={ContainerHeight}
                                          itemHeight={47}
                                          itemKey="user_id"
                                          onScroll={onScroll}
                                          >
                                          {(item) => (
                                                 <List.Item key={item?.user_id}>
                                                        <List.Item.Meta
                                                        // avatar={<Avatar src={setImageFun(item?.meta_data)} shape="square" style={{ width: 60, height: 60 }} />}
                                                        description={noticeContent(item)}
                                                        />
                                                 </List.Item>
                                          )}
                                          </VirtualList>
                                   </List>
                                   {followersList?.length === 0 && <Empty className='noStatusBack'  description='什么都没有～' image={noStatusBack} />}
                            </ul>
                     </div>
              </div>
       )
}
