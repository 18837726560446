// 防抖函数
export function deBounce(func, wait) {
    let timeOut = null
    return function (...args) {
      clearTimeout(timeOut) // 一定要清除定时器
      timeOut = setTimeout(() => {
        func(...args)
      }, wait)
    }
  }
  
  // 节流函数 method：方法 delay：对应多少秒触发最后一次 duration： 多少秒必须触发一次
  export function throttle(method, delay, duration) {
    console.log(method, delay, duration,'method, delay, duration')
    var timer = null
    var begin = new Date()
    return function () {
      var context = this,
        args = arguments
      var current = new Date()
      clearTimeout(timer)
      if (current - begin >= duration) {
        method.apply(context, args)
        begin = current
      } else {
        timer = setTimeout(function () {
          method.apply(context, args)
        }, delay)
      }
    }
  }
  