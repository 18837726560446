import { useState,useEffect } from "react"
import "../scss/box.scss"
import { Image } from "antd"
import { HeartFilled } from "@ant-design/icons"
import { useHistory } from "react-router-dom";
import { useMappedState } from "redux-react-hook"


export default function StarSerios(props) {
       // 我的关注数据
  const [starNFTList, setStarNftList] = useState([]) //关注的作品
  const [starNftTotal, setStarNftTotal] = useState(0)
  const [nftPageNo, setNftPageNo] = useState(1)
  const [followList,setFollowList] = useState([]);
  const [starSeriosTotal, setStarSeriosTotal] = useState(0)
  const [seriosPageNo, setSeriosPageNo] = useState(1)
  const history = useHistory();
  const {serios} = props
  const [starSeriosList, setStarSeriosList] = useState([]) //关注的系列
  const { loginInformation } = useMappedState(state => {
    return state
  })
  
  useEffect(() => {
  
  }, [])
   console.log(starSeriosList,"starSeriosList");
  return (
    <div>
       {
            // starSeriosList.map((v,i)=>{
            //      return 
                  <div className="box-card f-c serios-card" onClick={() => {
                    history.push('/home/worksSeries' + serios?.collection_id, { collection_id: serios?.collection_id })
              }}>
                 <div className="top">
                   <Image
                     src={serios?.face_pic_url || "error"}
                     className="top-img serios-img f-r f-c-c"
                   />
                   <div className="avator f-r f-c-c">
                     <Image
                       src={serios?.logo_pic_url || "error"}
                       className="avator-img "
                     />
                   </div>
                 </div>
         
                 <div className="bottom f-c">
                   <div className="f-r f-c-c">
                     <div>{serios.name || "没有名字"}</div>
                   </div>
                   <div style={{ textAlign: "center" }} className="f-r f-c-c">
                     <HeartFilled style={{ fontSize: "16px" }} />
                     &nbsp;{serios.star_num || 0}
                   </div>
                 </div>
               </div>
            // })
       }
     
    </div>

  )
}
