import React, { useState, useEffect } from "react";
import "./homes.scss";
import DarkFooter from "../../components/Footers/DarkFooter";
import { getNftList, notice } from "@/api/nft";
import { useDispatch, useMappedState } from "redux-react-hook";
import PopularityCreator from "./component/PopularityCreator";
import TableSee from "./component/TableSee";
import HeaderTop from "./component/HeaderTop";
import SelectedSeries from "./component/SelectedSeries";
import FixedLabel from "./component/FixedLabel";
import SelectedWorks from "./component/SelectedWorks/index";

export default function Homes(props) {
	const [nftList, setnftList] = useState([]);
	const [noticeList, setNoticeList] = useState([]);
	const dispatch = useDispatch();
	const { token } = useMappedState(state => {
		return state;
	});
	const getNftListFun = async () => {
		let params = {
			page_no: 1,
			page_size: 4,
			ascending: true,
			content_type: "All",
			order_by: "Price"
		};
		await getNftList(params).then(res => {
			console.log(res, "res");
			console.log(res.data, "res.data");
			setnftList(res?.data);
			if (res.code == 200) {
				dispatch({ type: "GET_NFT_LIST", nftList: res.data });
			}
		});
	};

	const getNotice = async () => {
		let params = {
			page_no: 1,
			page_size: 20
		};
		await notice(params).then(res => {
			setNoticeList(res.data);
		});
	};
	console.log();
	useEffect(() => {
		getNftListFun();
		getNotice();
	}, [token]);

	useEffect(() => {
		// window.location.reload()
	}, [nftList]);

	console.log(nftList, "nftList");
	console.log(noticeList, "noticeList");

	return (
		<div className="homes">
			<HeaderTop />
			<FixedLabel />
			<div className="homes-content">
				<SelectedWorks />
				<SelectedSeries />
				<PopularityCreator />
				<TableSee />
			</div>

			<DarkFooter />
		</div>
	);
}
