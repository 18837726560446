import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message, } from 'antd';
import { send_code } from '@/api/login'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMappedState, useDispatch } from 'redux-react-hook'
import {forget_password} from '@/api/login.js'
import {phoneEdit} from '@/api/user.js'
import './index.scss'


export default function EditPassword(props) {
    const [phone, setPhone] = useState("")
    const [code, setCode] = useState("");//短信验证码
    const [values, setValues] = useState("");
    const [form] = Form.useForm();
    const [open, setOpen] = useState("");//获取已设置支付密码的状态
    const { userInformation } = useMappedState((state) => {
        return state;
    })

   
    const onFinish = async(values) => {
        console.log('Success:', values);
        const params = {
            password:values.password,
            code:props.oldCode,
            phone:props.oldPhone,
        }
        console.log(params,"params")
        await forget_password(params).then((res)=>{
              if(res.code===200){
                   message.success('修改完成')
                   props.setAcitvesFun(1);
              }else{
                  message.error(res.msg)
              }
        })
    };
    return (
        <div className='EditPassword'>
            <div className='test-box'>
                <div className='test-back' onClick={() => {
                    props.setAcitvesFun(1)
                }}>
                    <ArrowLeftOutlined />
                    返回
                </div>
                <div className='test-title'>
                    修改密码
                </div>
                <div className='message-test-box'>
                    <Form
                        name="basic"
                        form={form}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="新密码"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入密码！',
                                },
                            ]}
                        >
                            <Input className='message-test'
                                onChange={(e) => {
                                    console.log(e.target.value, "value")
                                    let value = e.target.value;

                                    
                                }} placeholder="密码至少6位大写字母/数字/符号组成" />
                        </Form.Item>
                        <Form.Item
                            label="确认密码"
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: '请再次输入密码',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('你两次输入的密码不一致'));
                                },
                              }),
                            ]}
                        >
                            <Input className='message-test'
                               placeholder="密码至少6位大写字母/数字/符号组成" />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                            offset: 8,
                            span: 16,
                            }}
                        >
                            <Button type="primary" className='button-ok' disabled={values} htmlType="submit">提交</Button>
                        </Form.Item>
                    </Form>
                </div>
             
                
            </div>
        </div>
    )
}
