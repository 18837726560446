import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import store from "./store/index"
import { StoreContext } from "redux-react-hook"
// import "./Mock"
import "antd/dist/antd.min.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/font/font.scss"
// import "./utils/rem"
import "./css/flex.css"


const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <StoreContext.Provider value={store}>
    <App />
  </StoreContext.Provider>
)
