import React, { useState } from 'react'
import './index.scss'
import { message } from 'antd';
import { Button, Form, Input, Upload, Select,Avatar } from 'antd';
import { useMappedState, useDispatch } from 'redux-react-hook'
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { collectionEdit } from '../../../../api/nft'
const { TextArea } = Input;


export default function CreateSeries(props) {
    const [flagRz, setFlagRz] = useState(false);
    const [form] = Form.useForm();
    const [imageUrlAvatar, setImageUrlAvatar] = useState();
    const [imageUrlFace, setImageUrlFace] = useState();
    const [imageUrlHome, setImageUrlHome] = useState();
    const { collectionItem } = useMappedState((state) => {
        return state;
    })
    const { Option } = Select;
    const onFinish = async (values) => {
        console.log('Success:', values);
        const { logo, face, home, catagory, name } = values;
        const formData = new FormData();
        console.log(values, "??????")

        formData.append("catagory", catagory)
        formData.append("collection_id", collectionItem.id)
        formData.append("name", name);

        if (logo) {
            logo.forEach((item, index) => {
                formData.append("logo", item.originFileObj);
            })
        }

        if (face) {
            face.forEach((item, index) => {
                formData.append("face", item.originFileObj);
            })
        }
        if (home) {
            home.forEach((item, index) => {
                formData.append("home", item.originFileObj);
            });
        }
        console.log(formData, "formData")
        await collectionEdit(formData).then((res) => {
            if (res.code == 200) {
                message.success("提交成功")
                props.initStatus()
            }
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleChangeAvatar = (info) => {
        getBase64(info.file.originFileObj, (url) => {
            setImageUrlAvatar(url);
        });
    };

    const handleChangeFace = (info) => {
        getBase64(info.file.originFileObj, (url) => {
            setImageUrlFace(url);
        });
    };

    const handleChangeHome = (info) => {
        getBase64(info.file.originFileObj, (url) => {
            setImageUrlHome(url);
        });
    };
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e.file)) {
            return e.file;
        }
        return e?.fileList;
    };
    return (
        <div className='EditSeries'>

            <div className='CreateSeries-title'>
                编辑系列
            </div>
            <Form
                className='EditSeries-form'
                initialValues={collectionItem}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                // valuePropName="fileList"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="作品系列名称"
                    name="name"
                >
                    <Input placeholder="30个字符以内" disabled onChange={(e) => {
                        if (e.target.value) {
                            setFlagRz(true)
                        } else {
                            setFlagRz(false)
                        }

                        console.log(e.target.value)
                    }} />
                </Form.Item>
                <Form.Item
                    // name="catagoryy"
                    label="类型"
                >
                    <div>普通类型</div>
                </Form.Item>
                <Form.Item
                    name="catagory"
                    label="标签分类"
                >
                    <Select placeholder="请选择类型" disabled>
                        <Option value="皮肤">皮肤</Option>
                        <Option value="音乐">音乐</Option>
                        <Option value="权益卡">权益卡</Option>
                        <Option value="皮肤">皮肤</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="系列简介"
                    name="describe"
                >
                    <TextArea rows={4} disabled  placeholder="添加个人介绍,150个字符以内" onChange={(e) => {
                        if (e.target.value) {
                            setFlagRz(true)
                        } else {
                            setFlagRz(false)
                        }

                        console.log(e.target.value)
                    }} />
                </Form.Item>
                <Form.Item label="头像"
                    extra="此图片用于作品系列导航等显示效果，建议200*200px"
                >
                    <Form.Item name="logo" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                        <Upload name="files" showUploadList={false} onChange={handleChangeAvatar}  maxCount={1}>
                            {imageUrlAvatar ? (
                                <img
                                    src={imageUrlAvatar}
                                    className='createSeries-logo-box'
                                    alt="avatar"
                                />
                            ) : (
                                <img className='createSeries-logo-box' src={collectionItem?.logo_pic_url} alit="" />
                            )} 
                        </Upload>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="封面图片"
                    extra="此图片用于平台首页，市场，分类页面或其他展示。建议使用尺寸460*300px"
                >
                    <Form.Item name="face" valuePropName="fileList" getValueFromEvent={normFile} noStyle >
                        <Upload name="face" showUploadList={false} onChange={handleChangeFace}  maxCount={1}>
                            {imageUrlFace ? (
                                <img
                                    src={imageUrlFace}
                                    className='createSeries-face-box'
                                    alt="avatar"
                                />
                            ) : (
                                <img className='createSeries-face-box' src={collectionItem?.face_pic_url} alt="" />
                            )}
                        </Upload>
                    </Form.Item>
                </Form.Item>

                <Form.Item label="主页图片"
                    extra="此图片展示在系列主页顶部。建议尺寸1920*300px"
                >
                    <Form.Item name="home" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                        <Upload name="home" showUploadList={false} onChange={handleChangeHome}  maxCount={1}>
                               {imageUrlHome ? (
                                    <Avatar
                                        src={imageUrlHome}
                                        className='createSeries-home-box'
                                        alt="avatar"
                                    />
                                ) : (
                                    <Avatar className='createSeries-home-box' src={collectionItem?.home_pic_url} alit />
                                )}
                        </Upload>
                    </Form.Item>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 9,
                        span: 14,
                    }}
                >
                    <Button className='submit' type="primary" htmlType="submit" >
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
