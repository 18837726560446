import React, { useState, useEffect } from 'react'

import { Button, Modal } from 'antd';
import { useMappedState,  } from 'redux-react-hook'
import { NavLink } from 'react-router-dom'
import RouterView from '@/router/RouterView'
import './index.scss'


export default function Wallet(props) {
  const [acitves, setAcitves] = useState(1);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { chil } = props;
  const { walletActiveType } = useMappedState((state) => {
    return state;
  })

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };


  const payOkType = () => {
    if (walletActiveType) {
      setAcitves(walletActiveType)
    }
  }

  useEffect(() => {
    payOkType()
  }, [])

  console.log(walletActiveType, "walletActiveType")
  return (
    <div className='wallet'>
      <Modal
        open={open}
        onOk={handleOk}
        width={460}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            我已完成支付
          </Button>,
          <Button key="back" onClick={handleCancel}>
            我未完成支付
          </Button>,
        ]}
      >
        <div className='Modal' style={{
          fontSize: "24px",
          fontFamily: "OPPOSans-M, OPPOSans",
          fontWeight: "normal",
          color: "#001420",
          with: "100%",
          textAlign: "center",
          marginTop: "10px"
        }}>订单状态确认</div>
      </Modal>
      <div className='space'></div>
      <div className='father-content'>
        <div className='left-card'>
          <p className='setUp-button'>我的钱包</p>
          <div className='ul'>
            <li className={acitves === 1 ? 'active' : ''} onClick={() => {
              setAcitves(1)
              props.history.push('/home/wallet/intergral');
            }}><a>
                积分账户
              </a></li>
            <li className={acitves === 2 ? 'active' : ''} onClick={() => {
              setAcitves(2)
              props.history.push('/home/wallet/rechargePoints');
            }}><a>
                购买积分
              </a></li>
            <li className={acitves === 3 ? 'active' : ''} onClick={() => {
              setAcitves(3)
              props.history.push('/home/wallet/billDetails');
            }}><a>
                账单明细
              </a></li>
            <li className={acitves === 4 ? 'active' : ''} onClick={() => {
              setAcitves(4)
              props.history.push('/home/wallet/paydmin');
            }}><a>
                支付管理
              </a></li>
          </div>
        </div>
        <div className='right-card'>
          <div className='setUp-form'>
            <p className='title-form'>{acitves === 1 ? "积分账户" : (acitves === 2 ? "积分充值" : (acitves === 3 ? "账单明细" : "支付管理"))}</p>
            <RouterView routerList={chil}>
            </RouterView>
          </div>
        </div>
      </div>
    </div>
  )
}
