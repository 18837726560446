import React, { useEffect, useState } from 'react'
import './index.scss'
import { message } from 'antd';
import { Button, Form, Input, Upload, Select, InputNumber, } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useHistory } from "react-router-dom";
import {useMappedState,useDispatch} from 'redux-react-hook';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {nftCreate} from '../../../../api/nft'
import { Collection } from "@/api/user"
const { TextArea } = Input;


export default function CreateWorks(props) {
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [labelType,setLabelType] = useState('');
    const { Option } = Select;
    const [imageUrlFace, setImageUrlFace] = useState();
    const [createCollectionList, setcreateCollectionList] = useState([])
    const {loginInformation} = useMappedState((state)=>{
        return state;
    })
    const uploadButtonFace = (
        <div className='createWorks-face-box'>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
        </div>
    );
    const onFinish = async(values) => {
        console.log('Success:', values); 
        const { count, describe ,front_cover,name,skin,collection_id,music,audition,lyric} = values;
        const formData = new FormData();
        formData.append("content_type",labelType)
        formData.append("name", name)
        formData.append("count", count||1);
        formData.append("describe", describe);
        formData.append("collection_id", collection_id);
        front_cover?.forEach((item) => {
            formData.append("front_cover", item.originFileObj);   	
        })
        skin?.forEach((item) => {
            console.log(item,'item')
            formData.append("skin", item.originFileObj);   	
        })
        music?.forEach((item) => {
            formData.append("music", item.originFileObj);   	
        })
        audition?.forEach((item) => {
            formData.append("audition", item.originFileObj);   	
        })
        lyric?.forEach((item) => {
            formData.append("lyric", item.originFileObj);   	
        })


        console.log(formData,"formData")
        await nftCreate(formData).then((res)=>{
            if(res.code===200){
                   message.success('已创建作品')
                   props.initStatus()
                   history.push('/home/creatnft/homepage')
            }else{
                 message.error('请联系管理员')
            }
      })
    };
     
    const onChange = (value)=>{
          console.log(value,"value")
          createCollectionList.forEach((v,i)=>{
                if(v.id===value){
                    setLabelType(v.categories[0])
                }
          })
    }

    const getCreateCollectionList = async () => {
        let params = {
        //   page_no: 1,
        //   page_size: 20,
          user_id: loginInformation.id,
        }
        await Collection(params).then(res => {
          setcreateCollectionList(res.data.data)
          // dispatch({
          //   type: "GET_CREATE_COLLECTION_LIST",
          //   createCollectionList: res.data,
          // })
        })
      }
      
 
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e.file)) {
            return e.file;
        }
        return e?.fileList;
    };
    const checkLengthuser_30 = (e)=>{
        var l = 0;
        for(var i=0; i<e.target.value.length; i++) {
            if (/[\u4e00-\u9fa5]/.test(e.target.value[i])) {
                l+=2;
            } else {
                l++;
            }
            if (l > 30) {
              e.target.value = e.target.value.substr(0,i);
                break;
            }
        }
    };
    const checkLengthuser_150 = (e)=>{
        var l = 0;
        for(var i=0; i<e.target.value.length; i++) {
            if (/[\u4e00-\u9fa5]/.test(e.target.value[i])) {
                l+=2;
            } else {
                l++;
            }
            if (l > 150) {
              e.target.value = e.target.value.substr(0,i);
                break;
            }
        }
    };
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleChangeFace = (info) => {
        getBase64(info.file.originFileObj, (url) => {
            setImageUrlFace(url);
        });
    };
    useEffect(()=>{
        getCreateCollectionList()
    },[])
    console.log(createCollectionList,"createCollectionList")
    return (
        <div className='CreateWorks'>
            <div className='CreateSeries-title'>
                创建新作品
            </div>
            <Form
                className='CreateWorks-form'
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="作品名称"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入作品名称!',
                        },
                    ]}
                >
                    <Input placeholder="30个字符以内"  onKeyUp={checkLengthuser_30} />
                </Form.Item>
                <Form.Item
                    name="collection_id"
                    label="所属系列"
                    rules={[
                        {
                            required: true,
                            message: '请选择所属系列!',
                        },
                    ]}
                >
                    <Select placeholder="请选择所属系列" onChange={onChange}>
                        {
                            createCollectionList.map((v)=>{
                                 return <Option value={v.id} key={v.id} >{v.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="content_type"
                    label="标签分类"
                >   
                    {labelType}
                </Form.Item>

                <Form.Item label="封面图片"
                    extra="支持文件类型：JPG、PNG、GIF。限制大小：20 MB以内"
                >
                    <Form.Item name="front_cover"  rules={[
                        {
                            required: true,
                            message: '请输入系列名称!',
                        },
                    ]} valuePropName="fileList" getValueFromEvent={normFile} noStyle >
                        <Upload name="front_cover" showUploadList={false} onChange={handleChangeFace} maxCount={1}>
                            {imageUrlFace ? (
                                <img
                                    src={imageUrlFace}
                                    className='createWorks-face-box'
                                    alt="avatar"
                                />
                            ) : (
                                uploadButtonFace
                            )}
                        </Upload>
                    </Form.Item>
                </Form.Item>
                {labelType==='车机皮肤'&&<Form.Item label="apk皮肤包"
                    extra="此图片用于作品系列导航等显示效果，建议200*200px"
                >
                    <Form.Item name="skin" valuePropName="fileList" getValueFromEvent={normFile} noStyle >
                        <Upload.Dragger name="skin" beforeUpload={() => false} maxCount={1}>
                            +
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>}
                {labelType==='音乐'&&<Form.Item label="作品文件"
                    extra="支持视频、音频、三维模型、文件包支持文件类型：MP4、MP3、WAV、APK。限制大小：100 MB以内"
                >
                    <Form.Item name="music" valuePropName="fileList" getValueFromEvent={normFile} noStyle >
                        <Upload.Dragger name="music" beforeUpload={() => false} maxCount={1}>
                            +
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>}
                {labelType==='音乐'&&<Form.Item label="作品试听"
                    extra="支持视频、音频、三维模型、文件包
                    支持文件类型：MP4、MP3、WAV、APK。限制大小：100 MB以内"
                >
                    <Form.Item name="audition" valuePropName="fileList" getValueFromEvent={normFile} noStyle >
                        <Upload.Dragger name="audition" beforeUpload={() => false} maxCount={1}>
                            +
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>}
                {labelType==='音乐'&&<Form.Item label="歌词文件"
                    extra="支持视频、音频、三维模型、文件包
                    支持文件类型：PDF、WORD、XLS。限制大小：20 MB以内"
                >
                    <Form.Item name="lyric" valuePropName="fileList" getValueFromEvent={normFile} noStyle >
                        <Upload.Dragger name="lyric" beforeUpload={() => false} maxCount={1}>
                            +
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>}
                <Form.Item
                    label="作品简介"
                    name="describe"
                    rules={[
                        {
                            required: true,
                            message: '请输入作品简介!',
                        },
                    ]}
                > 
                    <TextArea rows={4} onKeyUp={checkLengthuser_150}  placeholder="添加个人介绍,150个字符以内"  />
                </Form.Item>
                <Form.Item label="铸造数量" name="count"  rules={[
                        {
                            required: true,
                            message: '请输入铸造数量!',
                        },
                    ]}>
                    <InputNumber defaultValue={1} />
                </Form.Item>
                <Form.Item
                    label="铸造数量"
                    wrapperCol={{
                        offset: 0,
                        span: 14,
                    }}
                >
                    <div className='zhuzao-box'>
                        <div className='zhuzao-child-one'>
                            <div className='zhuzao-label'>
                                铸造费用
                            </div>
                            <div className='zhuzao-content'>
                                10%
                            </div>
                        </div>
                        <div className='zhuzao-child-two'>
                            <div className='zhuzao-label'>
                                平台服务费
                            </div>
                            <div className='zhuzao-content'>
                                免费
                            </div>
                        </div>
                        <div className='zhuzao-child-three'>
                            注:作品铸造成功后，您可在创作主页设置作品价格并操作上架销售，平台即可浏览并购买已上架出售的作品。
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 9,
                        span: 14,
                    }}
                >
                    <Button className='submit' type="primary" htmlType="submit" >
                        铸造
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
