import React, { useState, useEffect } from 'react'
import { bill ,nft_deal} from '@/api/user'
import { Avatar,Tooltip,List } from 'antd';
import VirtualList from 'rc-virtual-list';
import { QuestionCircleFilled } from '@ant-design/icons';
import './index.scss'
import { asset } from '@/api/public'
import { useMappedState, useDispatch } from 'redux-react-hook';
import { Empty } from 'antd';
import {noStatusBack} from '@/utils/icon'

const ContainerHeight = 600;

export default function CreationIncome() {
      const text = <span>指的是累计成功销售创作扣除平台服务费后的积分收入</span>;
      const [nftDeal, setNftDeal] = useState([]);
      const [pageNo, setPageNo] = useState(1);
      const [allPage, setAllPage] = useState(1); 
      const [assetUserMation, setAssetUserMation] = useState("");
      const { loginInformation, statusCreate, } = useMappedState((state) => {
            return state;
     })
     const setImageFun = (value)=>{
      const image = JSON.parse(value)
      return image.FrontCoverUrl
    }
      const getAssetfun = async () => {
            const params = {
                    user_id: loginInformation?.id,
            }
            await asset(params).then(res => {
                    setAssetUserMation(res.data);
            })
    }
    const getNftDealList = async()=>{
      const params = {
            page_no: pageNo,
            page_size: 10,
            type:"All",
      }
      await nft_deal(params).then((res)=>{
            if(res.code===200){
                  setNftDeal(nftDeal.concat(res.data.deal))
                   if(res.data.count%10){
                        const allPage  = Math.floor(res.data.count/10)+1;
                        setAllPage(allPage);
                   }else{
                        const allPage  = res.data.count/10;
                        setAllPage(allPage);
                   }
            }
    })
    }
    const noticeContent = (v) => {
      return (
            <div className='list-card-box' key={v?.tx_hash}>      
            <div className='element one'>
                  <span>
                        {v?.name}
                  </span>
            </div>
            <div className='element two'>{v?.content_type}</div>
            <div className='element tree'>{v?.price}</div>
            <div className='element four'>{v?.deal_count}</div>
            <div className='element tree'>{v?.price}</div>
            <div className='element tree'>0.00</div>
            <div className='element tree'>{v?.amount}</div>
            <div className='element two'>{v?.buyer}</div>
            <div className='element tree'>{v?.time}</div>
      </div>
      )
    }
    const onScroll = (e) => {
      if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
        setPageNo(pageNo + 1);
        // 如果没到视图底部就加载列表
        if (allPage>=pageNo) {
            getNftDealList();
        }
      }
    };
      useEffect(() => {          
            getNftDealList();
            getAssetfun();
      }, [])
      console.log(nftDeal,"nftDeal")
      return (
            <div className='CreationIncome'>
                  <div className='my-creation-Income'>
                        <div className='title'>
                              <div className="my-title">我的创作收益</div>
                              <Tooltip placement="topLeft" title={text}>
                                    <QuestionCircleFilled className='icon' />
                              </Tooltip>
                        </div>
                        <div className='creation-card'>
                              <div className='creation-two-box'>
                                    <div className='two-title'>积分</div>
                                    <div className='two-describe'>{assetUserMation?.earning}</div>
                                    <div className='two-money'>(1积分=1元)</div>
                              </div>
                        </div>
                  </div>
                  <div className='shouyimingxi'>收益明细</div>
                  <div className='list-card'>
                        <div className='list-card-title'>
                              <li className='one'>作品名称</li>
                              <li className='tree'>类型</li>
                              <li className='tree'>单价</li>
                              <li className='tree'>数量</li>
                              <li className='tree'>交易价格</li>
                              <li className='tree'>平台费用</li>
                              <li className='tree'>创作收益</li>
                              <li className='four'>购买者</li>
                              <li className='tree'>交易时间</li>
                        </div>
                        <List>
                              <VirtualList
                              data={nftDeal}
                              height={ContainerHeight}
                              itemHeight={47}
                              itemKey="tx_hash"
                              onScroll={onScroll}
                              >
                              {(item) => (
                                    <List.Item key={item?.tx_hash}>
                                          <List.Item.Meta
                                             avatar={<Avatar src={setImageFun(item?.meta_data)} shape="square" style={{ width: 60, height: 60 }} />}
                                             description={noticeContent(item)}
                                          />
                                    </List.Item>
                              )}
                              </VirtualList>
                        </List>
                       {nftDeal?.length===0&&<Empty className='noStatusBack'  description='什么都没有～' image={noStatusBack} />}
                  </div>
            </div>
      )
}
