import React, { useState, useEffect } from 'react'
import './index.scss'
import { Select, Avatar,message } from 'antd';
import { HeartOutlined ,HeartFilled} from '@ant-design/icons';
import { collectionDetail } from '@/api/nft'
import { useMappedState,useDispatch } from 'redux-react-hook';
import {starCollection,isStar,messageUnreadCount} from '@/api/user';
import {collectionNft} from '@/api/public'
import { Empty } from 'antd';
import {noStatusBack} from '@/utils/icon'
const { Option } = Select;

export default function WorksSeries(props) {
    const { collection_id } = props.location.state;
    const [count,setCount] = useState(0);
    const dispatch = useDispatch();
    const [collectionItem, setCollectionItem] = useState(null);
    const [star,setStar] = useState();
    const [addone,setAddone] = useState(0);
    const content_type_list = [{ key: "All", name: "全部" }, { key: "Avatar", name: "头像" }, { key: "Music", name: "音乐" }, { key: "RightCard", name: "权益卡" }, { key: "DriverSkin", name: "车机皮肤" }];
    const [creatorNftPublicList,setCreatorNftPublicList] = useState([]);
    const {loginInformation} = useMappedState((state)=>{
        return state;
    })
   
    const handleChange = async(value) => {
        const params = {
                // page_no: 1,
                // page_size: 20,
                ascending: true,
                order_by: "Time",
                user_id:collectionItem?.user_id,
                content_type:value,
                id:collection_id,
        }
        await collectionNft(params).then(res => {
            setCreatorNftPublicList(res.data.nft)
        })
   }; 
   const setImageFun = (value)=>{
     if(value){
        const image = JSON.parse(value)
        return image.FrontCoverUrl
     }
   
}
    const getcollectionDetail = async () => {
        const params = {
            id: collection_id
        }
        await collectionDetail(params).then(res => {
            setCollectionItem(res.data)
            setAddone(res.data.start_num)
            collectionNftFun(res?.data?.id);
        })
    }
    const isStarFun = async()=>{
        const params = {
           id:collection_id,
           type:"Collection"
        }
        await isStar(params).then((res)=>{
              setStar(res.data.is_star)
        })
    }
    const starCollectionFun = async()=>{  
        const params = {
           collection_id:collectionItem?.id,
           has_star:!star,
        }
        await starCollection(params).then((res)=>{
               if(res.code===200){
                    message.success(`${!star?"已取关":"已关注"}`)
                    messageUnreadCountFun();
               }
        })
    }
    const messageUnreadCountFun = async()=>{
        if(loginInformation){
            await messageUnreadCount().then(res => {
              if(res.code==200){
                    dispatch({type:'MESSAGE_COUNT',messageCount:res.data.count})
              }
            })
          }
      }
    const collectionNftFun = async(id)=>{
        
        const params = {
                // page_no: 1,
                // page_size: 20,
                ascending: true,
                order_by: "Time",
                content_type:'All',
                id:id
        }
        await collectionNft(params).then(res => {
            console.log(res,'res')
            if(res.code===200){
                     setCreatorNftPublicList(res.data.nft)
                     setCount(res.data.count)
            }else if(res.code===6001){
                 message.error('获取列表失败')
            }
           
        })
    }

    useEffect(() => {
        getcollectionDetail();
        isStarFun();
    }, [])
    console.log(collectionItem,"collectionItem")
    console.log(collection_id,"collection_id")
    return (
        <div className='worksSeries'>
            <div className='worksSeries-banner'>
                <Avatar shape="square" className='backgroud-Avatar' src={collectionItem?.face_pic_url} />
                <Avatar className='worksSeries-Avatar' src={collectionItem?.logo_pic_url} />
            </div>
            <div className='worksSeries-title-box'>
                <div className='worksSeries-title-content'>
                        <div className='dimentsion-box'>
                                 <div className='dimentsion-left'>
                                        <div className='dimentsion'>
                                            {collectionItem?.name}
                                        </div>
                                        <div className='zuozhe'>
                                            作者:{collectionItem?.username}
                                        </div>
                                 </div>
                                <a className='dimentsion-right' onClick={()=>{
                                      if(star){
                                          setAddone(collectionItem?.start_num+1)
                                      }else{
                                          setAddone(addone-1)
                                      } 
                                      starCollectionFun()
                                      setStar(!star)
                                }}>
                                      {
                                         !star?<HeartFilled />:<HeartOutlined />
                                       }  
                                    &nbsp;
                                    {addone}
                                </a>
                        </div>
                        <div className='data-total'>
                            <div className='data-total-left'>
                                <div className='data-total-title'>
                                   {count}
                                </div>
                                <div className='data-total-content'>作品数量</div>
                            </div>
                            <div className='data-total-center'>
                                <div className='data-total-title'>
                                      {collectionItem?.create_time}
                                   </div>
                                <div className='data-total-content'>发布时间</div>
                            </div>
                            <div className='data-total-right'>
                                <div className='data-total-title'>
                                     {collectionItem?.start_num}
                                </div>
                                <div className='data-total-content'>关注量</div>
                            </div>
                        </div>
                        <div className='dimentsion-describe'>
                            {collectionItem?.brief||"没有描述"}
                        </div>
                       
                </div>

            </div>
            <div className='worksSeries-card'>
                <div className='selects'>
                              <Select
                                        defaultValue="全部"
                                        style={{
                                                width: 250,
                                        }}
                                        onChange={handleChange}
                                >
                                        {
                                                content_type_list.map((v, i) => {
                                                        return <Option value={v.key} key={i}>{v.name}</Option>
                                                })
                                        }
                                </Select>
                </div>
                <div className='content'>
                    {
                         creatorNftPublicList?.map((v,i)=>{
                            return <a className='card-my'
                            key={i}
                            onClick={() => {
                                props.history.push('/home/nftdetail'+v.asset_id,{v})
                            }}
                        >
                            <img alt="example" className='card-my-avatar'
                                src={setImageFun(v?.meta_data)} />
                            <div className='card-content-box'>
                                <div className='title'>
                                   {v?.name}
                                </div>
                                <div className='miaoshu'>
                                {v?.collection_name}
                                </div>
                                <div className='card-content'>
                                    <div className='price'>
                                        <div className='price-symbol'>￥</div>   {v.price}
                                    </div>
                                    <div className='house'>
                                        <HeartOutlined />
                                        &nbsp;
                                        {v?.star_num}
                                    </div>
                                </div>
        
                            </div>
                            {v?.status==='出售中'&&<div className='button'>立即购买</div>}
                            <div className='label'>{v?.status}</div>
                            <div className='avatar-mask'>
                            </div>
                        </a>
                         })
                    }
                    
                    {creatorNftPublicList.length===0&&<Empty className='noStatusBack'  description='什么都没有～' image={noStatusBack} />}
                </div>
            </div>
        </div>
    )
}
