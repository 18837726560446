import React, { useState } from 'react'
import './index.scss'
import { message,Avatar, Radio,Checkbox } from 'antd';
import { Button, Form, Input, Upload, Select, } from 'antd';
import { useHistory } from "react-router-dom";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { collectionCreate } from '../../../../api/nft'
const { TextArea } = Input;

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'|| file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('你只能够上传 JPG/PNG/GIF 文件!');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('图片必须小于 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

export default function CreateSeries(props) {
    const [flagRz, setFlagRz] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageUrlAvatar, setImageUrlAvatar] = useState();
    const [imageUrlFace, setImageUrlFace] = useState();
    const [imageUrlHome, setImageUrlHome] = useState();
    const [form] = Form.useForm();
    const history = useHistory();
    const { Option } = Select;
    const uploadButton = (
        <div className='createSeries-logo-box'>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
        </div>
    );
    const uploadButtonFace = (
        <div className='createSeries-face-box'>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
        </div>
    );
    const uploadButtonHome = (
        <div className='createSeries-home-box'>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
        </div>
    );

    
    const onFinish = async (values) => {
        console.log('Success:', values);
        const { logo, face, home, category, name, brief,} = values;
        const formData = new FormData();


        formData.append("category", category)
        formData.append("name", name);

        if (brief) {
            formData.append("brief", brief);
        }
        // if (describe) {
        //     formData.append("describe", describe);
        // }

        logo?.forEach((item, index) => {
            formData.append("logo", item.originFileObj);
        })

        face?.forEach((item, index) => {
            formData.append("face", item.originFileObj);
        })

        home?.forEach((item, index) => {
            formData.append("home", item.originFileObj);
        });
        console.log(formData, "formData")
        await collectionCreate(formData).then((res) => {
            if (res.code === 200) {
                message.success('已创建系列')
                props.initStatus()
                history.push('/home/creatnft/homepage')
            } else {
                message.error('请联系管理员')
            }
        })

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const checkLengthuser_30 = (e) => {
        var l = 0;
        for (var i = 0; i < e.target.value.length; i++) {
            if (/[\u4e00-\u9fa5]/.test(e.target.value[i])) {
                l += 2;
            } else {
                l++;
            }
            if (l > 30) {
                e.target.value = e.target.value.substr(0, i);
                break;
            }
        }
    };
    const checkLengthuser_300 = (e) => {
        var l = 0;
        for (var i = 0; i < e.target.value.length; i++) {
            if (/[\u4e00-\u9fa5]/.test(e.target.value[i])) {
                l += 2;
            } else {
                l++;
            }
            if (l > 300) {
                e.target.value = e.target.value.substr(0, i);
                break;
            }
        }
    };
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e.file)) {
            return e.file;
        }
        return e?.fileList;
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleChangeAvatar = (info) => {
            getBase64(info.file.originFileObj, (url) => {
                setImageUrlAvatar(url);
            });
    };

    const handleChangeFace = (info) => {
        getBase64(info.file.originFileObj, (url) => {
            setImageUrlFace(url);
        });
    };

    const handleChangeHome = (info) => {
        getBase64(info.file.originFileObj, (url) => {
            setImageUrlHome(url);
        });
    };

    return (
        <div className='CreateSeries'>
            <div className='CreateSeries-title'>
                创建新系列
            </div>
            <Form
                name="basic"
                className='CreateSeries-form'
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                // valuePropName="fileList"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="系列名称"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入系列名称!',
                        },
                    ]}
                >
                    <Input placeholder="30个字符以内"  onKeyUp={checkLengthuser_30} />
                </Form.Item>
                <Form.Item
                    name="category"
                    label="标签分类"
                    rules={[
                        {
                            required: true,
                            message: '请选择分类',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value="头像">头像</Radio>
                        <Radio value="音乐">音乐</Radio>
                        <Radio value="权益卡">权益卡</Radio>
                        <Radio value="车机皮肤">车机皮肤</Radio>
                    </Radio.Group>
                </Form.Item>
                {/* <Form.Item
                    label="描述"
                    name="describe"
                >
                    <TextArea rows={4} placeholder="添加个人介绍,300个字符以内" onKeyUp={checkLengthuser_300} onChange={(e) => {
                        if (e.target.value) {
                            setFlagRz(true)
                        } else {
                            setFlagRz(false)
                        }

                        console.log(e.target.value)
                    }} />
                </Form.Item> */}
                <Form.Item
                    label="系列简介"
                    name="brief"
                    rules={[
                        {
                            required: true,
                            message: '请填写作品简介!',
                        },
                    ]}
                >
                    <TextArea rows={4} placeholder="添加个人介绍,300个字符以内" showCount onKeyUp={checkLengthuser_300}  onChange={(e) => {
                        if (e.target.value) {
                            setFlagRz(true)
                        } else {
                            setFlagRz(false)
                        }

                        console.log(e.target.value)
                    }} />
                </Form.Item>
                <Form.Item label="头像"
                    extra="此图片用于作品系列导航等显示。支持上传JPG，JEPG，PNG，图片大小不大于5MB，推荐尺寸400*400"
                >
                    <Form.Item  name="logo" valuePropName="fileList" getValueFromEvent={normFile} noStyle label="头像"
                    rules={[
                        {
                            required: true,
                            message: '请上传头像',
                        },
                    ]}
                    >
                        <Upload name="files" showUploadList={false}  beforeUpload={beforeUpload} onChange={handleChangeAvatar} maxCount={1}>
                            {imageUrlAvatar ? (
                                <img
                                    src={imageUrlAvatar}
                                    className='createSeries-logo-box'
                                    alt="avatar"
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="封面图片"
                    extra="此图片用于平台首页，市场，分类页面或其他展示。图片大小不大于10MB，尺寸不小于400*300"
                >
                    <Form.Item name="face" valuePropName="fileList" rules={[
                        {
                            required: true,
                            message: '请上传图片',
                        },
                    ]} getValueFromEvent={normFile} noStyle >
                        <Upload name="face" showUploadList={false} onChange={handleChangeFace} maxCount={1}>
                           {imageUrlFace ? (
                                <img
                                    src={imageUrlFace}
                                    className='createSeries-face-box'
                                    alt="avatar"
                                />
                            ) : (
                                uploadButtonFace
                            )}
                        </Upload>
                    </Form.Item>
                </Form.Item>

                <Form.Item label="主页图片"
                    extra="此图片展示在系列主页顶部。图片大小不大于10MB，建议尺寸1400*400"
                >
                    <Form.Item name="home"  rules={[
                        {
                            required: true,
                            message: '请上传图片',
                        },
                    ]} valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                        <Upload name="home" showUploadList={false} onChange={handleChangeHome} maxCount={1}>
                            {imageUrlHome ? (
                                    <Avatar
                                        src={imageUrlHome}
                                        className='createSeries-home-box'
                                        alt="avatar"
                                    />
                                ) : (
                                    uploadButtonHome
                                )}
                            </Upload>
                    </Form.Item>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 9,
                        span: 14,
                    }}
                >
                    <Button className='submit' type="primary" htmlType="submit" >
                        创建
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
