import React, { useState, useEffect } from "react"
import "./index.scss"
import { Select, Card, message, List } from "antd"
import { Avatar } from "antd"
import { EyeOutlined, HeartOutlined } from "@ant-design/icons"
import CreateSeries from "../CreateSeries/index.js"
import EditSeries from "../EditSeries/index.js"
import VirtualList from 'rc-virtual-list';

import { ArrowLeftOutlined } from "@ant-design/icons"
import { useMappedState, useDispatch } from "redux-react-hook"
import { Collection } from "../../../../api/user"
import { collectionDelete } from '@/api/nft';
const ContainerHeight = 800;

export default function WorksSeries(props) {
  const [flag, setFlag] = useState(1);
  const [editStatus, setEditStatus] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [allPage, setAllPage] = useState(1);
  const [serviesPageStatus, setServiesPageStatus] = useState(1);//控制系列管理页面展示的状态

  const dispatch = useDispatch()
  const { activeType, loginInformation, serviesPageStatusStore } = useMappedState(state => {
    return state
  })
  const [createCollectionList, setcreateCollectionList] = useState([])

  const getCreateCollectionList = async () => {
   
    if (activeType === 3) {
      setServiesPageStatus(2)
    }
    let params = {
      page_no: pageNo,
      page_size: 10,
      user_id: loginInformation.id,
    }
    await Collection(params).then(res => {
      setcreateCollectionList(createCollectionList.concat(res.data.data))
      if (res.data.count % 10) {
        const allPage = Math.floor(res.data.count / 10) + 1;
        setAllPage(allPage);
      } else {
        const allPage = res.data.count / 10;
        setAllPage(allPage);
      }
    })
  }

  const deleteCollectionFun = async (id) => {
    const params = {
      collection_id: id
    }
    await collectionDelete(params).then(res => {
      if (res.code === 200) {
        message.success("删除成功");
      }
    })
  }
  const editCollection = async (vlaue) => {
    setServiesPageStatus(3)
    console.log(vlaue, "vlaue")
    dispatch({
      type: "COLLECTION_ITEM",
      collectionItem: vlaue,
    })
  }
  const ctrlSeviewsStatusFun = () => {
    if (serviesPageStatusStore) {
      setServiesPageStatus(serviesPageStatusStore)
    }
  }
  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      setPageNo(pageNo + 1);
      // 如果没到视图底部就加载列表
      if (allPage >= pageNo) {
        getCreateCollectionList()
      }
    }
  };
  const initStatus = ()=>{
    setServiesPageStatus(1)
    dispatch({ type: "ACTIVE_TYPE", activeType: 0 })
     window.location.reload();
  }
  const noticeContent = (v) => {
    return (
      <div className="dl">
        <a onClick={()=>{
                 props.history.push('/home/worksSeries' + v?.id, { collection_id: v?.id })
         }}>
                 <Avatar
          className="works-Avatar"
          shape="square"
          src={v?.face_pic_url}
        />
         </a>
        
        <div className="works-content-box">
          <div className="title">{v?.name}</div>
          <div className="eye-love">
            <div className="eye">
              <EyeOutlined />
              &nbsp;0
            </div>
            <div className="love">
               <HeartOutlined />
              &nbsp;{v?.star_num}
            </div>
            <span className="date">{v?.time}</span>
          </div>
          <div className="collection-all-count">
                共{v?.count}个作品
          </div>
          <div className="works-content-status">
            <a onClick={() => {
              editCollection(v)
            }}>编辑系列</a>
            <a onClick={() => {
              deleteCollectionFun(v.id)
            }}>删除系列</a>
            <a onClick={()=>{
                 props.history.push('/home/worksSeries' + v?.id, { collection_id: v?.id })
            }}>查看系列</a>
          </div>
        </div>
      </div>
    )
  }
  useEffect(() => {
    getCreateCollectionList()
  }, [activeType])
  useEffect(() => {
    ctrlSeviewsStatusFun()
  }, [serviesPageStatusStore])

  console.log(activeType, 'activeType')
  return (
    <div className="WorksSeries">
      {(serviesPageStatus === 2 || serviesPageStatus === 3) && (
        <div
          className="CreateSeries-back"
          onClick={() => {
            setServiesPageStatus(1)
            dispatch({ type: "ACTIVE_TYPE", activeType: 0 })
          }}
        >
          <ArrowLeftOutlined /> &nbsp; 返回
        </div>
      )}
      <div className="worksAdmin-box">
        {/* {serviesPageStatus === 1 &&
          createCollectionList.map((v, i) => {
            return (
              <div className="dl" key={i}>
                <Avatar
                  className="works-Avatar"
                  shape="square"
                  src={v?.face_pic_url}
                />
                <div className="works-content-box">
                  <div className="title">{v?.name}</div>
                  <div className="eye-love">
                    <div className="eye">
                      <EyeOutlined />
                      &nbsp;0
                    </div>
                    <div className="love">
                      <HeartFilled />
                      &nbsp;{v?.star_num}
                    </div>
                    <span className="date">{v?.time}</span>
                  </div>
                  <div className="works-content-status">
                    <span onClick={() => {
                      editCollection(v)
                    }}>编辑系列</span>
                    <span onClick={() => {
                      deleteCollectionFun(v.id)
                    }}>删除系列</span>
                    <span></span>
                  </div>
                </div>
              </div>
            )
          })} */}

        {serviesPageStatus === 1 && <List>
          <VirtualList
            data={createCollectionList}
            height={ContainerHeight}
            itemHeight={47}
            itemKey="id"
            onScroll={onScroll}
          >
            {(item) => (
              <List.Item key={item?.id}>
                <List.Item.Meta
                  // avatar={<Avatar src={setImageFun(item?.meta_data)} shape="square" style={{ width: 60, height: 60 }} />}
                  description={noticeContent(item)}
                />
              </List.Item>
            )}
          </VirtualList>
        </List>}
        {createCollectionList.length === 0 && serviesPageStatus === 1 && (
          <div
            className="create-button-box"
            id={activeType === 3 ? "none" : ""}
            onClick={() => {
              setServiesPageStatus(2)
            }}
          >
            +你还没有系列，点击创建新系列
          </div>
        )}
      </div>
      {serviesPageStatus === 2 && <CreateSeries initStatus={initStatus} />}
      {serviesPageStatus === 3 && <EditSeries initStatus={initStatus} />}
    </div>
  )
}
