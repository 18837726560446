import React,{useState,useEffect} from 'react'
import './index.scss'
import { Avatar, } from 'antd';
import { rz } from '../../../../utils/icon'
import { useHistory } from "react-router-dom";
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { useDispatch, useMappedState } from 'redux-react-hook'
import { getCollectionList, } from '@/api/nft';
import { starCollection } from  "@/api/user"


export default function SelectedSeries() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [nftDetailObj,setNftDetail] = useState(null);
    const {CollectionList } = useMappedState((state) => {
        return state;
    })
    const  getnftCollection = async () => {
        const params = {
             page_no:1,
             page_size:20
        }
        await getCollectionList(params).then(res => {
          dispatch({ type: 'GET_COLLECTION', CollectionList: res?.data.data || [] })
        })
      }

 
      useEffect(()=>{
          getnftCollection();
      },[])
      console.log(CollectionList,"CollectionList")

      const actionFllow = async (item, e) => {
        e.stopPropagation();
        const params = {
            collection_id: item.id,
            has_star: !item.is_star,
        }
        const { data, code } = await starCollection(params)
        if (code === 200) {
            message.success(`${!item.is_star ? "关注成功" : "取消关注"}`)
              getnftCollection();
        }
    }

    return (
        <div className='selected-series'>
            <div className='selected-series-title'>
                精选系列
            </div>
            <div className='selected-series-list'>
                {
                      CollectionList&&CollectionList.slice(0,3)?.map((v,i)=>{
                             return   <a className='selected-series-card' key={i}  onClick={() => {
                             
                                history.push('/home/worksSeries'+ v?.id,{collection_id:v?.id})
                        }}>
                            {/* <Avatar shape="square" className='selected-series-background' src={v?.face_pic_url} /> */}
                            <div className='selected-series-background'>
                               <img src={v?.face_pic_url}/>
                            </div>
                            <Avatar className='selected-series-avatar' src={v?.logo_pic_url} />
                            <div className='selected-series-title'>
                                {v.name} <img alt='认证' src={rz} />
                            </div>
                            <div className='star-number' onClick={(e) => actionFllow(v, e)}>
                                 {  v.is_star ? <HeartFilled /> : <HeartOutlined />}
                                        <span className='attention'>{v.start_num}</span>
                            </div>
                    </a>
                      })
                }
               
          
            </div>
        </div>
    )
}
