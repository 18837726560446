import React from 'react';
import {BrowserRouter} from 'react-router-dom'
import RouterView from './router/RouterView'
import routerList from './router/routerConfig'
import './App.css'


export default function App() {
  
  return (
    <React.Fragment> 
          <BrowserRouter>
              <RouterView routerList = {routerList}></RouterView>
          </BrowserRouter>
    </React.Fragment>
  )
}
