import React, { useEffect, useState } from 'react'
import { Button,Avatar } from 'antd';
import {zfb} from '../../../../utils/icon'
import {pointBuy} from '../../../../api/user'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { profile } from '@/api/user'

export default function RechargePoints() {
    const [recharge, setRecharge] = useState(1);
    const [open, setOpen] = useState(false);
    const [custom,setCustom] = useState("");
    const [userInformation, setUserInformation] = useState();
    const { loginInformation } = useMappedState((state) => {
        return state;
    })
    const [money,setMoney] = useState("50");
    const showModal = () => {
        setOpen(true);
      };

    const pointBuyFunction = async()=>{
           const params = getMoneyFun();
           await pointBuy(params).then((res)=>{
                 console.log(res,'res')
                 localStorage.setItem("out_trade_no",res.data.out_trade_no)
                 window.open(res.data.url,"_self")
           })
    }
    const getMoneyFun = ()=>{ 
        const params = {
            amount:"",
            return_url:'home/wallet/intergral',
        }  
        
        if(recharge===1){
            params.amount = "50";
        }else if(recharge===2){
            params.amount = "100";
        }else if(recharge===3){
            params.amount = "200";
        }else if(recharge===4){
            params.amount = money;
        }

        setMoney(params.amount)
        return params;
    } 
    
    const getSingleInfor = () => {
        const params = {
               user_id: loginInformation.id
        }
        profile(params).then(res => {
               setUserInformation(res.data)
        })
 }


    useEffect(()=>{
        getMoneyFun();
        getSingleInfor();
    },[recharge])
    console.log(money,'money')
    return (
        <div className='recharge-points'>
            <div className='points-one'>
                <div className='points-label'>钱包ID:</div>
                <span> {userInformation?.address.slice(0, 6)}……{userInformation?.address?.substring(userInformation?.address.length - 6)}</span>
            </div>
            <div className='points-two'>
                <div className='points-label'>充值金额:</div>
                <div className='two-box'>
                    <li className={recharge === 1 ? "active" : ""} onClick={() => {
                        setRecharge(1)
                    }}>
                        <div className='two-title'>50积分</div>
                        <div className='two-describe'>50积分</div>
                    </li>
                    <li className={recharge === 2 ? "active" : ""} onClick={() => {
                        setRecharge(2)
                    }}>
                        <div className='two-title'>100积分</div>
                        <div className='two-describe'>100积分</div>
                    </li>
                    <li className={recharge === 3 ? "active" : ""} onClick={() => {
                        setRecharge(3)
                    }}>
                        <div className='two-title'>200积分</div>
                        <div className='two-describe'>200积分</div>
                    </li>
                    <li className={recharge === 4 ? "active" : ""} onClick={() => {
                        setRecharge(4)
                    }}>
                        {recharge!=4&&<div className='two-title'>自定义金额</div>} 
                        {recharge!=4&&<div className='two-describe'>最低1元,最高10000元</div>}
                        {recharge===4&&<div className='Recharge'>
                              充值
                        </div>}
                        {recharge===4&&<div className='money-label-box'>
                                <div className='money_label'>
                                   ￥
                                </div>
                                {recharge===4&& <input className='custom'  type="number" onChange={(e)=>{
                                    setMoney(e.target.value)
                                }} />}
                         </div>}                 
                    </li>
                </div>

            </div>
            <div className='points-three'>
                <div className='points-label'>支付方式:</div>
                <Avatar shape="square" className='pay' src={zfb} />
            </div>
            <div className='points-four'>
                <div className='points-label'>应付金额:</div>
                <span>{money}</span><div className='yuan'>元</div>
            </div>
            <Button type="primary" style={{
                width: "434px",
                height: "60px",
                borderRadius: "8px",
                fontSize: "22px",
                fontFamily: "OPPOSans-B, OPPOSans",
                fontWeight: "normal",
                color: "#F9F9F9",
            }} onClick={() => {
                showModal()
                pointBuyFunction()
            }}>立即充值</Button>
            <p>
                <span className='one'>已阅读并同意</span>
                <span className='two'>《用户支付协议》</span>
            </p>
        </div>
    )
}
