import React, { useState, useEffect } from "react";
import "./index.scss";
import { Button, Popover } from "antd";
import RouterView from "@/router/RouterView";
import { NavLink } from "react-router-dom";
import { profile } from "@/api/user";
import { useMappedState, useDispatch } from "redux-react-hook";

export default function Wallet(props) {
	const [acitves, setAcitves] = useState(1);
	const dispatch = useDispatch();
	const { chil } = props;
	const [statusCreate, setStatusCreate] = useState(""); //1创建系列 2 创建作品
	const [open, setOpen] = useState(false);
	const { activeType, loginInformation } = useMappedState(state => {
		return state;
	});
	const hide = value => {
		setOpen(false);
		if (value === 2) {
			dispatch({ type: "ACTIVE_TYPE", activeType: 2 });
			props.history.push("/home/creatnft/works_admin");
		} else if (value === 3) {
			dispatch({ type: "ACTIVE_TYPE", activeType: 3 });
			props.history.push("/home/creatnft/works_series");
		}
	};

	const handleOpenChange = newOpen => {
		setOpen(newOpen);
	};

	console.log(statusCreate, "statusCreate");

	const activeTypeFun = () => {
		if (activeType) {
			setAcitves(activeType);
		}
	};

	useEffect(() => {
		activeTypeFun();
	}, [activeType]);
	console.log(activeType, "activeType");
	return (
		<div className="createNft">
			<div className="space"></div>
			<div className="father-content">
				<div className="left-card">
					<div className="create-button-box">
						<Popover
							content={
								<div className="prover-content-box">
									<div
										className="prover-selecet"
										onClick={() => {
											hide(3);
											props.history.push("/home/creatnft/works_series");
										}}
									>
										创建新系列
									</div>
									<div
										className="prover-selecet"
										onClick={() => {
											hide(2);
											props.history.push("/home/creatnft/works_admin");
										}}
									>
										创建新作品
									</div>
								</div>
							}
							trigger="click"
							overlayClassName="createNft-popover"
							open={open}
							placement="bottom"
							onOpenChange={handleOpenChange}
						>
							<Button className="create-button" type="primary" htmlType="submit">
								创建
							</Button>
						</Popover>
					</div>
					<div className="setUp-button">创作者中心</div>
					<div className="left-list-title">
						<a
							className={acitves === 1 ? "active" : ""}
							tabIndex="1"
							onClick={() => {
								setAcitves(1);
								props.history.push("/home/creatnft/homepage");
							}}
						>
							<div className="creation-icon"></div>
							&nbsp; 个人首页
						</a>
						<a
							className={acitves === 3 ? "active" : ""}
							tabIndex="2"
							onClick={() => {
								setAcitves(3);
								props.history.push("/home/creatnft/works_series");
							}}
						>
							<div className="creation-icon"></div>
							&nbsp; 系列管理
						</a>
						<a
							className={acitves === 2 ? "active" : ""}
							tabIndex="3"
							onClick={() => {
								setAcitves(2);
								props.history.push("/home/creatnft/works_admin");
							}}
						>
							<div className="creation-icon"></div>
							&nbsp; 作品管理
						</a>
						<a
							className={acitves === 4 ? "active" : ""}
							tabIndex="4"
							onClick={() => {
								setAcitves(4);
								props.history.push("/home/creatnft/creationIncome");
							}}
						>
							<div className="creation-icon"></div>
							&nbsp; 创作收益
						</a>
						<a
							className={acitves === 5 ? "active" : ""}
							tabIndex="5"
							onClick={() => {
								setAcitves(5);
								props.history.push("/home/creatnft/fanspage");
							}}
						>
							<div className="creation-icon"></div>
							&nbsp; 我的粉丝
						</a>
					</div>
				</div>
				<div className="right-card">
					<div className="setup-form">
						<div className="title-form">
							{acitves === 1
								? "个人首页"
								: acitves === 2
								? "作品管理"
								: acitves === 3
								? "作品系列"
								: acitves === 4
								? "创作收益"
								: "我的粉丝"}
						</div>
						<RouterView routerList={chil}></RouterView>
					</div>
				</div>
			</div>
		</div>
	);
}
