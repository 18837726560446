import React, { Component } from 'react'

import {Switch,Route,Redirect} from 'react-router-dom'

export default class RouterView extends Component {
    render() {
        let {routerList} = this.props;
        let list = routerList.filter(item=>item.path);
        let redirect = routerList.filter(item=>item.to)[0]; 
        return (
            <Switch>
                  {
                        list.map((item,index)=>{
                              return <Route key={index} path = {item.path} render = {(props)=>{
                                     let Com = item.com;
                                     if(item.children){
                                          return  <Com {...props} chil = {item.children} navLink = {item.children.filter(item=>item.path)} />
                                     }
                                     return <Com {...props}/>
                              }} />
                        })
                  }
                  {
                       redirect?<Redirect to = {redirect.to} from = {redirect.from}></Redirect>:<></>
                  }
            </Switch>
        )
    }
}
