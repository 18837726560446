import React, { useEffect, useState } from "react"
import { useMappedState } from "redux-react-hook"
import { Col, Row } from "antd"
import StarSerios from './serios'
import StarCollection from './nft'
import Creator from "./creator"
import { Empty } from 'antd';
import './index.scss'
import { noStatusBack } from '@/utils/icon'
import {
    seeStarNft,
    seeStarCollection,
    following,
} from "@/api/user"

export default function Attention() {
    // 我的关注数据
    const [starNFTList, setStarNftList] = useState([]) //关注的作品
    const [starNftTotal, setStarNftTotal] = useState(0)
    const [nftPageNo, setNftPageNo] = useState(1)

    const [starSeriosList, setStarSeriosList] = useState([]) //关注的系列
    const [starSeriosTotal, setStarSeriosTotal] = useState(0)
    const [seriosPageNo, setSeriosPageNo] = useState(1)


    const [starCreatorList, setStarCreatorList] = useState([]) //关注的创作者
    const [starCreatorTotal, setStarCreatorTotal] = useState(0)
    const [creatorPageNo, setCreatorPageNo] = useState(1)
    const { loginInformation } = useMappedState(state => {
        return state;
    })


    const [starType, setStarType] = useState(1) //关注的类型1，作品，2，系列，3，作者
    //关注的作品
    const getStarNft = async () => {
        // 这个接口有毛病
        const res = await seeStarNft({
            user_id: loginInformation.id,
            page_no: nftPageNo,
            page_size: 10,
        })
        let { code, data } = res
        if (code == 200) {
            let nftList =
                Array.isArray(data.nft) && data.nft.length
                    ? data.nft.map((item, key) => {
                        return (
                            <StarCollection collection={item} key={key}></StarCollection>
                        )
                    })
                    : []
            setStarNftTotal(data.count)
            setStarNftList(nftList)
        }
    }
    // 关注的系列
    const getStarSerios = async () => {
        const res = await seeStarCollection({
            user_id: loginInformation.id,
            page_no: seriosPageNo,
            page_size: 10,
        })

        let { code, data } = res
        if (code == 200) {
            let collectionList =
                Array.isArray(data.collection) && data.collection.length
                    ? data.collection.map((item, key) => {
                        return <StarSerios serios={item} key={key}></StarSerios>
                    })
                    : []

            // let collectionList = [{}, {}].map((item, key) => {
            //   return <StarSerios serios={item} key={key}></StarSerios>
            // })
            setStarSeriosTotal(data.count)
            setStarSeriosList(collectionList)
        }
    }
    // 关注的创作者
    const getStarCreator = async () => {
        const res = await following({
            user_id: loginInformation.id,
            page_no: creatorPageNo,
            page_size: 10,
        })
        let { code, data } = res
        if (code == 200) {
            let creatorList =
                Array.isArray(data.follow) && data.follow.length
                    ? data.follow.map((item, key) => {
                        return <Creator creator={item} key={key}></Creator>
                    })
                    : []
            setStarCreatorTotal(data.count)
            setStarCreatorList(creatorList)
        }
    }

    useEffect(() => {
        getStarSerios() //关注的作品
        getStarNft();
        getStarCreator();
    }, [])


    return (
        <div className="attention-box">
            <Row className="folling-search">
                <Col span={3}>
                    <div
                        onClick={() => setStarType(1)}
                        className={
                            starType == 1
                                ? "folling-type f-r f-c-c active"
                                : "folling-type f-r f-c-c"
                        }
                    >
                        关注的作品&nbsp;{starNftTotal || 0}
                    </div>
                </Col>
                <Col span={3}>
                    <div
                        onClick={() => setStarType(2)}
                        className={
                            starType == 2
                                ? "folling-type f-r f-c-c active"
                                : "folling-type f-r f-c-c"
                        }
                    >
                        关注的系列&nbsp;{starSeriosTotal || 0}
                    </div>
                </Col>
                <Col span={3}>
                    <div
                        onClick={() => setStarType(3)}
                        className={
                            starType == 3
                                ? "folling-type f-r f-c-c active"
                                : "folling-type f-r f-c-c"
                        }
                    >
                        关注的创作者&nbsp;{starCreatorTotal || 0}
                    </div>
                </Col>
            </Row>

            <div className="follow-list-box" >
                {starType == 1
                    ? starNFTList
                    : starType == 2
                        ? starSeriosList
                        : starCreatorList}
            </div>
            {
                starType == 1 && starNFTList?.length === 0 && <Empty className='noStatusBack' description='什么都没有～' image={noStatusBack} />
            }
            {
                starType == 2 && starSeriosList?.length === 0 && <Empty className='noStatusBack' description='什么都没有～' image={noStatusBack} />
            }
            {
                starType == 3 && starCreatorList?.length === 0 && <Empty className='noStatusBack' description='什么都没有～' image={noStatusBack} />
            }
        </div>
    )
}
