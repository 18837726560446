import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message, } from 'antd';
import { payPasswordSet, payPasswordEdit } from '../../../../api/user'
import { ArrowLeftOutlined } from '@ant-design/icons';
import {useMappedState,useDispatch} from 'redux-react-hook'
import { profile,payForgetPassword } from '@/api/user'
import {send_code} from '@/api/login'
import {codeValidate} from '@/api/login.js'


export default function PayAdmin(props) {
    const [acitves, setAcitves] = useState(1);
    const [recharge, setRecharge] = useState(1);
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(false);
    const [flagT, setFlagT] = useState(false);
    const [flagTh, setFlagTh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(true);
    const [values, setValues] = useState(true);
    const [phone,setPhone] = useState("")
    const [open, setOpen] = useState("");//获取已设置支付密码的状态
    const [code,setCode] = useState("");//短信验证码
    const [codeValidateflag,setCodeValidateflag] = useState(false);
    const {loginInformation} = useMappedState((state)=>{
        return state;
    })
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onFinish = async (values) => {
        console.log("忘记")
      
          if(codeValidateflag){
            const params = {
                code,
                password: values.password,
            }
            setFlag(true)
            await payForgetPassword(params).then((res) => {
                if (res.code === 200) {
                    dispatch({type:"WALLET_ACTIVE_TYPE",walletActiveType:1})
                }
            })
        }else{
            if (flagT) {
                console.log("修改")
                const params = {
                    old_password: values.passwordTwo,
                    password: values.password,
                }
                setFlag(true)
                await payPasswordEdit(params).then((res) => {
                    if (res.code === 200) {
                        dispatch({type:"WALLET_ACTIVE_TYPE",walletActiveType:1})
                    } 
                })
            } else {
                console.log("设置")
                const params = {
                    password: values.password
                }
                setFlag(true)
                await payPasswordSet(params).then((res) => {
                    console.log(res, "res")   
                    if (res.code === 200) {
                        
                            setAcitves(1)
                            setOpen(true)
                    } else {
                       
                    }
                })
            }
        }

      
        
    };
    const getSingleInfor = () => {
        const params = {
            user_id:loginInformation.id
        }
        profile(params).then(res => {
              setOpen(res.data.has_pay_pwd)
              setPhone(res.data.phone)
        })
       }
    const getSendCode = async() =>{
        const params = {
              phone:phone,
              type:"修改"
        }
        await send_code(params).then(res=>{
            if(res.code===200){
                 message.success("验证码已发送");
            }
    })
    };

   const codeValidateFun = async()=>{
        const params = {
            phone:phone,
            code:code,
       }
       await codeValidate(params).then(res=>{
               if(res.code===200){
                    setFlagTh(false)
                    setFlagT(false)
                    setOpen(false)
               }
       })
   }

  useEffect(()=>{
        getSingleInfor();
  },[])

    console.log(flag,"flag")
    return (
        <div className='pay-admin'>
            {!open && <div className='admin-one'>
                {!flagTh && <div className='admin-input-box'>
                    <div className='admin-input-label'>{flagT ? "重置支付密码" : "请设置支付密码"}  </div>
                    {/* <Input.Password className='admin-password' onChange={(e)=>{
                                          console.log(e.target.value,"value")
                                          let value = e.target.value;
                                          if(value){
                                            setValue(false)
                                          }else{
                                            setValue(true)
                                          }
                                  }} placeholder="支付密码用于余额支付，由6位数字组成" /> */}
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        {flagT && <Form.Item
                            name="passwordTwo"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入原支付密码',
                                },
                            ]}
                        >
                            <Input.Password className='admin-password' onChange={(e) => {
                                console.log(e.target.value, "value")
                                let value = e.target.value;
                                if (value) {
                                    setValue(false)
                                } else {
                                    setValue(true)
                                }
                            }} placeholder="请输入原支付密码" />
                        </Form.Item>}
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入支付密码',
                                },
                            ]}
                        >
                            <Input.Password className='admin-password' onChange={(e) => {
                                console.log(e.target.value, "value")
                                let value = e.target.value;
                                if (value) {
                                    setValue(false)
                                } else {
                                    setValue(true)
                                }
                            }} placeholder="支付密码用于余额支付，由6位数字组成" />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: '请再次输入支付密码!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('您两次输入的密码不一致'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password className='admin-password' onChange={(e) => {
                                console.log(e.target.value, "value")
                                let value = e.target.value;
                                if (value) {
                                    setValue(false)
                                } else {
                                    setValue(true)
                                }
                            }} placeholder="请再次输入密码" />
                        </Form.Item>
                        {flagT && <Form.Item
                            wrapperCol={{
                                offset: 7,
                                span: 16,
                            }}
                        >
                            <Button type="link" onClick={() => {
                                setFlagTh(true)
                            }}>忘记支付密码?</Button>
                        </Form.Item>}
                        <Form.Item
                            wrapperCol={{
                                offset: 0,
                                span: 16,
                            }}
                        >
                            <Button type="primary" className='button-ok' disabled={value} htmlType="submit">确认</Button>
                        </Form.Item>
                    </Form>
                </div>}
            </div>}
            {open&& <div className='status'>
                <div className='status-one'>支付密码</div>
                <div className='status-two'>已设置</div>
                <div className='status-three' onClick={() => {
                    setFlagT(true)
                    setOpen(false)
                }}>修改</div>
            </div>}
            {flagTh && <div className='test-box'>
                <div className='test-back' onClick={() => {
                    setFlagTh(false)
                }}>
                    <ArrowLeftOutlined />
                    返回
                </div>
                <div className='test-title'>
                    身份验证
                </div>
                <p>为了保护你的账号安全,请验证身份，验证成功后进行下一步操作</p>
                <div className='test-describe'>
                    请输入手机+86 136****7295验证
                </div>
                <div className='message-test-box'>
                    <Input className='message-test' onChange={(e) => {
                        console.log(e.target.value, "value")
                        let value = e.target.value;
                         
                        if (value) {
                            setValues(false)
                            setCode(e.target.value)
                        } else {
                            setValues(true)
                        }
                    }} placeholder="输入6位数短信验证码" />
                    <Button className='link-button' type="link" onClick={()=>{
                            getSendCode()
                    }}>获取短信验证码</Button>
                </div>
                <Button type="primary" className='button-ok' disabled={values} htmlType="submit" onClick={()=>{
                      setCodeValidateflag(true)
                      codeValidateFun()
                }}>验证</Button>
            </div>}
        </div>
    )
}
