//一级路由
import Home from '../pages/home'
import Login from '../pages/login/index'


//二级路由
import Homes from '../pages/homes/homes'
import Star from '../pages/star/index.js'
import CreateNft from '../pages/createNft/index'
import Bill from '../pages/bill/index'
import My from '../pages/my'
import NftDetail from '../pages/nftDetail/index'
import NftDetailCreator from '../pages/nftDetailCreator/index'

import NftDeal from '../pages/nftDeal/index'
import PersonalNft from '../pages/personalNft/index'
import Market from '../pages/market'
import WorksSeries from '../pages/worksSeries'
import Creator from '../pages/creator'
import CreatorUrl from '../pages/creatorUrl'
import Wallet from '../pages/wallet'
import Pay from '../pages/pay'

import Tiaoshi from '../pages/test_nft'


//三级路由
import Intergral from '../pages/wallet/commopent/Intergral';
import RechargePoints from '../pages/wallet/commopent/RechargePoints';
import BillDetails from '../pages/wallet/commopent/BillDetails';
import PayAdmin from '../pages/wallet/commopent/PayAdmin';

import HomePage from '../pages/createNft/component/HomePage';
import Worksseries from '../pages/createNft/component/WorksSeries';
import WorksAdmin from '../pages/createNft/component/WorksAdmin';
import FansPage from '../pages/createNft/component/FansPage';
import CreationIncome from '../pages/createNft/component/CreationIncome';

import Attention from '../pages/my/attention';
import Order from '../pages/my/order';
import MyCollections from '../pages/my/collection';



const routerList = [
   {
      path: "/home",
      com: Home,
      children: [
         {
            path: "/home/homes",
            com: Homes,
            title: "首页"
         },
         {
            path: '/home/star',
            com: Star,
            title: '个人收藏'
         },
         {
            path: "/home/my",
            com: My,
            title: '个人信息',
            children:[
                 {
                     path: "/home/my/attention",
                     com: Attention,
                     title: '我的关注',
                 },
                  {
                     path: "/home/my/order",
                     com: Order,
                     title: '我的订单',
                  },
                  {
                     path: "/home/my/collections",
                     com: MyCollections,
                     title: '我的藏品',
                  },
                  {
                     to: "/home/my",
                     from: "/home/my/attention"
                  },
            ]
         },
         {
            path: "/home/creatnft",
            com: CreateNft,
            title: '创建NFT',
            children: [
               {
                  path: "/home/creatnft/homepage",
                  com: HomePage,
                  title: '个人首页',
               },
               {
                  path: "/home/creatnft/works_series",
                  com: Worksseries,
                  title: '系列管理',
               },
               {
                  path: "/home/creatnft/works_admin",
                  com: WorksAdmin,
                  title: '作品管理',
               },
               {
                  path: "/home/creatnft/creationIncome",
                  com: CreationIncome,
                  title: '创作收益',
               },
               {
                  path: "/home/creatnft/fanspage",
                  com: FansPage,
                  title: '我的粉丝',
               },
               {
                  to: "/home/creatnft/homepage",
                  from: "/home/creatnft"
               },
            ]
         },
         {
            path: "/home/bill",
            com: Bill,
            title: '设置'
         },
         {
            path: "/home/nftdeal",
            com: NftDeal,
            title: '查看NFT交易记录'
         },
         {
            path: "/home/nftdetail:id",
            com: NftDetail,
            title: 'NFT详情'
         },
         {
            path: "/home/detail_creator:id",
            com: NftDetailCreator,
            title: 'NFT详情创作者视角'
         },
         {
            path: "/home/personalnft",
            com: PersonalNft,
            title: '个人NFT'
         },
         {
            path: "/home/market",
            com: Market,
            title: '市场'
         },
         {
            path: "/home/worksSeries:id",
            com: WorksSeries,
            title: '作品系列'
         },
         {
            path: "/home/creator",
            com: Creator,
            title: '创作者主页'
         },
         {
            path: "/home/creators/:url",
            com: CreatorUrl,
            title: '创作者主页路径'
         },
         {
            path: "/home/wallet",
            com: Wallet,
            title: '我的钱包',
            children: [
               {
                  path: "/home/wallet/intergral",
                  com: Intergral,
                  title: '积分账户',
               },
               {
                  path: "/home/wallet/rechargePoints",
                  com: RechargePoints,
                  title: '购买积分',
               },
               {
                  path: "/home/wallet/billDetails",
                  com: BillDetails,
                  title: '账单明细',
               },
               {
                  path: "/home/wallet/paydmin",
                  com: PayAdmin,
                  title: '支付管理',
               },
               {
                  to: "/home/wallet/intergral",
                  from: "/home/wallet"
               },
            ]
         },
         {
            path: "/home/pay",
            com: Pay,
            title: '支付'
         },
         {
            path: "/home/tiaoshi",
            com: Tiaoshi,
            title: '调试页面'
         },
         {
            to: '/home/homes',
            from: '/home'
         }
      ]
   },
   {
      path: '/login',
      com: Login
   },
   {
      to: '/home',
      from: '/'
   }
]

export default routerList

