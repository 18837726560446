import Api from "@/utils/request"

// NFTZ 获取实名认证状态
export const getAuthStatus = () => {
  return Api({
    url: "/get_auth_status",
    method: "get",
  })
}

// 获取实名认证url
export const getAuthUrl = data => {
  return Api({
    url: "/get_auth_url",
    method: "post",
    data,
  })
}




