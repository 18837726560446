import Api from "@/utils/request"

// 校验验证码
export const alipayCreateNftOrder = data => {
    return Api({
      url: "/alipay/create_nft_order",
      method: "post",
      data,
    })
  }

// 支付订单
export const alipayPcPay = data => {
  return Api({
    url: "/alipay/pc_pay",
    method: "post",
    data,
  })
}

// 购买积分
export const alipayPointBuy = data => {
  return Api({
    url: "/alipay/point/buy",
    method: "post",
    data,
  })
}

// 获取支付宝订单状态
export const alipayGetOrderStatus = data => {
  return Api({
    url: "/alipay/get_order_status",
    method: "post",
    data,
  })
}