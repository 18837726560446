import React, { useState, useEffect } from 'react'
import { Select, DatePicker, Avatar, message } from 'antd';
import { bill } from '../../../../api/user'
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import { Empty } from 'antd';
import {noStatusBack} from '@/utils/icon'

export default function BillDetails() {
      const { Option } = Select;
      const [billList, setBillList] = useState([]);
      const [dateItem, setDateItem] = useState();
      const [contentItem, setContentItem] = useState('All');
      const { RangePicker } = DatePicker;
      const [typeList, setTypeList] = useState([
            { key: "All", name: "全部" },
            { key: "In", name: "全部收入" },
            { key: "Out", name: "出售" },
            { key: "BuyNFT", name: "购买" },
            { key: "BuyCoin", name: "充值" },
            { key: "Reward", name: "车主奖励" },
      ]);

      const handleChange = async (value) => {
            console.log(`selected ${value}`);
            setContentItem(value)
            if (dateItem) {
                  const start_time = parseInt(dateItem[0].valueOf() / 1000);
                  const end_time = parseInt(dateItem[1].valueOf() / 1000);
                  const params = {
                        page_no: 1,
                        page_size: 20,
                        start_time: start_time,
                        end_time: end_time,
                        type: value,
                  }
                  await bill(params).then((res) => {
                        console.log(res, "res")
                        if (res.code === 200) {
                              setBillList(res.data)
                        }
                  })
            }


      };
      const handleChangeDate = async (value) => {
            console.log(value, "value")
            setDateItem(value)
            if (value) {
                  const start_time = parseInt(value[0].valueOf() / 1000);
                  const end_time = parseInt(value[1].valueOf() / 1000);
                  const params = {
                        page_no: 1,
                        page_size: 20,
                        start_time: start_time,
                        end_time: end_time,
                        type: contentItem,
                  }
                  await bill(params).then((res) => {
                        console.log(res, "res")
                        if (res.code === 200) {
                              setBillList(res.data)
                        }
                  })
            }

      }
      const initListFun = async () => {
            const params = {
                  page_no: 1,
                  page_size: 20,
                  type: 'All'
            }
            await bill(params).then((res) => {
                  if (res.code === 200) {
                        setBillList(res.data)
                  }
            })
      }
      useEffect(() => {
            initListFun()
      }, [])

      return (
            <div className='bill-details'>
                  <div className='bill-select'>
                        <div className='bill-select-left'>
                              <div className='label'>消费时间</div>
                              <RangePicker locale={locale} onChange={handleChangeDate} className='ange-icker' />
                        </div>
                        <div className='bill-select-right'>
                              <div className='label'>类型选择</div>
                              <Select
                                    defaultValue="All"
                                    className='Select'
                                    onChange={handleChange}
                              >
                                    {
                                          typeList?.map((v, i) => {
                                                return <Option value={v.key} key={i}>
                                                      {v.name}
                                                </Option>
                                          })
                                    }
                              </Select>
                        </div>
                  </div>
                  <div className='bill-list-box'>
                        <div className='bill-list-title'>
                              <div className='one'>类型</div>
                              <div>数量</div>
                              <div>时间</div>
                        </div>
                        <div className='bill-list-card'>
                              {
                                    billList.map((v, i) => {
                                          return <div className='li' key={i}>
                                                <div className='list-card-one'>
                                                      <Avatar shape="square" className='type-icon' src="" />
                                                      <div className='card-one-box'>
                                                            {/* <div className='card-one-title'>
                                                                            充值
                                                                      </div> */}
                                                            <div className='card-one-describe'>
                                                                  {v?.trade_type}
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className='list-card-one'>
                                                      {v?.amount}
                                                </div>
                                                <div className='list-card-two'>
                                                      {v?.time}
                                                </div>
                                          </div>
                                    })
                              }
                              {billList?.length===0&&<Empty className='noStatusBack'  description='什么都没有～' image={noStatusBack} />}
                        </div>
                  </div>
            </div>
      )
}
