import { useEffect, useState } from "react"
import "./index.scss"
import { Col, Row, List, Divider, Skeleton } from "antd"
import { nft_deal } from "@/api/user"
import { useMappedState } from "redux-react-hook"
import {
  nftCollection,
} from "@/api/user"
import VirtualList from 'rc-virtual-list';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Empty } from 'antd';
import { noStatusBack } from '@/utils/icon'
const ContainerHeight = 1000;


export default function Order(props) {
  const { userId } = props;
  const { loginInformation } = useMappedState(state => {
    return state
  })

  const [orderType, setOrderType] = useState("All");
  const [pageNo, setPageNo] = useState(1);
  const [allPage, setAllPage] = useState(1);
  const [nftDeal, setNftDeal] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);

  const getOrderList = async () => {
    const params = {
      page_no: pageNo,
      page_size: 10,
      type: orderType,
    }
    await nft_deal(params).then((res) => {
      console.log(res, "res")
      if (res.code === 200) {
        setOrderTotal(res.data.count || 0)
        setNftDeal(nftDeal.concat(res.data.deal))
        if (res.data.count % 10) {
          const allPage = Math.floor(res.data.count / 10) + 1;
          setAllPage(allPage);
        } else {
          const allPage = res.data.count / 10;
          setAllPage(allPage);
        }
      }
    })
  }
  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      setPageNo(pageNo + 1);
      // 如果没到视图底部就加载列表
      if (allPage >= pageNo) {
        nft_deal();
      }
    }
  };
 
  const noticeContent = (v) => {
    return (
      <div>
        <Row className="list list-label">
          <Col span={4} className="list-item">
            交易时间: {v.time}
          </Col>
          <Col span={18} className="list-item">
            交易哈希: {v.tx_hash}
          </Col>
        </Row>
        <Row className="list list-content">
          <Col span={4} className="list-item item-msg">
            <div className="frist-box">
              <img className="nft-img" src={setImageFun(v?.meta_data)}></img>
              {v.name}
            </div>
          </Col>
          <Col span={4} className="list-item item-msg">
            {v?.describe}
          </Col>
          <Col span={2} className="list-item item-msg">
            {v.content_type}
          </Col>
          <Col span={2} className="list-item item-msg">
            {v.trade_type}
          </Col>
          <Col span={2} className="list-item item-msg">
            {v.buyer}
          </Col>
          <Col span={1} className="list-item item-msg">
            {v.price}
          </Col>
          <Col span={1} className="list-item item-msg">
            {v.deal_count}
          </Col>
          <Col span={1} className="list-item item-msg">
            {v.price}
          </Col>
          <Col span={2} className="list-item item-msg">
            {v.amount}
          </Col>
          <Col span={2} className="list-item item-msg">
            {v.method}
          </Col>
          <Col span={2} className="list-item item-msg">
            {v.status === 0 ? "成功" : v.status === 1 ? '交易失败' : '交易进行中'}
          </Col>
        </Row>
      </div>
    )
  }
  const setImageFun = (value) => {
    if (value) {
      const image = JSON.parse(value)
      return image.FrontCoverUrl
    }

  }
  useEffect(() => {
    getOrderList()
  }, [orderType, pageNo])

 

  console.log(nftDeal, 'nftDeal')
  return (
    <div className='order-box'>
      <Row className="order-type">
        <Col span={2}>
          <div
            onClick={() => {
              setOrderType("All")
            }}
            className={
              orderType == "All"
                ? "active type-item f-r f-c-c"
                : "type-item f-r f-c-c"
            }
          >
            全部
          </div>
        </Col>
        <Col span={2}>
          <div
            onClick={() => {
              setOrderType("Sell")
            }}
            className={
              orderType == "Sell"
                ? "active type-item f-r f-c-c"
                : "type-item f-r f-c-c"
            }
          >
            售出
          </div>
        </Col>
        <Col span={18}>
          <div
            onClick={() => {
              setOrderType("Buy")
            }}
            className={
              orderType == "Buy"
                ? "active type-item f-r f-c-c"
                : "type-item f-r f-c-c"
            }
          >
            购买
          </div>
        </Col>
        <Col span={2} className="order-total">
                    共{orderTotal}笔订单
        </Col>
      </Row>
      {
        orderType == "All" && nftDeal?.length === 0 && <Empty className='noStatusBack' description='什么都没有～' image={noStatusBack} />
      }
      {
        orderType == "Sell" && nftDeal?.length === 0 && <Empty className='noStatusBack' description='什么都没有～' image={noStatusBack} />
      }
      {
        orderType == "Buy" && nftDeal?.length === 0 && <Empty className='noStatusBack' description='什么都没有～' image={noStatusBack} />
      }
      <div className="order-list">
        {(orderType == "All"||orderType == "Sell"||orderType == "Buy") && nftDeal?.length > 0 && <Row className="list-header list">
          <Col span={4} className="list-item">
            作品名称
          </Col>
          <Col span={4} className="list-item">
            作品简介
          </Col>
          <Col span={2} className="list-item">
            作品类型
          </Col>
          <Col span={2} className="list-item">
            交易类型
          </Col>
          <Col span={2} className="list-item">
            交易对象
          </Col>
          <Col span={1} className="list-item">
            价格
          </Col>
          <Col span={1} className="list-item">
            数量
          </Col>
          {/* <Col span={1} className="list-item">
            交易价格
          </Col> */}
          <Col span={2} className="list-item">
            支付金额
          </Col>
          <Col span={2} className="list-item">
            支付方式
          </Col>
          <Col span={1} className="list-item">
            状态
          </Col>
        </Row>}
        {/* {nftDeal?.map((v, i) => {
          return (
            <div key={i}>
              <Row className="list list-label">
                <Col span={4} className="list-item">
                  {v.time}
                </Col>
                <Col span={18} className="list-item">
                  交易哈希:{v.tx_hash}
                </Col>
              </Row>
              <Row className="list list-content">
                <Col span={4} className="list-item item-msg">
                  <div className="frist-box">
                    <img className="nft-img" src={setImageFun(v?.meta_data)}></img>
                    {v.name}
                  </div>
                </Col>
                <Col span={4} className="list-item item-msg">
                  {v?.describe}
                </Col>
                <Col span={2} className="list-item item-msg">
                  {v.content_type}
                </Col>
                <Col span={2} className="list-item item-msg">
                  {v.trade_type}
                </Col>
                <Col span={2} className="list-item item-msg">
                  {v.buyer}
                </Col>
                <Col span={1} className="list-item item-msg">
                  {v.price}
                </Col>
                <Col span={1} className="list-item item-msg">
                  {v.deal_count}
                </Col>
                <Col span={2} className="list-item item-msg">
                  {v.price}
                </Col>
                <Col span={2} className="list-item item-msg">
                  {v.amount}
                </Col>
                <Col span={2} className="list-item item-msg">
                  {v.method}
                </Col>
                <Col span={1} className="list-item item-msg">
                  {v.status === 0 ? "成功" : v.status === 1 ? '交易失败' : '交易进行中'}
                </Col>
              </Row>
            </div>
          )
        })} */}
        {/* <InfiniteScroll
          dataLength={nftDeal.length}
          next={nftDeal}
          hasMore={false}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          endMessage={<Divider plain>没有更多数据了🤐</Divider>}
          scrollableTarget="scrollableDiv"
        > */}
        <List>
          <VirtualList
            data={nftDeal}
            height={ContainerHeight}
            // itemHeight={107}
            itemKey="id"
            onScroll={onScroll}
          >
            {(item) => (
              <List.Item key={item?.uri}>
                <List.Item.Meta
                  description={noticeContent(item)}
                />
              </List.Item>
            )}
          </VirtualList>
        </List>
        {/* </InfiniteScroll> */}

      </div>
    </div>
  )
}
