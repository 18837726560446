import React, { useState, useEffect } from 'react'
import { bill } from '../../api/user'
import { message } from 'antd';
import { useMappedState, useDispatch } from 'redux-react-hook'
import './index.scss'
import EssentialInformation from './component/EssentialInformation'
import MessageNotification from './component/MessageNotification'
import SecuritySetting from './component/SecuritySetting'
import { profile, profileEdit, avatarEdit } from "@/api/user"

export default function Bill() {
  const [acitves, setAcitves] = useState(1);
  const [userInformation, setUserInformation] = useState();
  let dispatch = useDispatch();
  let { loginInformation} = useMappedState((state) => {
    return state;
  })

 
  const getSingleInfor = async () => {
    const params = {
      user_id: loginInformation.id,
    }
    await profile(params).then(res => {
      if (res.code === 200) {
        setUserInformation(res.data)
      }

    })
  }
  useEffect(() => {
    // getbillList()
    getSingleInfor();
  }, [])
  return (
    <div className='setUp'>
      <div className='space'></div>
      <div className='father-content'>
        <div className='left-card'>
          <div className='setUp-button'>设置</div>
          <div className='menus'>
            <div className={acitves === 1 ? 'active' : ''} onClick={() => {
              setAcitves(1)
            }}>基本信息</div>
            <div className={acitves === 2 ? 'active' : ''} onClick={() => {
              setAcitves(2)
            }}>安全设置</div>
            <div className={acitves === 3 ? 'active' : ''} onClick={() => {
              setAcitves(3)
            }}>消息设置</div>
          </div>
        </div>
        <div className='right-card'>
          <div className='setUp-form'>
            <p className='title-form'>{acitves === 1 ? "基本信息":(acitves === 2 ? "安全设置":"消息通知")}</p>
            {acitves===1&&<EssentialInformation userInformation={userInformation} />}
            {acitves===2&&<SecuritySetting/>}
             {acitves===3&&<MessageNotification/>}
          </div>
        </div>
      </div>
    </div>
  )
}
