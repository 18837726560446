import { useState } from "react"
import "../scss/box.scss"
import { Image } from "antd"
import { useHistory } from "react-router-dom";
import { HeartFilled } from "@ant-design/icons"

export default function StarCollection(props) {
  let { collection } = props;
  const history = useHistory();
  const setImageFun = (value) => {
    if (value) {
      const image = JSON.parse(value)
      return image.FrontCoverUrl
    }

  }
  return (
    <div className="box-card f-c" onClick={() => {
           history.push('/home/nftdetail' + collection.asset_id, { v: collection })
    }}>
      <div className="top">
        <img
          src={setImageFun(collection?.meta_data)}
          className="top-img collection-img f-r f-c-c"
        />
      </div>
      <div className="bottom f-c">
        <div>
          <div>{collection.asset_id || "没有编号"}</div>
          <div>{collection.collection_name || "没有名字"}</div>
        </div>

        <div style={{ textAlign: "right" }} className="f-r f-c-e">
          <HeartFilled style={{ fontSize: "16px" }} />
          &nbsp;{collection.star_num || 0}
        </div>
      </div>
    </div>
  )
}
