import Api from "@/utils/request";
import NoTokenApi from "@/utils/request";
// 查看用户统计信息
export const asset= data => {
    return Api({
      url: "/asset",
      method: "get",
      params:data,
    })
}

// 查看collection包含的所有nft
export const collectionNft= data => {
  return Api({
    url: "/collection/nft",
    method: "get",
    params:data,
  })
}

// 查看collection包含的所有nft
export const profileUrl= data => {
  return NoTokenApi({
    url: "/profile_url",
    method: "get",
    params:data,
  })
}
