import "./scss/index.scss"
import React, { useEffect, useState } from "react"
import { Avatar, message } from "antd"
import { NavLink } from 'react-router-dom'
import avatar from '@/assets/img/default-avatar.svg'
import {
  profile,
  nftCollection,
  nft_deal,
  seeStarNft,
  seeStarCollection,
  following,
} from "../../api/user"

import { useMappedState, useDispatch } from "redux-react-hook"
import { EditOutlined } from "@ant-design/icons"
import { alipayGetOrderStatus } from '@/api/alipay'
import RouterView from '@/router/RouterView'

export default function PersonalInformation(props) {

  const [flag, setFlag] = useState(1);
  const [user, setUser] = useState({});
  const { chil } = props;
  const outTradeNo = localStorage.getItem("out_trade_no")
  const [collectionCount, setCollectionCount] = useState(0);
  const [starSeriosTotal, setStarSeriosTotal] = useState(0)
  const [starNftTotal, setStarNftTotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [starCreatorTotal, setStarCreatorTotal] = useState(0)

  const { loginInformation, PersonalCenterType, } = useMappedState(state => {
    return state;
  })

  // 用户购买的NFT
  async function getNftCollection() {
    const { code, data } = await nftCollection({
      content_type: 'All', //缺少关注类型的参数
    })
    if (code == 200) {
      setCollectionCount(data.count)
    }
  }
  const getOrderList = async () => {
    const params = {
      type: "All",
    }
    await nft_deal(params).then((res) => {
      console.log(res, "res")
      if (res.code === 200) {
        setOrderTotal(res.data.count || 0)
      }
    })
  }
  const getStarNft = async () => {
    // 这个接口有毛病
    const res = await seeStarNft({
      user_id: loginInformation.id,
    })
    let { code, data } = res
    if (code == 200) {
      setStarNftTotal(data.count)
    }
  }
  // 关注的系列
  const getStarSerios = async () => {
    const res = await seeStarCollection({
      user_id: loginInformation.id,
    })
    let { code, data } = res
    if (code == 200) {
      setStarSeriosTotal(data.count)
    }
  }
  // 关注的创作者
  const getStarCreator = async () => {
    const res = await following({
      user_id: loginInformation.id,
    })
    let { code, data } = res
    if (code == 200) {
      setStarCreatorTotal(data.count)
    }
  }

  const fun = (timer) => {
    setTimeout(() => {
      const params = {
        out_trade_no: outTradeNo,
      }
      // 这里ajax 请求的代码片段和判断是否停止定时器
      // 如需要停止定时器，只需加入以下：
      alipayGetOrderStatus(params).then((res) => {
        if (res.code === 200) {
          if (res.data.status === 0) {
            clearInterval(timer);
            message.success('交易成功')
            localStorage.removeItem('out_trade_no')
          } else if (res.data.status === 1) {
            clearInterval(timer);
            message.error('交易失败')
            localStorage.removeItem('out_trade_no')
          } else if (res.data.status === 2) {
            console.log('正在交易中')
            // localStorage.removeItem('out_trade_no')
          }

        }
      })
    }, 0)
  }
  const alipayGetOrderStatusFun = () => {
    let timer = setInterval(() => {
      fun(timer);
    }, 2000)
  }

  const getOrderStatus = () => {
    if (outTradeNo) {
      alipayGetOrderStatusFun()
    }
  }

  // 查看用户信息
  async function getProfile() {
    const res = await profile({ user_id: loginInformation.id })
    if (res.code == 200) {
      setUser(res?.data || "")
    }
  }

  const activeFun = () => {
    if (PersonalCenterType) {
      setFlag(PersonalCenterType)
    }
  }
  useEffect(() => {
    getProfile();
    getOrderStatus();
    getNftCollection();
    getOrderList();
    getStarNft();
    getStarSerios();
    getStarCreator();
  }, [])
  useEffect(() => {
    activeFun()
  }, [PersonalCenterType])

  console.log(outTradeNo, "outTradeNo")
  return (
    <div className="PersonalInformation">
      <div className="header-card">
        <div className="header-card-content max-box">
          <div className="left">
            <Avatar className="avatar" size={114} src={user.avatar || avatar} />
            <div className="right-content">
              <div className="nick-name f-r f-c-b">
                <div>{user.username || "无数据"}</div>
                <div className="edit-user" onClick={() => {
                  props.history.push('/home/bill')
                }}>
                  <EditOutlined
                    style={{
                      fontSize: "16px",
                      margin: "0 5px",
                      color: "#202020",
                    }}
                  />
                  修改信息
                </div>
              </div>
              <div className="address">区块链账号地址：{user.address}</div>
              <div className="describe">{user.brief || "什么都没有～"}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-ruadus-box">

      </div>
      <div className='header-table-box'>
        <div className='header-table'>
          <div id={flag === 1 ? "active" : ""} onClick={() => {
            setFlag(1)
            props.history.push('/home/my/collections')
          }}> <a>
              我的藏品{collectionCount}
            </a></div>
          <div id={flag === 2 ? "active" : ""} onClick={() => {
            setFlag(2)
            props.history.push('/home/my/attention')
          }}> <a>
              我的关注{starNftTotal?starNftTotal:starSeriosTotal?starSeriosTotal:starCreatorTotal}
            </a></div>
          <div id={flag === 3 ? "active" : ""} onClick={() => {
            setFlag(3)
            props.history.push('/home/my/order')
          }}> <a>
              我的订单{orderTotal}
            </a></div>
        </div>

      </div>
      <RouterView routerList={chil}>
      </RouterView>
    </div>
  )
}
