import Api from "@/utils/request"
import NoTokenApi from "@/utils/request"

// 编辑用户头像
export const avatarEdit = data => {
  return Api({
    url: "/avatar/edit",
    method: "post",
    data,
  })
}

//查看积分记录
export const bill = data => {
  return Api({
    url: "/bill",
    method: "get",
    params: data,
  })
}

// 查看用户创建的Collection
export const Collection = data => {
  return NoTokenApi({
    url: "/collection",
    method: "get",
    params: data,
  })
}

//关注&&取关
export const follow = data => {
  return Api({
    url: "/follow",
    method: "post",
    data,
  })
}

//查看粉丝
export const followers = data => {
  return Api({
    url: "/followers",
    method: "get",
    params: data,
  })
}

// 查看关注了那些人
export const following = data => {
  return Api({
    url: "/following",
    method: "get",
    params: data,
  })
}

// 查看所有消息
export const messageAll = data => {
  return Api({
    url: "/message",
    method: "get",
    params: data,
  })
}

// 查看消息详情
export const messageDetial = data => {
  return Api({
    url: "/message/detial",
    method: "get",
    params: data,
  })
}

// 查看未读消息总数
export const messageUnreadCount = data => {
  return Api({
    url: "/message/unread_count",
    method: "get",
    params: data,
  })
}

// 查看nft交易记录
export const nft_deal = data => {
  return Api({
    url: "/nft_deal",
    method: "get",
    params: data,
  })
}

// 忘记支付密码
export const payForgetPassword = data => {
  return Api({
    url: "/pay/forget_password",
    method: "post",
    data,
  })
}

// 修改支付密码
export const payPasswordEdit = data => {
  return Api({
    url: "/pay/password/edit",
    method: "post",
    data,
  })
}

// 创建支付密码
export const payPasswordSet = data => {
  return Api({
    url: "/pay/password/set",
    method: "post",
    data,
  })
}

//修改手机号
export const phoneEdit = data => {
  return Api({
    url: "/phone/edit",
    method: "post",
    data,
  })
}

// 购买积分
export const pointBuy = data => {
  return Api({
    url: "alipay/point/buy",
    method: "post",
    data,
  })
}

// 个人基本信息
export const profile = data => {
  return Api({
    url: "/profile",
    method: "get",
    params: data,
  })
}

// 编辑用户信息
export const profileEdit = data => {
  return Api({
    url: "/profile/edit",
    method: "post",
    data,
  })
}

// 编辑用户信息
export const creatorProfileEdit = data => {
  return Api({
    url: "creator/profile/edit",
    method: "post",
    data,
  })
}


// 查看收藏的系列
export const seeStarCollection = data => {
  return NoTokenApi({
    url: "/star/collection",
    method: "get",
    params: data,
  })
}
// 添加取消collection收藏
export const starCollection = data => {
  return Api({
    url: "/star/collection",
    method: "post",
    data,
  })
}

// 添加移除nf收藏
export const seeStarNft = data => {
  return NoTokenApi({
    url: "/star/nft",
    method: "get",
    params: data,
  })
}

// 添加取消NFT收藏
export const starNFT = data => {
  return Api({
    url: "/star/nft",
    method: "post",
    data,
  })
}

// 查看个人创建的nft(私有接口)
export const userNftPrivate = data => {
  return Api({
    url: "/creator/nft/private",
    method: "get",
    params: data,
  })
}

// 普通用户查看创作者中心
export const creatorNftPublic = data => {
  return Api({
    url: "/creator/nft/public",
    method: "get",
    params: data,
  })
}

// 查看个人中心
export const userNftPublic = data => {
  return Api({
    url: "/user/nft/public",
    method: "get",
    params: data,
  })
}

// 用户收藏的nft
export const nftCollection = data => {
  return Api({
    url: "/user/nft",
    method: "get",
    params: data,
  })
}

// 查看用户已关注此nft或collection
export const isStar = data => {
  return Api({
    url: "/is_star",
    method: "get",
    params: data,
  })
}

// 作品置顶
export const top = data => {
  return Api({
    url: "/top",
    method: "post",
    data,
  })
}


// 消息设置
export const messageSetting = data => {
  return Api({
    url: "/message/setting",
    method: "post",
    data,
  })
}


// 查看消息设置
export const seeMessageSetting = data => {
  return Api({
    url: "/message/setting",
    method: "get",
    params: data,
  })
}

