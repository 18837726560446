import React, { useEffect, useState } from 'react'
import { Button, Avatar, } from 'antd';
import { Carousel, Modal } from 'antd';
import { NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import './index.scss'
import { banner1, banner2, label1, label2 } from '@/utils/icon.js'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { messageUnreadCount } from '@/api/user'



export default function HeaderTop(props) {
        const dispatch = useDispatch();
        const history = useHistory();
        const [isModalOpen, setIsModalOpen] = useState(false)
        const handleOk = () => {
                setIsModalOpen(false)
        }
        const handleCancel = () => {
                setIsModalOpen(false)
        }
        const { userInformation, loginInformation } = useMappedState(state => {
                return state
        })
        const messageUnreadCountFun = async () => {
                if (loginInformation) {
                        await messageUnreadCount().then(res => {
                                if (res.code == 200) {
                                        dispatch({ type: 'MESSAGE_COUNT', messageCount: res.data.count })
                                }
                        })
                }
        }
        console.log(userInformation, 'userInformation')
        useEffect(() => {
                messageUnreadCountFun();
        }, [])
        return (
                <div className='headerTop'>
                        <Modal
                                title="我要成为创作者"
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                        >
                                <div>
                                        <div>
                                                目前创作者权限尚未开放，如你和你的团队想成为平台创作者，欢迎联系我们
                                        </div>
                                        <div>vip@chipsecwethings.com</div>
                                </div>
                        </Modal>

                        <div className='homes-header-box'>
                                <div className='bannner'>
                                        <Carousel autoplay effect="fade">
                                                <div className='banner-item-box'>
                                                        <Avatar shape="square" className='bannner-avartar' src={banner1} />

                                                        <div className='bannner-content-box'>
                                                                <div className='left-title'>
                                                                        YU-Gl-YN 游戏元1 &nbsp;<img alt='' className='bannner-label-avatar' src={label1} />
                                                                </div>
                                                                <div className='left-describle'>
                                                                        游戏元YU-Gl-YN NFT登录dawn,限量发售,先到先得
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className='banner-item-box'>
                                                        <Avatar shape="square" className='bannner-avartar' src={banner2} />
                                                        <div className='bannner-content-box'>
                                                                <div className='left-title'>
                                                                        YU-Gl-YN 游戏元2&nbsp; <img alt='' className='bannner-label-avatar' src={label2} />
                                                                </div>
                                                                <div className='left-describle'>
                                                                        游戏元YU-Gl-YN NFT登录dawn,限量发售,先到先得
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className='banner-item-box'>
                                                        <Avatar shape="square" className='bannner-avartar' src={banner1} />
                                                        <div className='bannner-content-box'>
                                                                <div className='left-title'>
                                                                        YU-Gl-YN 游戏元3&nbsp;<img alt='' className='bannner-label-avatar' src={label1} />
                                                                </div>
                                                                <div className='left-describle'>
                                                                        游戏元YU-Gl-YN NFT登录dawn,限量发售,先到先得
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className='banner-item-box'>
                                                        <Avatar shape="square" className='bannner-avartar' src={banner2} />
                                                        <div className='bannner-content-box'>
                                                                <div className='left-title'>
                                                                        YU-Gl-YN 游戏元4
                                                                </div>
                                                                <div className='left-describle'>
                                                                        游戏元YU-Gl-YN NFT登录dawn,限量发售,先到先得
                                                                </div>
                                                        </div>
                                                        <div className='border-shadow'>
                                                                
                                                        </div>
                                                </div>
                                        </Carousel>
                                        {/* <div className='box-shodow'>
                                        </div> */}
                                </div>
                                <div className='homes-header-right'>
                                        <div className='common'>
                                                共同发现价值<br />
                                                探索数字宇宙
                                        </div>
                                        <div className='right-describe'>
                                                一站式区中心化NFT交易平台,支持多链NFT创作与跨平台交易,为你提供实时链上数据。
                                        </div>
                                        <div className='right-button-box'>
                                                <Button type="primary" className='button-left' onClick={() => {
                                                        history.push('/home/market')
                                                }}>
                                                        探索
                                                </Button>
                                                <Button className='button-right' onClick={() => {
                                                        if (userInformation?.role === 2) {
                                                                dispatch({ type: "ACTIVE_TYPE", activeType: 2 })
                                                                history.push('/home/creatnft/works_admin')
                                                        } else {
                                                                setIsModalOpen(true)
                                                        }
                                                }}>创作NFT
                                                </Button>
                                        </div>
                                </div>
                        </div>


                </div>
        )
}
