import React, { useState, useEffect, message } from "react"
import VirtualList from 'rc-virtual-list';
import "./index.scss"
import { useMappedState, useDispatch } from "redux-react-hook"
import { Avatar, Modal, Badge, Popover, Image, List, Divider, Skeleton } from "antd"
import InfiniteScroll from 'react-infinite-scroll-component';
import avatar from '@/assets/img/default-avatar.svg'
import { xzl, Logo, tzh, footerLogo } from "@/utils/icon.js"
import { profile, messageAll, messageUnreadCount } from '@/api/user'
import { compose } from "redux";

const fakeDataUrl =
  'https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo';
const ContainerHeight = 682;


export default function Index(props) {

  const [flag, setFlag] = useState("#fff")
  const [active, setActive] = useState();
  const [userInformation, setUserInformation] = useState();
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [isRead, setIsRead] = useState(false);
  let dispatch = useDispatch()
  const token = localStorage.getItem("token")
  // const token = true;
  const { loginInformation, messageCount } = useMappedState(state => {
    return state
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const getSingleInfor = async () => {
    const params = {
      //   user_id: nftDetailObj?.seller_id
      user_id: loginInformation?.id
    }
    if (loginInformation) {
      await profile(params).then(res => {
        if (res.code == 200) {
          setUserInformation(res.data)
          dispatch({ type: 'GET_USERINFORMATION', userInformation: res.data })
        }
      })
    }
  }


  const messageUnreadCountFun = async () => {
    if (loginInformation) {
      await messageUnreadCount().then(res => {
        if (res.code == 200) {
          dispatch({ type: 'MESSAGE_COUNT', messageCount: res.data.count })
        }
      })
    }
  }

  useEffect(() => {
    getSingleInfor();
    messageUnreadCountFun();
  }, [])

  const loadMoreData = async () => {
    setPageNo(pageNo + 1)
    if (loading) {
      return;
    }
    setLoading(true);
    const params = {
      page_no: pageNo,
      page_size: 10
    }
    await messageAll(params).then(res => {
      if (res.code === 200) {
        setTotal(res.data.count)
        setData([...data, ...res.data.message]);
        setLoading(false);

      } else {
        setLoading(false);
      }

    })
  };
  useEffect(() => {
    loadMoreData();
  }, []);
  const noticeContent = (item) => {
    return (
      <div className="notice-item">
        <div>{item?.from_username}</div>
        <div>{item?.title}</div>
        <div>{item?.time}</div>
      </div>
    )
  }

  const rechargePoints = (item) => {
    return (
      <div className="operation">
        <div className="left">
          {item.msg_type === "1" ? "充" : (item.msg_type === "5" ? "售" : (item.msg_type === "6" ? "奖" : (item.msg_type === "4" ? "买" : "")))}
        </div>
        <div className="right">
          <div>{item.msg_type === "1" ? "充值积分" : (item.msg_type === "5" ? "作品成功出售" : (item.msg_type === "6" ? "权益奖励" : (item.msg_type === "4" ? "购买作品成功" : "")))}</div>
          <div>{item?.title}</div>
          <div>{item?.time}</div>
        </div>

      </div>
    )
  }
  const content = (
    <div
      id="scrollableDiv"
      style={{
        height: 682,
        overflow: 'auto',
        padding: '0 16px',
      }}
    >
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < total}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>到底啦</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={data}
          renderItem={item => (
            <List.Item key={item.id}>
              {
                item.msg_type === '4' ? <List.Item.Meta
                  avatar={<Avatar src={item.from_user_avatar} style={{ width: 40, height: 40 }} />}
                  description={noticeContent(item)}
                /> : <div>{rechargePoints(item)}</div>
              }
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );

  const myMenu = (

    <div className="select_mather">
      <div
        className="select_child"
        onClick={() => {
          setActive(1)
          props.history.push("/home/my/collections", { type: 1 })
          dispatch({ type: 'PERSONALCENTERTYPE', PersonalCenterType: 1 })
        }}
      >
        个人中心
      </div>
      <div
        className="select_child"
        onClick={() => {
          setActive(2)
          props.history.push("/home/my/attention", { type: 2 })
          dispatch({ type: 'PERSONALCENTERTYPE', PersonalCenterType: 2 })
        }}
      >
        我的关注
      </div>
      <div
        className="select_child"
        onClick={() => {
          setActive(4)
          props.history.push("/home/my/order", { type: 3 })
          dispatch({ type: 'PERSONALCENTERTYPE', PersonalCenterType: 3 })
        }}
      >
        我的订单
      </div>
      <div
        className="select_child"
        onClick={() => {
          setActive(6)
          props.history.push("/home/my/collections", { type: 1 })
          dispatch({ type: 'PERSONALCENTERTYPE', PersonalCenterType: 1 })
        }}
      >
        我的藏品
      </div>
      <div
        className="select_child"
        onClick={() => {
          setActive(3)
          props.history.push("/home/wallet")
        }}
      >
        我的钱包
      </div>

      <div
        className="select_child"
        onClick={() => {
          setActive(5)
          props.history.push("/home/bill")
        }}
      >
        安全设置
      </div>

      <div
        className="select_child last_child"
        onClick={() => {
          setActive(7)
          localStorage.clear()
          props.history.push("/login")
        }}
      >
        退出
      </div>
    </div>
  )

  const clickMessageIcon = () => {
    setIsRead(!isRead)
  }


  return (
    <header
    >
      <Modal
        title="我要成为创作者"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <div>
            目前创作者权限尚未开放，如你和你的团队想成为平台创作者，欢迎联系我们
          </div>
          <div>vip@chipsecwethings.com</div>
        </div>
      </Modal>
      <div className="header-bar">
        <div className="header-bar-left" onClick={() => {
          props.history.push("/home/homes")
        }}>
          <img className="logo" src={Logo} alt="log" />
        </div>
        <div className="header-bar-right">
          <div className="menu-nav">
            <div
              className="child"
              onClick={() => {
                props.history.push("/home/homes")
              }}
            >
              <a>首页</a>
            </div>
            <div
              className="child"
              onClick={() => {
                props.history.push("/home/market")
              }}
            >
              <a>市场</a>
            </div>
            <div
              className="child"
              onClick={() => {
                if (loginInformation?.role === 2) {
                  props.history.push("/home/creatnft")
                } else {
                  setIsModalOpen(true)
                }
              }}
            >
              <a>创作</a>
            </div>
          </div>

          <div className={`${isRead ? "read-active" : "unrend"} notice`} onClick={clickMessageIcon}>
            <Popover content={content} overlayClassName='notice-popover' title="互动消息" trigger="hover">
              
                <Badge size="large" count={messageCount}>
                  <Avatar className="notice-icon" shape="square" src={tzh}  />
                </Badge>
              
            </Popover>
          </div>
          {token && (
            <div className="headerName">
              <Popover content={myMenu} overlayClassName='mine-popover' trigger="hover">
                <Avatar
                  id='login-avatar'
                  src={userInformation?.avatar || avatar}
                  onClick={() => {
                    props.history.push("/home/my/collections", { type: 1 })
                    dispatch({ type: 'PERSONALCENTERTYPE', PersonalCenterType: 1 })
                  }}
                  style={{ width: 44, height: 44 }}
                />
              </Popover>
            </div>
          )}
          {!token && (
            <div
              className="headerName child"
              onClick={() => {
                props.history.push("/login")
              }}
            >
              <a>登录</a>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
