import Api from "@/utils/request";
import NoTokenApi from "@/utils/request"

// 登录
export const login = data => {
  return NoTokenApi({
    url: "/login",
    method: "post",
    data,
    /**headers: {
      "accept": "application/json",
      "Content-Type": "application/x-www-form-urlencoded", 
    },*/
  })
}
// 注册
export const signup = data => {
    return NoTokenApi({
      url: "/signup",
      method: "post",
      data,
    })
}

// 发送验证码
export const send_code = data => {
    return NoTokenApi({
      url: "/send_code",
      method: "post",
      data,
    })
}

// 忘记密码
export const forget_password = data => {
    return Api({
      url: "/forget_password",
      method: "post",
      data,
    })
}


// 校验验证码
export const codeValidate = data => {
  return NoTokenApi({
    url: "/code/validate",
    method: "get",
    params:data,
  })
}