import React, { useState, useEffect } from "react"
import { Button, Col, Row, Form, Input } from "antd"
import { Checkbox, message } from "antd"
import { login, signup, send_code, forget_password } from "../../api/login"
import { useMappedState, useDispatch } from "redux-react-hook"
import "./index.scss"
import { profileEdit, creatorProfileEdit } from "@/api/user"
import loginLogo from '@/assets/img/loginLogo.png'

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
}

export default function Index(props) {
  const dispatch = useDispatch()
  const [loginStatus, setLoginStatus] = useState("1") //1.登录2.注册3.找回密码
  const [countdown, setCountdown] = useState(60)
  const [flag, setFlag] = useState(false)
  const [numVal, setNumVal] = useState('');
  const [form] = Form.useForm()

  const onFinish = async values => {
    const localData = "";
    if (loginStatus === "1") {
      await login(values).then(res => {
        console.log(res)
        if (res?.code === 200) {
          localStorage.setItem("token", res.data.token)
          localStorage.setItem("loginInformation", JSON.stringify(res.data))
          dispatch({ type: "LOGIN_INFORMATION", loginInformation: res.data })
          props.history.push("/homes")
        } else {
          debugger
          message.error(res?.msg)
        }
      })
    } else if (loginStatus === "2") {
      await signup(values).then(res => {
        if (res?.code === 200) {
          message.success("注册成功")
          setLoginStatus("1")
        } else {
          message.error(res?.msg)
        }
      })
    } else if (loginStatus === "3") {
      await forget_password(values).then(res => {
        if (res?.code === 200) {
          message.success("找回成功")
          setLoginStatus("1")
          form.resetFields()
        } else {
          message.error(res?.msg)
        }
      })
    }
  }

  const getCountDown = () => {
    if (flag) {
      let timer = setTimeout(function () {
        setCountdown(countdown - 1)
        if (countdown === 0) {
          clearInterval(timer)
          return setFlag(false)
          // setFlag(false);
          // setCountdown(60)
        }
      }, 1000)

    }
  }

  const getSendCode = async () => {
    let userData = form.getFieldsValue("phone")
    getCountDown()
    let type = loginStatus === "2" ? "注册" : "修改"
    let params = {
      phone: userData.phone,
      type: type,
    }
    await send_code(params)
  }
  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo)
  }

  const onFill = () => {
    form.resetFields()

    if (loginStatus === "1") {
      setLoginStatus("2")
    } else if (loginStatus === "2") {
      setLoginStatus("1")
    } else if (loginStatus === "3") {
      setLoginStatus("1")
    }
  }
  const forgetFun = () => {
    setLoginStatus("3")
  }


  const defaultAvatarFun = async (values) => {
    console.log("Success:", values)
    const formData = new FormData();

    if (values.avatar) {
      formData.append("avatar", values.avatar[0].originFileObj)
    }
    //  if(userInformation.role===1){
    //         await profileEdit(formData).then((res)=>{
    //           console.log(res,'res')
    //               if(res.code===200){

    //               }else{
    //                     message.error(res.msg)
    //               }
    //         })
    // }else if(userInformation.role===2){
    await creatorProfileEdit(formData).then((res) => {
      console.log(res, 'res')
      if (res.code === 200) {

      } else {
        message.error(res.msg)
      }
    })
    // }

  }

  useEffect(() => {
    getCountDown()
  })

  

  console.log(loginStatus, "loginStatus")
  console.log(countdown, "countdown")
  const goToPage = (status) => {
    // console.log('status--->', status)
  }
  return (
    <div className="login">
      <div className="header">
        <img src={loginLogo} />
      </div>
      <div className="loginContent">
        <div className="left">
          <h1>探索数字宇宙</h1>
        </div>

        <div className="loginFrom">
          <div className="from-title">
            {loginStatus === "1"
              ? "登录"
              : loginStatus === "2"
                ? "注册"
                : "重置密码"}
          </div>
          <div className="textDescription">
            <span className="normal">
              {loginStatus === "1"
                ? "还没有账号，"
                : loginStatus === "2"
                  ? "已有账户，"
                  : ""}
            </span>
            {/* <span className='light'>免费注册</span> */}
            <span className="light" onClick={onFill}>
              {loginStatus === "1"
                ? "免费注册"
                : loginStatus === "2"
                  ? "前去登录"
                  : "返回登录"}
            </span>
          </div>
          <div className="antdForm">
            <Form
              name="basic"
              form={form}
              // labelCol={{
              //   span: 8,
              // }}
              // wrapperCol={{
              //   span: 16,
              // }}
              // initialValues={{
              //   remember: true,
              // }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {loginStatus !== "1" && (
                <Form.Item
                  // label="电话号码"
                  name="phone"
                  rules={[{ required: true, message: "请输入手机号" }, {
                    pattern: /^1[3-9]\d{9}$/,
                    message: '请输入正确的手机号',
                  },]}
                >
                  <div className="phoneFather">
                    <Input
                      className="phone"
                      // style={{ fontSize: "18px", paddingLeft: "80px" }}
                      placeholder="请输入手机号"
                    />
                    <span className="phoneCode">+86</span>
                  </div>
                </Form.Item>
              )}
              {loginStatus !== "3" && loginStatus !== "2" && (
                <Form.Item
                  // label="用户名"
                  name="name"
                  rules={[
                    {
                      pattern: /^1[3-9]\d{9}$/,
                      message: '请输入正确的手机号',
                    },
                    { required: true, message: '请输入账号名称或手机号' }
                  ]}
                >
                  <Input placeholder="请输入账号名称或手机号" />
                </Form.Item>
              )}
              {loginStatus === "2" && (
                <Form.Item
                  // label="密码"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "请输入注册密码",
                    },
                    {
                      pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                      message: '建议密码由６～16位大小写字母／数字／符号至少两种组成',
                    },
                  ]}
                >
                  <Input.Password placeholder="6~16位大小写/数字/符号至少两种组成" />
                </Form.Item>
              )}

              {loginStatus !== "1" && (
                <Form.Item
                // label="验证码"
                >
                  <Form.Item
                    name="code"
                    noStyle
                    rules={[{ required: true, message: "请输入验证码!" }]}
                    getValueFromEvent={(e) => {
                      const { value } = e.target;
                      return value.replace(/[^\d]/g, '');
                    }}
                  >
                    <Input placeholder="请输入验证码" maxLength={6} />
                  </Form.Item>


                  <Button
                    type="link"
                    disabled={flag}
                    className={`submitBtn ${flag ? "grey" : ""}`}
                    onClick={() => {
                      getSendCode()
                      setFlag(true)
                      setCountdown(60)
                    }}
                  >
                    {flag ? `${countdown}s后重新获取` : "获取验证码"}
                  </Button>
                </Form.Item>
              )}
              {(loginStatus === "1") && (
                <Form.Item
                  // label="密码"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "请输入登录密码",
                    },
                  ]}
                >
                  <Input.Password placeholder="请输入登录密码" />
                </Form.Item>
              )}
              {(loginStatus === "3") && (
                <Form.Item
                  // label="密码"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "请输入密码",
                    },
                    {
                      pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                      message: '建议密码由６～16位大小写字母／数字／符号至少两种组成',
                    },
                  ]}
                >
                  <Input.Password placeholder="6~16位大小写/数字/符号至少两种组成" />
                </Form.Item>
              )}
              {loginStatus === "3" && (
                <Form.Item
                  // label="密码"
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "请你再次输入密码!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error("您两次输入的密码不一致")
                        )
                      },
                    }),
                  ]}
                >
                  <Input.Password placeholder="确认密码" />
                </Form.Item>
              )}
              <div className="pswTips" >
                <div ></div>
                <div >
                  {loginStatus === "1" && (
                    <div
                      className="forgotPsw"
                      onClick={forgetFun}
                    >
                      忘记密码
                    </div>
                  )}
                </div>
              </div>
              {/* {loginStatus==="1"&&<div className='spacing'></div>} */}
              <Button
                className={`login-form-button ${loginStatus === '1' ? "loginBtn" : 'otherBtn'}`}
                type="primary"
                htmlType="submit"
                block
                onClick={goToPage(loginStatus)}
               
              >
                {loginStatus === "1"
                  ? "登录"
                  : loginStatus === "2"
                    ? "注册"
                    : "提交"}
              </Button>

              {loginStatus === "2" && (
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                            new Error("请确认用户协议")
                          ),
                    },
                  ]}
                  {...tailFormItemLayout}
                >
                  <Checkbox style={{ fontSize: "18px" }}>
                    <span className="pt">我已阅读并同意</span>
                    <span className="alj">《DWAN平台用户服务协议》</span>
                    <span className="pt">和</span>
                    <span className="alj">《DWAN隐私协议》</span>
                  </Checkbox>
                </Form.Item>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
