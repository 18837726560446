import { Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { getAuthUrl } from '@/api/bill';

import './index.scss'
const AuthModal = (props) => {
    let { visiable, showAuthModal, authStatus } = props;
    console.log('authStatus----->', authStatus)
    const [modalVisable, setModalVisable] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    useEffect(() => {
        console.log("visiable:", visiable)
        setModalVisable(visiable)
    }, [visiable]);

    const handleOk = () => {
        showAuthModal(false)

    };
    const handleCancel = () => {
        showAuthModal(false)
    };

    const getAuthUrlFun = async () => {
        const formData = new FormData();
        formData.append("mode", 2);
        formData.append("return_model", 2);

        await getAuthUrl(formData).then(res => {
            if (res.code === 200) {
                setQrCodeUrl(res?.data)
                // setAuthStatus(res.data?.status)
            }
        })
    };
    useEffect(() => {
        getAuthUrlFun()
    }, []);

    return (
        <>
            <Modal open={modalVisable} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <div className='content-auth-modal'>
                    <div className='title'>实名认证</div>
                    <div className='subtitle'>请用手机扫描下方二维码进行实名认证操作</div>

                    <div className='qr-code-area'>
                        <QRCode
                            value={qrCodeUrl}
                            size={121}
                            fgColor="#000000" />
                    </div>
                    <div className="auth-status">
                        {authStatus === 1 ? "认证失败" : (authStatus === 2 ? "用户实名认证中" : (authStatus === 3 ? "实名超时或未进行实名" : (authStatus === 0  ?  "认证成功" : "")))}
                    </div>
                </div>

            </Modal>
        </>
    );
};
export default AuthModal;