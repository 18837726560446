import React from "react";
import { xzl, Logo, footerLogo } from '@/utils/icon.js'
import "./index.scss"

function DarkFooter() {
        return (
                <footer className="footer-page">
                        <div className="footer-content">
                                <div className="footer-content-one">
                                        <img className='logos' src={footerLogo} alt="log" />
                                        <div className='left'>
                                        世界上首个也是最大的加密收藏品和非同质化代币<br></br>(NFT)数字市场。购买、出售和发现独家数字项目。
                                        </div>
                                        <div className='record-mark'>
                                        @ 2021 ccsmec.down 沪ICP备2022003475号
                                        </div>
                                </div>
                                <div className="right">
                                        <div className="footer-content-two">
                                                <div className="main-title">
                                                        关于我们
                                                </div>
                                                <div className='describe'>
                                                        关于我们
                                                </div>
                                                <div className='describe'>
                                                        平台协议
                                                </div>
                                        </div>
                                        <div className="footer-content-three">
                                                <div className="main-title">
                                                        支持
                                                </div>
                                                <div className='describe'>
                                                        平台公告
                                                </div>
                                                <div className='describe'>
                                                        &nbsp;
                                                </div>
                                        </div>
                                        <div className="footer-content-four">
                                                <div className="main-title">
                                                        联系我们
                                                </div>
                                                <div className='describe'>
                                                        若您和您的团队想成为平台创作者,或有任何问题，欢迎联系我们
                                                </div>
                                                <div className="describe">
                                                        邮箱:vip@chisewethings.com
                                                </div>
                                        </div>
                                </div>

                        </div>

                </footer>
        );
}

export default DarkFooter;
