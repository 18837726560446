import axios from 'axios';
import { message } from "antd";
import { checkStatus } from "../api/helper/checkStatus";
import { ResultEnum } from '../enums/httpEnum'

// 创建一个新的axios，就是返回值request
// request的用法和axios用法基本一致
const request = axios.create({
  // 公共配置，将来发送请求会自动添加
  // 发送请求的前缀：将来所有通过request发送的请求，会在请求地址前面自动添加baseURL
  // react脚手架会根据开发/生产自动加载.env.xxx的环境变量
  // 通过 process.env.xxx 读取加载的环境变量的值
  // 开发模式下：process.env.REACT_APP_API --> /dev-api
  baseURL: process.env.REACT_APP_BASE_URL, // 请求地址：baseURL + url
  timeout: 15000, // 请求超时时间：一旦请求超过10s还未完成，就会自动中断请求
  // headers: {},
});
request.interceptors.request.use((config) => {
  // token从redux中读取
  // 不是组件，不能使用hooks函数读取
  // 使用最原始读取redux数据的方法
  // const token = store.getState().user.token;
  let token = localStorage.getItem('token') || ''
  // if (!token) return Promise.reject()
  if (token) {
    // 携带token参数
    (config.headers).Authorization = "Bearer" + " " + token;
  }

  return config;
});


// 响应拦截器函数：得到响应之后触发的函数
request.interceptors.response.use(
  (response) => {

    // // * 登录失效（code == 401）
    // 		if (response?.data.code == 401) {

    // 			window.location.href = "/login"
    // 		}
    // 请求成功
    if (response?.data?.code === 200) {
      // 说明功能成功，返回成功的数据
      // message.success(response.data.msg);
      return response.data; // 我们最终希望得到的数据
    }
    else {
      console.log("======response else======", response)
      // 说明功能失败，没有数据，返回失败的原因
      // message.error(response.msg);
      // return Promise.reject(response.data.message); // 外面触发失败的流程
    }
  },
  (error) => {
    // 如果没有网络，
    if (!error.response) {
      message.error('网络异常')
    } else if (error.response.status == 401) {
      message.error('身份过期，请重新登录')
      // 1.清空token信息
      localStorage.removeItem("token")
      // store.dispatch(logout())
      // 2. 路由跳转 -> login页面
      window.location.href = "/login"
    }

    return Promise.reject(error);
  }
);

export default request;