import React, { useState, useEffect } from 'react'
import './index.scss'
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { Button, Avatar, Image, message,Divider, Skeleton, List  } from 'antd';
import { nftDetail, nftDetailNoton } from '@/api/nft'
import { Row, Col } from "antd";
import VirtualList from 'rc-virtual-list';
import { Modal, InputNumber, Form } from 'antd';
import { isStar, messageUnreadCount } from '@/api/user';
import { useMappedState, useDispatch } from 'redux-react-hook'
import { profile, starNFT } from '@/api/user'
import { blockchain } from '@/utils/icon.js'
import { collectionNft } from '@/api/public'
import DarkFooter from "../../components/Footers/DarkFooter"
import { NftOrderAdd, NftOrderDown, nftBurn } from '@/api/nft'
const key = 'updatable';
const ContainerHeight = 1000;
export default function NftDetailCreator(props) {
        const { nftDetailItem, statusItem } = props.location.state;
        const [form] = Form.useForm();
        const [nftId, setNftId] = useState();
        const [isModalOpen, setIsModalOpen] = useState(false);
        const content_type_list = [{ key: "All", name: "所有" }, { key: "Avatar", name: "头像" }, { key: "Music", name: "音乐" }, { key: "RightCard", name: "权益卡" }, { key: "DriverSkin", name: "车机皮肤" }];
        const [nftDetailObj, setNftDetail] = useState(null);
        const [works, setWorks] = useState("");
        const [addone, setAddone] = useState(0);
        const [pageNo, setPageNo] = useState(1);
        const [count, setCount] = useState(0);
        const [allPage, setAllPage] = useState(1);
        const dispatch = useDispatch();
        const [loading, setLoding] = useState(true);
        const [collectionId,setCollectionId] = useState("");
        const [creatorNftPublicList, setCreatorNftPublicList] = useState([]);
        const [star, setStar] = useState();
        const { loginInformation, nftList } = useMappedState((state) => {
                return state;
        })
        const getnftDetail = async () => {

                if (nftDetailItem.asset_id === '0') {

                        const params = {
                                nft_owner_id: nftDetailItem?.owner_id,
                        }
                        await nftDetailNoton(params).then(res => {
                                // dispatch({ type: "NFT_DETAIL_ITEM", nftDetialItem: res.data })
                                setNftDetail(res.data)
                                getNftListFun(res.data.collection_id)
                        })
                } else {
                        const params = {
                                asset_id: nftDetailItem?.asset_id,
                        }
                        await nftDetail(params).then(res => {
                                //     dispatch({ type: "NFT_DETAIL_ITEM", nftDetialItem: res.data })
                                setNftDetail(res.data)
                                isStarFun(res.data.asset_id)
                                getNftListFun(res.data.collection_id)
                        })
                }

        }
        const setImageFun = (value) => {
                const image = JSON.parse(value)
                return image.FrontCoverUrl
        }
        const messageUnreadCountFun = async () => {
                if (loginInformation) {
                        await messageUnreadCount().then(res => {
                                if (res.code == 200) {
                                        dispatch({ type: 'MESSAGE_COUNT', messageCount: res.data.count })
                                }
                        })
                }
        }
        const setContentType = (value) => {
                let content_type = ""
                content_type_list.map((v, i) => {
                        if (v.key == value) {
                                content_type = v.name;
                        }
                })
                return content_type;
        }

        const isStarFun = async (asset_id) => {
                const params = {
                        id: asset_id,
                        type: "NFT"
                }
                await isStar(params).then((res) => {
                        setStar(res.data.is_star)
                })
        }
        const titles = () => {
                console.log(works, "works")
                if (works === "1") {
                        return "上架作品"
                } else if (works === "2") {
                        return "销毁作品"
                } else if (works === "3") {
                        return "下架作品"
                }
        }
        const onFinishAdd = async (values) => {
                console.log('Success:', values);
                if (works === "1") {
                        const params = {
                                count: values.count,
                                end_time: 3,
                                nft_base_id: Number(nftId),
                                price: values.price
                        }
                        await NftOrderAdd(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        message.success("上架成功");
                                }
                        })
                } else if (works === "2") {
                        const params = {
                                count: values.count,
                                nft_base_id: Number(nftId),
                        }
                        await nftBurn(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        message.success("销毁成功");
                                }
                        })
                } else if (works === "3") {
                        const params = {
                                asset_id: nftId,
                        }
                        await NftOrderDown(params).then(res => {
                                if (res.code === 200) {
                                        setIsModalOpen(false);
                                        message.success("下架成功");
                                }
                        })
                }


        };
        const handleCancel = () => {
                form.resetFields();
                setIsModalOpen(false);
        };
        const getCollectionFun = async () => {
                console.log(pageNo,'pageNo')
                const params = {
                      page_no: pageNo,
                      page_size: 8,
                      ascending: true,
                      order_by: "Time",
                      content_type: 'All',
                      id:collectionId,
                }
                await collectionNft(params).then(res => {
                      console.log(res, 'res')
                      if (res.code === 200) {
                            setCreatorNftPublicList(res.data.nft)
                            setCount(res.data.count)
                      } else if (res.code === 6001) {
                            message.error('获取列表失败')
                      }
    
                })
          }
        const getNftListFun = async (id) => {
                const params = {
                      page_no: pageNo,
                      page_size: 8,
                      ascending: true,
                      order_by: "Time",
                      content_type: 'All',
                      id: id
                }
                await collectionNft(params).then(res => {
                      console.log(res, 'res')
                      if (res.code === 200) {
                            setCreatorNftPublicList(res.data.nft)
                            setCount(res.data.count)
                      } else if (res.code === 6001) {
                            message.error('获取列表失败')
                      }
    
                })
          }
        const starNFTFun = async () => {
                setStar(!star)
                const key = 'updatable';
                const params = {
                        has_star: !star,
                        asset_id: nftDetailObj?.asset_id,
                }
                //     const loading = false
                message.loading({
                        content: 'Loading...',
                        key,
                });
                setLoding(false)
                if (loading) {
                        await starNFT(params).then((res) => {
                                if (res.code === 200) {
                                        message.success({
                                                content: `${star ? "已取关" : "已关注"}`,
                                                key,
                                        })
                                        setLoding(true)
                                        getnftDetail()
                                        if (!star) {
                                                messageUnreadCountFun()
                                        }
                                }
                        })
                }
        }
        const onScroll = (e) => {
                if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
                      setPageNo(pageNo + 1);
                      // 如果没到视图底部就加载列表
                      if (allPage >= pageNo) {
                            getCollectionFun();
                      }
                }
          };
        useEffect(() => {
                getnftDetail()
        }, [])
        console.log(nftDetailObj, "nftDetailObj")
        console.log(statusItem, 'statusItem')
        console.log(nftDetailItem, 'nftDetailItem')
        return (
                <div className='nftDetailCreator'>
                        <Modal title={titles()}
                                open={isModalOpen}
                                onCancel={handleCancel}
                                footer={null}
                        >
                                <Form
                                        name="basic"
                                        form={form}
                                        labelCol={{
                                                span: 6,
                                        }}
                                        wrapperCol={{
                                                span: 16,
                                        }}
                                        onFinish={onFinishAdd}
                                        autoComplete="off"
                                >
                                        {works === "1" &&
                                                <Form.Item
                                                        label="上架数量"
                                                        name="count"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }
                                        {works === "1" &&
                                                <Form.Item
                                                        label="作品价格"
                                                        name="price"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }
                                        {works === "2" &&
                                                <Form.Item
                                                        label="销毁数量"
                                                        name="count"
                                                        rules={[
                                                                {
                                                                        required: true,
                                                                        message: 'Please input your username!',
                                                                },
                                                        ]}
                                                >
                                                        <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                        }

                                        <Form.Item wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                        }}>
                                                <Row>
                                                        <Col span={12}>
                                                                <Button type="primary" htmlType="submit">
                                                                        确认
                                                                </Button>
                                                        </Col>
                                                        <Col span={12}>
                                                                <Button onClick={handleCancel}>
                                                                        取消
                                                                </Button>
                                                        </Col>
                                                </Row>
                                        </Form.Item>
                                </Form>
                        </Modal>
                        <div className='nftDetail-content'>
                                <div className='avatar-header'>
                                        <div className='left'>
                                                <Image
                                                        className='avatar-detials'
                                                        src={setImageFun(nftDetailItem?.meta_data)}
                                                />
                                                <div className='works-detial-card'>
                                                        <div className='detial-card-price'>
                                                                <div className='price-label'>商品价格</div>
                                                                <div className='price'>
                                                                        <div className='price-symbel'>￥</div>{nftDetailObj?.price || "0.00"}
                                                                </div>
                                                        </div>
                                                        {statusItem === 1 && <Button type="primary" className='buy-Butone'
                                                                disabled={nftDetailObj?.on_quantity <= 0}
                                                                onClick={() => {
                                                                        setIsModalOpen(true);
                                                                        setNftId(nftDetailObj?.asset_id)
                                                                        setWorks("3");
                                                                }} >下架作品</Button>}
                                                        {statusItem === 0 && <div className='button-box'>
                                                                <Button type="primary" className='down-Butone'
                                                                        disabled={nftDetailObj?.on_quantity <= 0}
                                                                        onClick={() => {
                                                                                setNftId(nftDetailItem?.nft_base_id)
                                                                                setWorks("1");
                                                                                setIsModalOpen(true);
                                                                        }} >上架作品</Button>
                                                                <Button type="primary" className='down-Butone'
                                                                        disabled={nftDetailObj?.on_quantity <= 0}
                                                                        onClick={() => {
                                                                                setIsModalOpen(true);
                                                                                setNftId(nftDetailItem?.nft_base_id)
                                                                                setWorks("2");
                                                                        }} >销毁作品</Button>
                                                        </div>}
                                                        {statusItem === 2 && <Button type="primary" className='buy-Butone'
                                                                disabled
                                                        >已出售</Button>}
                                                </div>
                                        </div>
                                        <div className='right'>
                                                <div className='work-detial-title-box'>
                                                        <div className='right-label-title'>
                                                                {nftDetailObj?.name}
                                                        </div>
                                                        <div className='house' onClick={() => {
                                                                starNFTFun()
                                                                setAddone(nftDetailObj?.star_num + 1)
                                                        }}>
                                                                {
                                                                        !star ? <HeartFilled /> : <HeartOutlined />
                                                                }
                                                                &nbsp;
                                                                {addone}
                                                        </div>
                                                </div>
                                                <div className='music-label'>
                                                        {
                                                                setContentType(nftDetailObj?.content_type)
                                                        }
                                                </div>
                                                <div className='serives-box-lable'>
                                                        所属系列
                                                </div>
                                                <a className='serives-content' onClick={() => {
                                                        props.history.push('/home/worksSeries' + nftDetailObj?.collection_id, { collection_id: nftDetailObj?.collection_id })
                                                }}>
                                                        {nftDetailObj?.collection_name}
                                                </a>
                                                <div className='nftDetail-right-card'>
                                                        <div className='nftDetail-card-one'>
                                                                <div className='jbxx'>
                                                                        基本信息
                                                                </div>
                                                                <div className='nftDetail-discrib'>
                                                                        <img src={blockchain} className='icon-avatar' />
                                                                        <span>作品已区块链存证</span>
                                                                </div>
                                                        </div>
                                                        <div className='nftDetail-address'>
                                                                <span className='one'>区块链合约地址:</span>
                                                                <span className='two'>{nftDetailObj?.contract.slice(0, 6)}……{nftDetailObj?.contract?.substring(nftDetailObj?.contract.length - 6)}</span>
                                                        </div>
                                                        <div className='nftDetail-address'>
                                                                <span className='one'>作品ID:</span>
                                                                <span className='two'>{nftDetailObj?.token_id}</span>
                                                        </div>
                                                        <div className='nftDetail-address'>
                                                                <span className='one'>总量:</span>
                                                                <span className='two'>{nftDetailObj?.total_quantity}个</span>
                                                        </div>
                                                        <div className='nftDetail-address'>
                                                                <span className='one'>可购买量:</span>
                                                                <span className='two'>{nftDetailObj?.on_quantity}个</span>
                                                        </div>
                                                        <div className='smart-avatar' onClick={() => {
                                                                props.history.push('/home/creator', { seller_id: nftDetailObj.seller_id })
                                                        }}>
                                                                <div className='czz'>创作者:</div>
                                                                <a className='smart-avatar-wenzi'>
                                                                        <Avatar className='czz-atartar' src={nftDetailObj?.avatar} />
                                                                        <div className='mbs'>{nftDetailObj?.seller_name}</div>
                                                                </a>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div className='zpxljs'>
                                        <div className='zpxljs-title'>
                                                作品系列介绍
                                        </div>
                                        <div className='zpxljs-maioshu'>
                                                {nftDetailObj?.brief}
                                        </div>
                                        <div className='label-icon'>

                                        </div>
                                </div>
                                <div className='zpxljs'>
                                        <div className='zpxljs-title'>
                                                创作者简介
                                        </div>
                                        <div className='zpxljs-maioshu'>
                                                {nftDetailObj?.brief}
                                        </div>
                                        <div className='label-icon'>

                                        </div>
                                </div>
                                <div className='zpxljs'>
                                        <div className='zpxljs-title'>
                                                交易须知
                                        </div>
                                        <div className='zpxljs-maioshu'>
                                                1、所有作品信息由创作者上传，用户购买前务必仔细甄别并自行判断，平台不为创作者的承诺做担保，如果创作者未兑现其承诺，一切与平台无关；
                                                <br></br>
                                                2、用户未经授权不得将持有的数字作品用于任何商业用途；
                                                <br></br>
                                                3、本平台坚决反对数字作品炒作、禁止用户进行任何形式的数字作品炒作及私下交易数字作品；<br></br>
                                                4、数字作品一经交易不支持退换，请用户务必谨慎购买；
                                                <br></br>
                                                5、如因创作者侵权或发布危害国家利益、违反社会道德与公序良俗以及其他负面言论或行为或违法违规等原因，导致作品被隐藏、下架或限制交易的，购买作品的用户应当自行与作品的创作者友好协商解决。

                                        </div>
                                        <div className='label-icon'>

                                        </div>
                                </div>
                                <div className='more-works'>更多系列作品</div>
                                <div className='content'>
                                        {/* <InfiniteScroll
                                    dataLength={creatorNftPublicList.length}
                                    next={creatorNftPublicList}
                                    // hasMore={creatorNftPublicList.length < 50}
                                    loader={
                                          <Skeleton
                                                avatar
                                                paragraph={{
                                                      rows: 1,
                                                }}
                                                active
                                          />
                                    }
                                    endMessage={<Divider plain>没有更多数据了🤐</Divider>}
                                    scrollableTarget="scrollableDiv"
                              > */}
                                        <List
                                                grid={{
                                                        gutter: 16,
                                                        column: 4,
                                                }}
                                        >
                                                <VirtualList
                                                        data={creatorNftPublicList}
                                                        height={ContainerHeight}
                                                        itemHeight={107}
                                                        itemKey="id"
                                                        onScroll={onScroll}
                                                >
                                                        {(item) => (
                                                                <List.Item key={item.email}>
                                                                        <div className='card-my'
                                                                                // key={i}
                                                                                onClick={() => {
                                                                                        props.history.push('/home/nftdetail' + item.asset_id, { v:item })
                                                                                }}
                                                                        >
                                                                                <img alt="example" className='card-my-avatar'
                                                                                        src={setImageFun(item?.meta_data)} />
                                                                                <div className='card-content-box'>
                                                                                        <div className='title'>
                                                                                                {item?.name}
                                                                                        </div>
                                                                                        <div className='miaoshu'>
                                                                                                {item?.collection_name}
                                                                                        </div>
                                                                                        <div className='card-content'>
                                                                                                <div className='price'>
                                                                                                        <div className='price-symbol'>￥</div>   {item.price}
                                                                                                </div>
                                                                                                <div className='house'>
                                                                                                        <HeartOutlined />
                                                                                                        &nbsp;
                                                                                                        {item?.star_num}
                                                                                                </div>
                                                                                        </div>

                                                                                </div>
                                                                                <div className='button'>立即购买</div>
                                                                                <div className='label'>{item?.status}</div>
                                                                                <div className='avatar-mask'>
                                                                                </div>
                                                                        </div>
                                                                </List.Item>
                                                        )}
                                                </VirtualList>
                                        </List>
                                        {/* </InfiniteScroll> */}
                                </div>
                        </div>
                        <DarkFooter />
                </div>
        )
}
