import React from 'react';
import { useEffect, useState } from 'react'
import './index.scss'
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'
import { useRequest } from 'ahooks';
import { useMappedState } from 'redux-react-hook';
import { useDispatch } from 'redux-react-hook'
import { getNftList } from '@/api/nft'
import { useHistory } from "react-router-dom";
import { starNFT } from "@/api/user"
import { message } from 'antd';
import { getAuthStatus } from '@/api/bill';
import AuthModal from '../../../bill/component/SecuritySetting/component/AuthModal/index';

export default function SelectedWorks(props) {
    const history = useHistory();
    const [dataList, setDataList] = useState([]);
    const dispatch = useDispatch()
    const [authVisiable, setAuthVisiable] = useState(false) //是否开启认证Modal
    const [dataAuthStatus, setDataAuthStatus] = useState('')
    let timer = null;

    // 获取用户信息redux
    const { userInformation } = useMappedState((state) => {
        return state;
    })

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getStatusData(); //数据查询函数，
            clearTimerFun(); //清空定时器
            setTimerFun(); //创建定时器
        }

    }, []); //监听筛选条件，一旦变化就立即重新查询，并清空已有定时器，创建新的定时器

    useEffect(() => {
        return () => {
            clearTimerFun(); //组件销毁时，清空定时器
        };
    }, []);

    const setTimerFun = () => {
        console.log("创建定时器");
        timer = setInterval(() => {
             getStatusData();
        }, 2000);
    };

    const clearTimerFun = () => {
        console.log("清空定时器");
        clearInterval(timer);
    };

    const getStatusData = async () => {
        const { data, code } = await getAuthStatus();
        if (code === 200) {
            setDataAuthStatus(data)
            console.log('data22---->', data)
        }
    }

    const getSelectedWorksList = async () => {
        const params = {
            page_no: 1,
            page_size: 4,
            ascending: true,
            content_type: 'All',
            order_by: 'Price'
        }
        await getNftList(params).then(res => {
            if (res.code === 200) {
                setDataList(res.data)

            }
        })
    }

    useEffect(() => {
        getSelectedWorksList()
    }, [])

    const setImageFun = (value) => {
        const image = JSON.parse(value)
        return image.FrontCoverUrl
    }
    const actionFllow = async (item, e) => {
        e.stopPropagation();
        const params = {
            asset_id: item.asset_id,
            has_star: !item.is_star,
        }
        const { data, code } = await starNFT(params)
        if (code === 200) {
            message.success(`${!item.is_star ? "关注成功" : "取消关注"}`)
            getSelectedWorksList()
        }
    }

    const goToBuy = (item, e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log('userInformation?.is_auth123--->', userInformation?.is_auth)
        if (userInformation?.is_auth === false) {
          return  setAuthVisiable(true)
        }

        dispatch({ type: "NFT_DETAIL_ITEM", nftDetialItem: item });
        history.push('/home/pay');
    }

    const showAuthModal = (flag) => {
        setAuthVisiable(flag)
    }

    return (
        <div className='selected-works'>
            <div className='selected-works-title'>
                精选作品
            </div>
            <div className='selected-works-describe'>
                <div className='works-describe-left'>
                    为你呈现精选热门内容
                </div>
                <div className='works-describe-right'>
                    <NavLink to="/home/market">
                        查看更多<span></span>
                    </NavLink>
                </div>
            </div>

            <div className='works-banner'>
                {
                    dataList.map((v, i) => {
                        return <div className='work-card' key={i} onClick={() => {
                            history.push('/home/nftdetail' + v.asset_id, { v })
                        }}
                        >
                            <div className='works-img-item'  ><img src={setImageFun(v?.meta_data)} alt="" /></div>
                            <div className='card-describe'>
                                <div>
                                    <div className='name'>
                                        {v?.name}
                                    </div>
                                    <div className='dec'>
                                        {v?.collection_name}
                                    </div>
                                    <div className='buy-button' onClick={(e) => goToBuy(v, e)}>立即购买</div>
                                    <div className='sale-status'>{v.status}</div>
                                </div>
                                <div className='footer'>
                                    <div >¥{v?.price}</div>
                                    <div onClick={(e) => actionFllow(v, e)}>
                                        {
                                            v.is_star ? <HeartFilled /> : <HeartOutlined />
                                        }
                                        <span>{v?.star_num}</span>
                                    </div>
                                </div>
                                <div className='avatar-mask'>
                                </div>
                            </div>

                        </div>
                    })
                }
            </div>
            <AuthModal visiable={authVisiable} authStatus={dataAuthStatus?.status} showAuthModal={showAuthModal}></AuthModal>
        </div>

    )
} 
