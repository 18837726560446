import React, { useState, useEffect } from "react"
import "./index.scss"
import { message } from "antd"
import { Button, Form, Input, Upload } from "antd"
import { profile, profileEdit, creatorProfileEdit } from "@/api/user"
import { useDispatch, useMappedState } from "redux-react-hook"
import avatar from '@/assets/img/default-avatar.svg'

const { TextArea } = Input;

export default function EssentialInformation(props) {
 
  const [acitves, setAcitves] = useState(1)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState();
  const [imageUrlTopPic, setImageUrlTopPic] = useState();
  const [fileList, setFileList] = useState([]);
  const { loginInformation } = useMappedState(state => {
    return state
  })
  const {userInformation} = props;
  const dispatch = useDispatch()
  const onFinish = async (values) => {
    console.log("Success:", values)
          const formData = new FormData();
          if(values.avatar){
            formData.append("avatar",values.avatar[0].originFileObj)
          }
         formData.append("brief",values.brief)
         formData.append("username",values.username)
         if(userInformation.role===1){
                await profileEdit(formData).then((res)=>{
                      if(res.code===200){
                            message.success("修改成功")
                           // window.location.reload()
                          window.history.back(-1);
                      }else{
                            message.error(res.msg)
                      }
                })
        }else if(userInformation.role===2){
               if(values.creator_url&&values.creator_url!=userInformation.creator_url){ 
                  formData.append("url",values.creator_url);
               }
               if(values.top_pic){
                formData.append("top_pic",values.top_pic[0].originFileObj);
               }
               await creatorProfileEdit(formData).then((res)=>{
                    if(res.code===200){
                          message.success("修改成功")               
                          window.history.back(-1);
                          // window.location.reload()
                    }else{
                          message.error(res.msg)
                    }
              })
        }
           
  }
  
  const getSingleInfor = async() => {
    console.log(loginInformation.id,"loginInformation.id")
    const params = {
      user_id: loginInformation.id,
    }
    await profile(params).then(res => {
      if(res.code===200){
        // setUserInformation(res.data)
        form.setFieldsValue({
          username: res.data.username,
          brief: res.data.brief,
          address: res.data.address,
          creator_url:res.data.creator_url,
        })
      }
    })
  }
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info) => {
         console.log(fileList,'fileList')
         getBase64(info.file.originFileObj, (url) => {
           setImageUrl(url);
        });
  };

  const handleChangeTopPic = (info) => {
      console.log(fileList,'fileList')
      getBase64(info.file.originFileObj, (url) => {
            setImageUrlTopPic(url);
      });
  };


  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e.file)) {
      return e.file;
    }
    return e?.fileList;
  };


  const checkLengthusername = (e)=>{
    var l = 0;
    for(var i=0; i<e.target.value.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(e.target.value[i])) {
            l+=2;
        } else {
            l++;
        }
        if (l > 30) {
          e.target.value = e.target.value.substr(0,i);
            break;
        }
    }
  };
  const checkLengthuserbrief = (e)=>{
    var l = 0;
    for(var i=0; i<e.target.value.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(e.target.value[i])) {
            l+=2;
        } else {
            l++;
        }
        if (l > 300) {
          e.target.value = e.target.value.substr(0,i);
            break;
        }
    }
  }; 
  const checkLengthuserWeb = (e)=>{
    var l = 0;
    for(var i=0; i<e.target.value.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(e.target.value[i])) {
            l+=2;
        } else {
            l++;
        }
        if (l > 60) {
          e.target.value = e.target.value.substr(0,i);
            break;
        }
    }
  };

  useEffect(() => {
    getSingleInfor()
  },[])
   console.log(userInformation,"userInformation")
  return (
    <Form
      form={form}
      name="basic"
      className="from-message"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="头像"
        extra="此图片用于作品系列导航等显示效果，建议200*200px"
      >
        <Form.Item name="avatar" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
          <Upload name="files" fileList={fileList}   showUploadList={false}  onChange={handleChange}  maxCount={1}>
             {imageUrl ? (
                <img
                  src={imageUrl}
                  className='logo-box'
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) :  <img className='logo-box' src={userInformation?.avatar||avatar} alit="" />}
          </Upload>
        </Form.Item>
      </Form.Item>
      <Form.Item label="用户账号" name="username">
        <Input
          onKeyUp={checkLengthusername}
          style={{
            width: "500px",
            height: "60px",
            background: "#F0F1F4",
            borderRadius: "8px",
            paddingLeft: "20px",
          }}
          placeholder="请填写用户账号"
          
        />
      </Form.Item>
      {userInformation?.role===2&&<Form.Item label="封面"
        extra="此图片用于作品系列导航等显示效果，建议200*200px"
      >
        <Form.Item name="top_pic" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
          <Upload name="files" fileList={fileList}   showUploadList={false}  onChange={handleChangeTopPic}  maxCount={1}>
          {imageUrlTopPic ? (
                <img
                  src={imageUrlTopPic}
                  className='fic-box'
                  alt="avatar"
                />
              ) :  <img className='fic-box' src={userInformation?.top_pic||avatar} alit="" />}
          </Upload>
        </Form.Item>
      </Form.Item>}
      <Form.Item label="用户简介" name="brief">
        <TextArea
          rows={4}
          onKeyUp={checkLengthuserbrief}
          style={{
            width: "810px",
            background: "#F0F1F4",
            borderRadius: "8px",
            paddingLeft: "20px",
          }}
          placeholder="添加个人介绍,300个字符以内"
        />
      </Form.Item>
      <Form.Item label="区块链账号地址" name="address" >
        <Input
          disabled
          maxLength={300}
          style={{
            width: "500px",
            height: "60px",
            background: "#F0F1F4",
            borderRadius: "8px",
            paddingLeft: "20px",
          }}
          placeholder="未认证"
        
        />
      </Form.Item>
      {userInformation?.role===2&&<Form.Item label="个人网站" name="creator_url">
        <Input
            onKeyUp={checkLengthuserWeb}
          style={{
            width: "500px",
            height: "60px",
            background: "#F0F1F4",
            borderRadius: "8px",
            paddingLeft: "20px",
          }}
          placeholder="添加个人网站，让大家记住你"
         
        />
      </Form.Item>}
      <Form.Item
        wrapperCol={{
          offset: 9,
          span: 14,
        }}
      >
        <Button className="submit" type="primary" htmlType="submit">
          保存
        </Button>
      </Form.Item>
    </Form>
  )
}
