import React, { useState } from 'react'
import { Avatar, Col, Row } from 'antd';
import { chejipifu, touxiang, yinyue, quanyika, } from '../../../../utils/icon';
import { useHistory } from "react-router-dom";
import { useMappedState, useDispatch } from 'redux-react-hook'
import './index.scss'
import headPortrait from '@/assets/img/head-portrait.jpg';
import musicBackImg from '@/assets/img/music.jpg';
import carMachineSkinBackImg from '@/assets/img/car-machine-skin.jpg';
import equityCardImg from '@/assets/img/equityCardImg.jpg'
export default function TableSee() {
       const history = useHistory();
       const dispatch = useDispatch();
       const listData = [
              {
                     id: 1,
                     title: "头像",
                     type: 'Avatar',
                     backgroundimage: headPortrait

              },
              {
                     id: 2,
                     title: "音乐",
                     type: 'Music',
                     backgroundimage: musicBackImg
              }, {
                     title: "权益卡",
                     type: 'RightCard',
                     backgroundimage: equityCardImg
              },
              {
                     id: 3,
                     title: "车机皮肤",
                     type: 'DriverSkin',
                     backgroundimage: carMachineSkinBackImg
              },
       ]
       return (
              <div className='category-browse'>
                     <div className='title'>分类浏览合集</div>
                     <div className='category-list'>

                            {
                                   listData.map((item, index) => {
                                          return (
                                                 <div className='category-card' key={item.id} onClick={() => {
                                                        history.push('/home/market')
                                                        dispatch({ type: "CONTENT_TYPE", contentType: item.type })
                                                 }}
                                                 >
                                                        <div className='category-img' style={{
                                                              backgroundImage:`url(${item.backgroundimage})`
                                                              
                                                        }}>
                                                               <div className='mark-des'>{item.title}</div>
                                                        </div>
                                                 </div>
                                          )
                                   })
                            }


                     </div>

              </div>

       )
}
