import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message, } from 'antd';
import { send_code } from '@/api/login'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMappedState, useDispatch } from 'redux-react-hook'
import { codeValidate } from '@/api/login.js'
import {phoneEdit} from '@/api/user.js'
import './index.scss'


export default function EditPhone(props) {
    const [phone, setPhone] = useState("")
    const [code, setCode] = useState("");//短信验证码
    const [values, setValues] = useState("");
    const [form] = Form.useForm();
    const [open, setOpen] = useState("");//获取已设置支付密码的状态
    const { userInformation } = useMappedState((state) => {
        return state;
    })

    const getSendCode = async () => {
  
       const newPhone = form.getFieldsValue().new_phone
       console.log(newPhone,'newPhone')
        const params = {
            phone: newPhone,
            type: "修改"
        }
        await send_code(params).then(res => {
            if (res.code === 200) {
                message.success("验证码已发送");
            }
        })
    };
    const onFinish = async(values) => {
        console.log('Success:', values);
        props.setAcitvesFun(3);

        const params = {
            new_code:values.new_code,
            new_phone:values.new_phone,
            old_code:props.oldCode,
            // old_phone:props.oldPhone,
        }
        console.log(params,"params")
        await phoneEdit(params).then((res)=>{
              if(res.code===200){
                   message.success('已修改手机号')
                   props.setAcitvesFun(1);
              }else{
                  message.error(res.msg)
              }
        })
    };
    return (
        <div className='EditPhone'>
            <div className='test-box'>
                <div className='test-back' onClick={() => {
                    props.setAcitvesFun(1)
                }}>
                    <ArrowLeftOutlined />
                    返回
                </div>
                <div className='test-title'>
                    修改手机号
                </div>
                <div className='message-test-box'>
                    <Form
                        name="basic"
                        form={form}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="新手机号码"
                            name="new_phone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input className='message-test'
                                prefix="+86"
                                onChange={(e) => {
                                    console.log(e.target.value, "value")
                                    let value = e.target.value;

                                    
                                }} placeholder="输入新手机号" />
                        </Form.Item>
                        <Form.Item
                            label="新手机验证码"
                            name="new_code"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input className='message-test'
                                suffix={
                                    <Button className='link-button' type="link" onClick={() => {
                                        getSendCode()
                                    }}>获取验证码</Button>
                                }
                               placeholder="输入6位数短信验证码" />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                            offset: 8,
                            span: 16,
                            }}
                        >
                            <Button type="primary" className='button-ok' disabled={values} htmlType="submit">验证</Button>
                        </Form.Item>
                    </Form>

                </div>
             
                
            </div>
        </div>
    )
}
