import React, { useState, useEffect } from 'react'
import { useMappedState, useDispatch } from 'redux-react-hook'
import { profile,messageUnreadCount } from '@/api/user'
import { NavLink } from 'react-router-dom'
import { walletBack} from '@/utils/icon.js'
import { alipayGetOrderStatus} from '@/api/alipay'
import { message } from "antd"

export default function Intergral() {
       const dispatch = useDispatch();
       const [billList, setBillList] = useState();
       const [userInformation, setUserInformation] = useState()
       const outTradeNo = localStorage.getItem("out_trade_no")
       const { loginInformation } = useMappedState((state) => {
              return state;
       })
       const getSingleInfor = () => {
              const params = {
                     user_id: loginInformation?.id
              }
              profile(params).then(res => {
                     setUserInformation(res.data)
              })
       }
       const messageUnreadCountFun = async () => {
              if (loginInformation) {
                    await messageUnreadCount().then(res => {
                          if (res.code == 200) {
                                dispatch({ type: 'MESSAGE_COUNT', messageCount: res.data.count })
                          }
                    })
              }
        }
       const fun = (timer) => {
              setTimeout(() => {
                const params = {
                  out_trade_no:outTradeNo,
                }
                // 这里ajax 请求的代码片段和判断是否停止定时器
                // 如需要停止定时器，只需加入以下：
                alipayGetOrderStatus(params).then((res) => {
                  if (res.code === 200) {
                         if(res.data.status === 0){
                                clearInterval(timer);
                                message.success('交易成功')
                                messageUnreadCountFun()
                                localStorage.removeItem('out_trade_no')
                         }else if(res.data.status === 1){
                                clearInterval(timer);
                                messageUnreadCountFun()
                                message.error('交易失败')
                                localStorage.removeItem('out_trade_no')
                         }else if(res.data.status === 2){
                               messageUnreadCountFun()  
                                console.log('正在交易中')
                         }
                        
                  }
                })
              }, 0)
            }
            const alipayGetOrderStatusFun = () => {
              let timer = setInterval(() => {
                fun(timer);
              }, 2000)
            }
            
            const getOrderStatus = ()=>{
                 if(outTradeNo){
                    alipayGetOrderStatusFun()
                 }
            }
       useEffect(() => {
              getSingleInfor();
              getOrderStatus();
       }, [])

       console.log(billList, "billList")
       return (
              <div className='integral'>
                     <div className='integral-title'>
                            <div className='integral-title-one'>积分余额</div>
                            <div className='balance'>{userInformation?.balance}</div>
                     </div>
                     <div className='pay-admin-label'><NavLink to='/home/wallet/paydmin' onClick={() => {
                                          dispatch({ type: "WALLET_ACTIVE_TYPE", walletActiveType: 4 })
                                   }}>
                                          支付管理
                                   </NavLink>  
                     </div>
                     <div className='footer'>
                            <div className='walletID'>
                                   <div>钱包ID</div>
                                   <div className='address'>
                                          {userInformation?.address.slice(0, 6)}……{userInformation?.address?.substring(userInformation?.address.length - 6)}
                                   </div>
                            </div>
                            <div className='right'>
                                   <div className='button-one' onClick={() => {
                                          dispatch({ type: "WALLET_ACTIVE_TYPE", walletActiveType: 2 })
                                   }}><NavLink to='/home/wallet/rechargePoints'>
                                                 充值
                                          </NavLink></div>
                                   <div className='button-two'><NavLink to='/home/wallet/billDetails' onClick={() => {
                                          dispatch({ type: "WALLET_ACTIVE_TYPE", walletActiveType: 3 })
                                   }}>
                                          账单明细
                                   </NavLink></div>
                            </div>
                     </div>
              </div>
       )
}
