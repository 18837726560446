import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { profileEdit, creatorProfileEdit } from "@/api/user"
import { EditOutlined,} from '@ant-design/icons';
import './index.scss'
import { Avatar } from 'antd';
import { message, Upload } from 'antd';
const { Dragger } = Upload;


const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

export default function UploadHomeImage(props) {
    const { userInformation } = props;
    const [loading, setLoading] = useState(false);
    const handleChange = async(info) => {
        console.log(info, "info")
         if (info.file.status === 'uploading') {
            setLoading(true);
            return;
          }
          setLoading(false);
          const formData = new FormData();
          info?.fileList.forEach((item) => {
              formData.append("top_pic", item.originFileObj);
          })
          console.log(formData, "formData")
          await creatorProfileEdit(formData).then((res) => {
              if (res.code === 200) {
                  window.location.reload()
                  message.success('背景图片修改成功')
              }
          })
       
    }
    return (
        <div className='UploadHomeImage'>
            <Dragger
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={() => false} 
                onChange={handleChange}
            >{userInformation?.top_pic? (
                <Avatar shape="square" className='backgroud-top_pic' src={userInformation?.top_pic} />
              ) : (
                <div className='edit-message-back'>
                    <EditOutlined className="edit-icon" />&nbsp; 编辑图片
               </div>
              )}
            </Dragger>
        </div>
    )
}