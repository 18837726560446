import { useState, useEffect } from "react"
import { Avatar, Select, Tabs } from "antd"
import "../scss/box.scss"
import {
  nftCollection,
} from "@/api/user"
import { useHistory } from "react-router-dom";
import { Empty } from 'antd';
import { noStatusBack } from '@/utils/icon'
import { getAuthStatus } from '@/api/bill';
import AuthModal from './components/AuthModal/index'
import { useMappedState } from 'redux-react-hook';
export default function Collection(props) {
  // let { collection } = props;

  const content_type_list = [{ key: "All", name: "全部" }, { key: "Avatar", name: "头像" }, { key: "Music", name: "音乐" }, { key: "RightCard", name: "权益卡" }, { key: "DriverSkin", name: "车机皮肤" }];
  const [collectionType, setCollectionType] = useState("All");
  const history = useHistory();
  const [nftCollectionList, setNftCollectionList] = useState([]);
  const { Option } = Select;
  const [authStatus, setAuthStatus] = useState('') // 实名认证状态
  // 获取用户信息redux
  const { userInformation } = useMappedState((state) => {
    return state;
})
  const [authVisiable, setAuthVisiable] = useState(userInformation?.is_auth === false ? true : false) //是否开启认证Modal
  // 用户购买的NFT
  async function getNftCollection() {
    const { code, data } = await nftCollection({
      content_type: collectionType, //缺少关注类型的参数
    })
    if (code == 200) {
      setNftCollectionList(data.data)
    }
  }
  const setImageFun = (value) => {
    if (value) {
      const image = JSON.parse(value)
      return image.FrontCoverUrl
    }

  }

  useEffect(() => {
    getNftCollection()
  }, [collectionType])

  // // 获取实名认证状态
  // const getAuthStatusDetail = async () => {
  //   await getAuthStatus().then(res => {
  //     if (res.code === 200) {
  //       const status = res.data?.status
  //       if(res.data.status !== 0) {
  //         setAuthVisiable(true)
  //       }
  //     }
  //   })
  // };
  // useEffect(() => {
  //   getAuthStatusDetail()
  // }, [])
  const showAuthModal = (flag) => {
    setAuthVisiable(flag)
    

}
  return (
    <div className="colections-List-box">
      {nftCollectionList?.length != 0 && <div className="lable-form-select-box1">
        <Select
          defaultValue="全部"
          style={{
            width: 250,
          }}
          onChange={e => {
            setCollectionType(e)
          }}
        >
          {
            content_type_list.map((v, i) => {
              return <Option value={v.key} key={i}>{v.name}</Option>
            })
          }
        </Select>
      </div>}
      <div className="colections-List">
        {
          nftCollectionList?.map((item, index) => {
            return <div className="box-card f-c" key={index} onClick={() => {
              history.push('/home/nftdetail' + item.asset_id, { v: item })
         }}>
              <div className="top">
                <img
                  src={setImageFun(item?.meta_data)}
                  className="top-img collection-img f-r f-c-c"
                />
              </div>
              <div className="bottom f-c">
                <div>
                  <div>{item.name || "没有名字"}</div>
                  <div className="sell-describe">{item.collection_name || "什么描述"}</div>
                </div>

                <div>收藏时间:{item.time}</div>
              </div>
            </div>
          })
        }
        {
          !nftCollectionList?.length && <Empty className='noStatusBack' description='什么都没有～' image={noStatusBack} />
        }
      </div>
      <AuthModal visiable={authVisiable} authStatus={authStatus} showAuthModal={showAuthModal}></AuthModal>
    </div>
    
  )
}
